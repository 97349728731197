import React, { useState, useEffect, useRef } from "react";
import { Card, Col, Row, Form, Alert, Button } from 'react-bootstrap';
import { useNavigate, useParams } from "react-router-dom";
import LayoutWrapper from "../../components/UI/LayoutWrapper";
import { Paths } from "../../routes/route";
import DashboardCard from "../../components/UI/DashboardCard";
import { useForm } from "react-hook-form";
import Select from 'react-select';
import TranslationBlock from "./TranslationBlock";
import { handleSave } from "../../helpers/API/Save";
import { getData } from "../../helpers/API/Misc";

const UserRoleForm = (props) => {
    const API_URL = process.env.REACT_APP_API_URL;
    const { role_id } = useParams();
    const navigate = useNavigate();
    const { data, permission, title, selectedVisualiser } = props;
    const { handleSubmit, register, setValue, formState: { errors } } = useForm();
    const [successMessage, setSuccessMessage] = useState('');
    const [errMessage, setErrorMessage] = useState('');
    const [startSave, setStartSave] = useState(false);
    const [specialRoles, setSpecialRoles] = useState(false);
    const allOption = { value: 0, label: 'All' };
    const [visualiserVal, setVisualiserVal] = useState([allOption]);
    const [selectVizOption, setSelectVizOption] = useState([]);
    const [selectLanguage, setSelectLanguage] = useState([]);
    const [visualiserId, setVisualiserId] = useState([0]);

    const [moduleList, setModuleList] = useState([
        { module_id: "", module: "", parent_module_id: "", add_perm: "", edit_perm: "", delete_perm: "" }
    ]);

    // Module permission saving logic
    const handleChange = (e, index, vname) => {
        const list = [...moduleList];
        list[index][vname] = (e.target.checked === true) ? 1 : 0;
        setSpecialRoles((list[index]['sp_role'] && vname === 'edit_perm') ? !specialRoles : specialRoles);
        setModuleList(list);
    };

    const [specialModuleList, setSpecialModuleList] = useState([
        { id: "" }
    ]);

    const [checkedIds, setCheckedIds] = useState([]);
    const prevCheckedIdsLengthRef = useRef(0);
    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setCheckedIds((prevCheckedIds) => [...prevCheckedIds, value]);
        } else {
            setCheckedIds((prevCheckedIds) =>
                prevCheckedIds?.filter((id) => id !== value)
            );
        }
    };

    useEffect(() => {
        setValue("GID", data.GID);
        setValue("role_name", data.role_name);
        setValue("active", data.status);
        setValue("createdBy", data.createdBy);
        setValue("createdAt", data.createdAt);
        setValue("updatedBy", data.updatedBy);
        setValue("updatedAt", data.updatedAt);
        // eslint-disable-next-line
    }, [props?.data]);

    useEffect(() => {
        if (selectedVisualiser) {
            setVisualiserVal(selectedVisualiser);
        } else {
            setVisualiserVal([allOption]);
        }
    }, [selectedVisualiser]);

    useEffect(() => {
        const vArr = []
        visualiserVal?.map((element, index) => {
            return vArr.push(
                element["value"],
            );
        })
        setVisualiserId(vArr);
    }, [visualiserVal]);

    useEffect(() => {

        // Language List checkboxes
        getData(API_URL + '/v1/userroles/data/languageList?visualizer_id=' + visualiserId, 'GET', '', 4, setSelectLanguage);

    }, [visualiserId]);

    useEffect(() => {
        let perm = [];
        const newdata = permission || [];
        newdata.map((element, index) => {
            Object.keys(element).forEach(key => {
                if (element[key] !== '') {
                    perm = JSON.parse(element[key])
                }
            })
        });
        setCheckedIds(perm);
        prevCheckedIdsLengthRef.current = perm.length;
    }, [permission]);
    const handleVisChange = (a, e) => {
        if (selectedVisualiser && a.length < selectedVisualiser.length) {
            // Find the object with removed visualizer name and value in selectLanguage array
            let matchingObjects = [];
            if(e.action === 'remove-value') {
                matchingObjects = selectLanguage.data[0].data.filter(item => {
                    return item.mode === e.removedValue.label &&
                        item.children?.[2]?.lang?.some(langItem => langItem.visualiser === e.removedValue.value);
                });
            }
            // Extract id values from the lang array
            const extractedIds = matchingObjects.flatMap(item => {
                const langArray = item.children?.[2]?.lang;
                if (langArray) {
                    return langArray.map(langItem => langItem.id);
                }
                return [];
            });

            const editCheckedIds = [...checkedIds];
            const filteredEditCheckedIds = editCheckedIds.filter(id => !extractedIds.includes(Number(id)));
            setCheckedIds(filteredEditCheckedIds);
        }

        setVisualiserVal(a);
        const isAllSelected = a.some(
            (option) => option.value === allOption.value
        );
        if (isAllSelected) {
            // If "All" is selected, remove other options from the dropdown
            setVisualiserVal([allOption]);
        }
    };

    useEffect(() => {
        const tmp = [];
        const transArr = [];
        fetch(API_URL + '/v1/user/get_module_list?role_id=' + role_id, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("access_token"),
            }
        }).then(response => response.json())
            .then(jsondata => {
                Object.keys(jsondata).forEach(key => {
                    tmp.push({
                        module_id: jsondata[key]['mainID'],
                        module: jsondata[key]['name'],
                        parent_module_id: jsondata[key]['parent_module_id'],
                        add_perm: jsondata[key]['add_perm'] || 0,
                        edit_perm: jsondata[key]['edit_perm'] || 0,
                        delete_perm: jsondata[key]['delete_perm'] || 0,
                        sp_role: jsondata[key]['special_roles']
                    });
                    setModuleList(tmp);

                    if (jsondata[key]['special_roles'] === 1) {
                        if (jsondata[key]['edit_perm']) {
                            setSpecialRoles(true);
                        }
                        transArr.push({
                            id: jsondata[key]['mainID']
                        });
                    }
                });
                setSpecialModuleList(transArr)
            }).catch((err) => {
                console.log("Login error: " + err);
            });
    }, [role_id]);

    useEffect(() => {

        // Visualizer dropdown
        getData(API_URL + 'v1/visualizer/list', 'GET', setSelectVizOption, 1, '', 'visualizer_name', true, true, 'All');

    }, []);


    const onSubmit = (data) => {
        setStartSave(true);
        let formData = new FormData();
        if (role_id !== undefined) {
            formData.append("GID", role_id);
        }
        formData.append("role_name", data.role_name);
        formData.append("visualiser", (visualiserVal ? JSON.stringify(visualiserVal) : allOption));
        formData.append("role_module_details", JSON.stringify(moduleList));
        formData.append("special_role_details", JSON.stringify(specialModuleList));
        formData.append("lang_role_details", JSON.stringify(checkedIds));

        handleSave(API_URL + '/v1/userroles/data/save', setSuccessMessage, Paths.userrole.path, setErrorMessage, setStartSave, formData, navigate);
    }
    
    return (
        <>
            <LayoutWrapper breadcrumb_title='User Role'
                form={true}
                label={Paths.userrole.name}
                linkto={Paths.userrole.path}
                title={title}>
                {errMessage && <Alert variant="danger">{errMessage}</Alert>}
                {successMessage && !errMessage && <Alert variant="success">{successMessage}</Alert>}
                <Row>
                    <Col lg='12' className=''>
                        <DashboardCard className='pt-4'>
                            <h5 className="w-100 px-4 py-4 m-0">{title}</h5>
                            <Form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" className="w-100 px-4 pb-4">
                                <Card.Body>
                                    <Row>
                                        <Col xl={6} lg={6} md={6} className="mb-3">
                                            <Form.Group id="role_name">
                                                <Form.Label> User Role Name <span style={{ color: 'red', marginLeft: '4px' }}>*</span></Form.Label>
                                                <Form.Control
                                                    {...register("role_name", {
                                                        required: 'User Role Name is required',
                                                        validate: {
                                                            minLength: (v) => v.length >= 3 || 'User Role Name should have at least 3 characters',
                                                            matchPattern: (v) => /^[a-z, A-Z]+$/.test(v) || 'User Role Name must contain only alphabetic characters',
                                                        },
                                                    })} />
                                            </Form.Group>
                                            {errors.role_name?.message && (
                                                <small style={{ color: 'red' }}>{errors.role_name.message}</small>
                                            )}
                                        </Col>
                                    </Row>
                                    {selectVizOption && <Row>
                                        <Col xl={6} lg={6} md={6} className="mb-3">
                                            <Form.Group id="visualiser">
                                                <Form.Label>Visualiser</Form.Label>
                                                <Select isMulti
                                                    name="visualiser"
                                                    options={selectVizOption}
                                                    value={visualiserVal}
                                                    onChange={handleVisChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>}
                                    <Row className="role_main">
                                        <Col md={12} className="mb-3">
                                            <Form.Group id="moduleList">
                                                <Form.Label className="mb-2">Access Rights:</Form.Label>
                                                <div>
                                                    <Row className="role_header">
                                                        <Col xl={4} lg={4} md={4}>&nbsp;</Col>
                                                        <Col className="text-center"><Form.Label>Add</Form.Label></Col>
                                                        <Col className="text-center"><Form.Label>Delete</Form.Label></Col>
                                                        <Col className="text-center"><Form.Label>Edit</Form.Label></Col>
                                                    </Row>
                                                    {moduleList.map((x, i) => {
                                                        return (<Row className="role_col" key={i + "role"}>

                                                            <React.Fragment>
                                                                <Col className="ps-4" xl={4} lg={4} md={4}>
                                                                    <Form.Label>{x.module}</Form.Label>
                                                                </Col>
                                                                <Col className="">
                                                                    <div className="d-block text-center">
                                                                        <input name="add_perm[]" value="1" type="checkbox" checked={Number(x.add_perm) === 1 ? true : false} className="form-check-input" style={{ cursor: 'pointer' }} onChange={e => {
                                                                            handleChange(e, i, 'add_perm');
                                                                        }} />
                                                                    </div>
                                                                </Col>
                                                                <Col className="">
                                                                    <div className="d-block text-center">
                                                                        <input name="delete_perm[]" value="1" type="checkbox" checked={Number(x.delete_perm) === 1 ? true : false} className="form-check-input" style={{ cursor: 'pointer' }} onChange={e => {
                                                                            handleChange(e, i, 'delete_perm');
                                                                        }} />
                                                                    </div>
                                                                </Col>
                                                                <Col className="">
                                                                    <div className="d-block text-center">
                                                                        <input name="edit_perm[]" value="1" type="checkbox" checked={Number(x.edit_perm) === 1 ? true : false} className="form-check-input" style={{ cursor: 'pointer' }} onChange={e => {
                                                                            handleChange(e, i, 'edit_perm');
                                                                        }} />
                                                                    </div>
                                                                </Col>
                                                            </React.Fragment>
                                                        </Row>);
                                                    })}
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <hr />
                                    {specialRoles && (
                                        <TranslationBlock
                                            specialModuleList={specialModuleList}
                                            checkedIds={checkedIds}
                                            handleCheckboxChange={handleCheckboxChange}
                                            selectLanguage={selectLanguage}
                                        />
                                    )}

                                    {!startSave && <Row>
                                        <Col xl={12} lg={12} md={12} className="cancel_block">
                                            <div style={{ display: "flex" }} className="cancel_button">
                                                <Button className="list_button m-0" type="submit" >
                                                    Save</Button>
                                                <Button
                                                    className="list_button m-0"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        navigate(`${Paths.userrole.path}`);
                                                    }}
                                                >
                                                    Cancel</Button>
                                            </div>
                                        </Col>
                                    </Row>}
                                </Card.Body>
                            </Form>
                        </DashboardCard>
                    </Col>
                </Row>
            </LayoutWrapper>

        </>
    );
}

export default UserRoleForm;