import React, { useEffect } from "react";
import { useDropzone } from 'react-dropzone';
import { Col, Row, Form } from 'react-bootstrap';
// 50000000 = 50 MB
const Uploader = ({ maxFiles = 1, filesAcceptedTxt = 'You can only upload images here', accept = { 'image/*': [] }, maxSize = 50000000, maxSizeLabel = 'with size less than 50 MB', ...props }) => {
	// Uploader
	// For file uploader  // Color

	useEffect(() => {
		if (props.setdedupstatus) {
			props.setdedupstatus('');
		}
	}, [props]);

	const thumbs = (props.uploderState).map((file, i) => (
		<React.Fragment key={i + "fKey"}>
			<Row className="mt-3 w-100">
				<Col xl={2} md={2} key={i + "rKey"} className="mb-3" style={{ display: "inline-flex", flexDirection: "column", alignItems: "flex-start" }}>
					<strong style={{ display: "inline-flex", justifyContent: "center" }}>
						<span style={{ paddingTop: "10px", alignItems: "center", display: "inline-flex", fontWeight: 800 }}>
							{file.name}
						</span>
					</strong>
				</Col>
			</Row>
			{props.dedup && (
				<Col md={12} className="mb-3">
					<Form.Group id="active">
						<Form.Label><strong>Optimise glb:</strong></Form.Label>
						<div className="d-block">
							<div className="d-inline-block me-4">
								<div className="d-inline-block pe-2">
									<input type="radio" id={"dedup0" + i} name={"dedup" + i} label="Ja" value="0" checked={((file.dedup === '0') || !file.dedup) ? true : false} onChange={(e) => {
										file.dedup = e.target.value;
										props.setdedupstatus(file.dedup);
										props.setUploderState(props.uploderState);
									}} style={{ cursor: 'pointer' }}
									/>
								</div>
								<div className="d-inline-block">
									<label htmlFor={"dedup0" + i} style={{ cursor: 'pointer' }}>Raw</label>
								</div>
							</div>
							<div className="d-inline-block me-4">
								<div className="d-inline-block pe-2">
									<input type="radio" id={"dedup1" + i} name={"dedup" + i} label="Ja" value="1" checked={file.dedup === '1' ? true : false} onChange={(e) => {
										file.dedup = e.target.value;
										props.setdedupstatus(file.dedup);
										props.setUploderState(props.uploderState);
									}} style={{ cursor: 'pointer' }}
									/>
								</div>
								<div className="d-inline-block">
									<label htmlFor={"dedup1" + i} style={{ cursor: 'pointer' }}>Optimise</label>
								</div>
							</div>
							<div className="d-inline-block">
								<div className="d-inline-block pe-2">
									<input type="radio" id={"dedup2" + i} name={"dedup" + i} label="Nein" value="2" checked={file.dedup === '2' ? true : false} onChange={(e) => {
										file.dedup = e.target.value;
										props.setdedupstatus(file.dedup);
										props.setUploderState(props.uploderState);
									}} style={{ cursor: 'pointer' }}
									/>
								</div>
								<div className="d-inline-block">
									<label htmlFor={"dedup2" + i} style={{ cursor: 'pointer' }}>Just flatten and draco</label>
								</div>
							</div>
						</div>
					</Form.Group>
				</Col>
			)}
		</React.Fragment>
	));

	const { getRootProps: getRootReviewerProps, getInputProps: getInputReviewerProps, open } = useDropzone({
		accept: accept,
		maxFiles: maxFiles,
		maxSize: maxSize,
		multiple: true,
		// Disable click and keydown behavior
		noClick: true,
		noKeyboard: true,
		onDrop: (acceptedFiles) => {
			props.setUploderState(
				acceptedFiles.map(file => Object.assign(
					file, { preview: URL.createObjectURL(file) }
				))
			);
		}
	});

	return (
		<>
			<section className="dropZoneContainer">
				<div {...getRootReviewerProps({ className: 'document-dropzone' })}>
					<input {...getInputReviewerProps()} />
					<p>Drag files into this field or click the button to select a file to upload</p>
					{filesAcceptedTxt && <p class="text-danger">{filesAcceptedTxt} {maxSizeLabel} and if you try to upload any other file type, then it will not get uploaded.</p>}
					<button type="button" onClick={open} className="flexible_button">
						Find file on your device
					</button>
				</div>
				<div >
					<aside style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: 16 }}>
						{thumbs}
					</aside>
				</div>
			</section>
		</>
	);
}

export default Uploader;