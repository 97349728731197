import './Header.scss'
import avatar from '../../assets/images/avatar.png'
import logout from '../../assets/images/logout.svg'
import { useContext, useState } from 'react'
import DataContext from '../../context/DataContext'
import { Paths } from '../../routes/route'
import { handleLogout } from '../../framework/utilities.js/utilities'

const Header = ({ className }) => {

    const { auth, dispatchAuth } = useContext(DataContext);
    const [displayPopup, setDisplayPopup] = useState(false)
    let username = auth[0]?.user_profile?.FName + ' ' + auth[0]?.user_profile?.SName;

    const displayPopupHandler = () => {
        setDisplayPopup(prev => !prev)
    }

    const onLogoutClick = ()=>{
        handleLogout(dispatchAuth);
    }

    return (
        <>
            <header className={`${className}`}>
                <div>
                    <h6 className='m-0'>{username}</h6>
                </div>
                <div className={`avatar ava_image ${displayPopup && 'popup'}`} style={{ cursor: 'pointer' }} onClick={displayPopupHandler}>
                    {auth[0]?.user_profile?.url ? <img src={auth[0]?.user_profile?.url} style={{ width: '49px', height: '50px', cursor: 'pointer', borderRadius: '50%' }} alt="avatar" /> : <img src={avatar} alt="avatar" style={{ width: '49px', height: '50px', cursor: 'pointer', borderRadius: '50%', objectFit: 'cover' }} />}
                </div>
                {displayPopup &&
                    <div className='settings_popup'>
                        <ul>
                            <li style={{ color: '#EE6100' }}><a href={Paths.Profile}>EDIT MY PROFILE</a></li>
                            <li><a href={Paths.users.path}>Manage USERS</a></li>
                            <li className='seperator'></li>
                            <li className='logout' onClick={onLogoutClick}><span><img src={logout} alt="logout" /></span><span>LOGOUT</span></li>
                        </ul>
                        <div className='arrow_pointer'></div>
                    </div>
                }
            </header>
        </>
    )
}

export default Header