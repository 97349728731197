import { useCallback, useState, useRef, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Col, Row } from 'react-bootstrap';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const UnorderedGrid = (props) => {
	const gridRef = useRef();

	const [rowData, setRowData] = useState([]);

	const columnDefs = props.columnDefs;
	const handleCellEditingStopped = props.handleCellEditingStopped;
	const rowDragManaged = props.rowDragManaged;
	const rowDragEntireRow = props.rowDragEntireRow;
	const onRowDragEnd = props.onRowDragEnd;
	const onFirstDataRendered = props.onFirstDataRendered;

	const onGridReady = useCallback((params) => {
		setRowData(props.gridData);
	}, [props.gridData]);

	useEffect(() => {
		setRowData(props.gridData);
	}, [props.gridData]);

	return (
		<Row>
			<Col>
				<div className="ag-theme-alpine" style={{ width: '100%', height: 500 }}>
					<AgGridReact
						ref={gridRef}
						rowData={rowData}
						columnDefs={columnDefs}
						animateRows={true}
						pagination={true}
						rowSelection="single"
						onGridReady={onGridReady}
						rowDragManaged={rowDragManaged}
						rowDragEntireRow={rowDragEntireRow}
						onRowDragEnd={onRowDragEnd}
						onCellEditingStopped={handleCellEditingStopped}
						onFirstDataRendered={onFirstDataRendered}
					>
					</AgGridReact>
				</div>
			</Col>
		</Row>
	);
}

export default UnorderedGrid;