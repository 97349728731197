import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import CountryForm from "./Form";
import { Paths } from "../../routes/route";
import { fetchEditData } from "../../helpers/API/Edit";
const Edit = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const { id } = useParams();

    const [editData, setEditData] = useState();

    const title = Paths.country.c2_name;

    useEffect(() => {

        fetchEditData(API_URL + 'v1/country/edit?id=' + id, setEditData);

    }, [id]);

    return (
        <>
            <CountryForm editpage={1} editData={editData} lg={11} title={title} />
        </>
    );
}

export default Edit;