import React, { useContext, useState } from "react";
import { Col, Row, Form, Button, Alert, Card } from "react-bootstrap";
import avatar from "../../assets/images/avatar-icon.png";
import LayoutWrapper from "../../components/UI/LayoutWrapper";
import ChangePassword from "./ChangePassword";
import "./Profile.scss";
import "./Login/LoginLayout.scss";
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal";
import DataContext from "../../context/DataContext";

const Profile = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const { auth } = useContext(DataContext);

    const [fName, setFName] = useState(auth[0].user_profile.FName);
    const [sName, setSName] = useState(auth[0].user_profile.SName);
    const [email, setEmail] = useState(auth[0].user_profile.Email);
    const [errMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    let [modalShow, setModalShow] = useState(false);
    let [modalDisplay, setModalDisplay] = useState(false);

    let [delRecTitle, setDelRecTitle] = useState('');
    let [delRecId, setDelRecId] = useState(0);

    const [a2, setA2] = useState({ preview: auth[0].user_profile.url ? auth[0].user_profile.url : avatar, raw: "" });

    const handleChange = e => {
        if (e.target.files.length) {
            setA2({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0]
            });
        }
    };

    const showpopup = () => {
        setModalDisplay(true);
    };

    const deleteProfile = (params) => {
        setDelRecId(params);
        setDelRecTitle("Your profile picture will be deleted permanently");
        setModalShow(true);
    }

    const handleOnDeleteSubmitted = () => {

        let formData = new FormData();
        formData.append("GID", delRecId);

        fetch(API_URL + 'v1/user/deleteProfilePicture', {
            method: 'POST', headers: {
                Authorization: 'Bearer ' + localStorage.getItem("access_token"),
            }, body: formData
        })
            .then(response => response.json())
            .then(jsondata => {
                setModalShow(false);
                setDelRecId(0);
                setDelRecTitle('');
                const userProfile = JSON.parse(localStorage.getItem("user_profile"));
                userProfile.url = '';
                localStorage.setItem("user_profile", JSON.stringify(userProfile));
                setTimeout(function () {
                    window.location.reload(false);
                }, 300);
            }).catch((err) => {

            });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const fdata = [a2.raw]
        let formData = new FormData();
        for (let i = 0; i < fdata.length; i++) {
            formData.append("FileData" + [i], fdata[i]);
        }
        formData.append("GID", auth[0].user_profile.GID);
        formData.append("first_name", fName);
        formData.append("last_name", sName);
        formData.append("email", email);
        formData.append("user_type", auth[0].user_profile.Utype);
        formData.append("user_role", auth[0].user_profile.user_role);
        formData.append("status", auth[0].user_profile.Status);
        formData.append("updated_by", auth[0].user_profile.GID);
        formData.append("type", "user_edit");

        fetch(API_URL + "v1/user/data/save", {
            method: "POST",
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("access_token"),
            },
            body: formData,
        })
            .then((response) => response.json())
            .then((jsondata) => {
                if (jsondata.GID && jsondata.message === "Record updated successfully.") {
                    const { FName, SName, Email, url } = jsondata.user_profile.profile;
                    const userProfileString = localStorage.getItem("user_profile");
                    const userProfile = JSON.parse(userProfileString);
                    userProfile.FName = FName;
                    userProfile.SName = SName;
                    userProfile.Email = Email;
                    userProfile.url = url;
                    localStorage.setItem("user_profile", JSON.stringify(userProfile));
                    setSuccessMessage("Profile Updated successfully");
                    setTimeout(function () {
                        setSuccessMessage("");
                        window.location.reload(false);
                    }, 2000);
                    setErrorMessage("");
                } else {
                    setErrorMessage("Something went wrong");
                }
            })
            .catch((err) => {
                // setStartSave(false);
            });
    };

    return (
        <>
            <LayoutWrapper
                modal={<ConfirmDeleteModal modalShow={modalShow} handleModelClose={() => { setModalShow(false); }} delRecTitle={delRecTitle} handleOnDeleteSubmitted={handleOnDeleteSubmitted} />}
                className="d-none"
                custom_class="padding_css"
                modal1={
                    <ChangePassword
                        modalDisplay={modalDisplay}
                        handleModelClose={() => {
                            setModalDisplay(false);
                        }}
                    />
                }
            >
                <div className="dashboard_main">
                    <div className="dashboard_title">Manage my profile</div>
                    <div className="dashboard_subtitle">
                        Here you manage your profile settings
                    </div>
                </div>
                {errMessage && <Alert variant="danger">{errMessage}</Alert>}
                {successMessage && !errMessage && (
                    <Alert variant="success">{successMessage}</Alert>
                )}
                <Card
                    border="light"
                    className="bg-white shadow-sm mt-5 profilecard "
                    style={{ borderRadius: "20px" }}
                >
                    <Form
                        onSubmit={handleSubmit}
                        encType="multipart/form-data"
                        className="w-100 px-4 py-3"
                    >
                        <div className="px-3 pt-3 pb-2">
                            <div className="profile_title">My Profile</div>
                        </div>
                        <Card.Body>
                            <Row>
                                <Col lg={5}>
                                    <Row>
                                        <Col xl={12} lg={12} md={12} className="mb-3">
                                            <Form.Group id="FName">
                                                <Form.Label>First Name</Form.Label>
                                                <Form.Control
                                                    className="form-control-lg"
                                                    name="FName"
                                                    required
                                                    type="text"
                                                    value={fName}
                                                    onChange={(e) => {
                                                        setFName(e.target.value);
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12} lg={12} md={12} className="mb-3">
                                            <Form.Group id="SName">
                                                <Form.Label>Last Name</Form.Label>
                                                <Form.Control
                                                    className="form-control-lg"
                                                    required
                                                    name="SName"
                                                    type="text"
                                                    value={sName}
                                                    onChange={(e) => {
                                                        setSName(e.target.value);
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12} lg={12} md={12} className="mb-3">
                                            <Form.Group id="Email">
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control
                                                    className="form-control-lg"
                                                    required
                                                    name="Email"
                                                    type="text"
                                                    value={email}
                                                    onChange={(e) => {
                                                        setEmail(e.target.value);
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={7} className="ml-4">
                                    <Row>
                                        <Col
                                            lg={8}
                                            className="d-flex flex-column align-items-end"
                                        >
                                            <div style={{ fontSize: "14px", paddingRight: "102px" }} className="mb-4">
                                                Profile image
                                            </div>

                                            {a2.preview && <img style={{ width: '250px', height: '250px', cursor: 'pointer', borderRadius: '50%', objectFit: 'cover' }} src={a2.preview} alt="avatar" />}

                                        </Col>
                                        <Col lg={4} className="mt-5 d-flex flex-column align-items-end ">
                                            <label htmlFor="upload-button">
                                                <h5 className="mb-2 list_button" style={{ cursor: 'pointer' }}>Update</h5>
                                            </label>
                                            <input
                                                type="file"
                                                accept="image/x-png,image/gif,image/jpeg"
                                                id="upload-button"
                                                style={{ display: "none" }}
                                                onChange={handleChange}
                                            />
                                            <Button
                                                className="list_button remove_button"
                                                type="button"
                                                onClick={() => deleteProfile(auth[0].user_profile.GID)}
                                            >
                                                Remove
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    xl={12}
                                    lg={12}
                                    md={12}
                                    className="mt-5 p-2 d-flex justify-content-end"
                                >
                                    <Button
                                        className="mb-0 list_button remove_button me-3"
                                        onClick={() => showpopup(1)}
                                    >
                                        Change Password
                                    </Button>
                                    <Button className="mb-0 list_button" type="submit">
                                        SAVE & PUBLISH
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Form>
                </Card>
            </LayoutWrapper>
        </>
    );
};

export default Profile;
