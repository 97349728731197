import React, { useState, useEffect, useContext } from "react";
import { Col, Row, Form, Button, Alert } from 'react-bootstrap';
import LayoutWrapper from "../../components/UI/LayoutWrapper";
import DashboardCard from '../../components/UI/DashboardCard'
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useParams, useNavigate } from "react-router-dom";
import { getOwnerInfo } from "../../framework/utilities.js/utilities";
import Select from 'react-select'
import ModuleEditScreenOwnerInfo from "../../components/ModuleEditScreenOwnerInfo";
import { Paths } from "../../routes/route";
import DataContext from "../../context/DataContext";
import { useForm, useController } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { handleSave } from "../../helpers/API/Save";
import { getData } from "../../helpers/API/Misc";

const PartsConfigForm = (props) => {
	const schema = z.object({
		dependency_name: z.string({ required_error: 'Please enter the valid part dependency name' }),
		glb: z.number({ required_error: "Please select at least one option" }),
	});

	const navigate = useNavigate();
	const { handleSubmit, register, setValue, control, formState: { errors } } = useForm({ resolver: zodResolver(schema) });
	const API_URL = process.env.REACT_APP_API_URL;
	const { id } = useParams();
	const { auth } = useContext(DataContext);
	let userId = auth[0].user_profile.GID;
	const { editpage, editData, title } = props;

	const storedValue = localStorage?.getItem('viz_id');
	const { field } = useController({ name: 'glb', control })

	const [dependencyOptions, setDependencyOptions] = useState([]);
	const [groupedOptions, setGroupedOptions] = useState([]);
	const [options, setOptions] = useState([]);
	const [selectedGlb, setSelectedGlb] = useState([]);
	const [selectedButtonsEdit, setSelectedButtonsEdit] = useState([]);
	const [selectedParts, setSelectedParts] = useState([]);
	const [visualStatus, setVisualStatus] = useState('');
	const [errMessage, setErrorMessage] = useState('');
	const [successMessage, setSuccessMessage] = useState('');
	const [startSave, setStartSave] = useState(false);

	const [createdAt, setCreatedAt] = useState("");
	const [updatedAt, setUpdatedAt] = useState("");
	const [createdBy, setCreatedBy] = useState("");
	const [updatedBy, setUpdatedBy] = useState("");

	const ownerInfo = getOwnerInfo(createdAt, updatedAt, createdBy, updatedBy);

	const groupedDepOptions = [];
	const tempOptions = {};
	const tempOptions2 = {};
	const tempArray = [];
	let transformedArray = [];

	useEffect(() => {
		if (editData) {
			setValue("dependency_name", editData.part_dependency_name);
			setValue("glb", Number(editData.parts_json));
			setSelectedGlb(editData.parts_json);
			setVisualStatus(editData.active ? '1' : '0');
			setCreatedAt(editData.created_at);
			setUpdatedAt(editData.updated_at);
			setCreatedBy(editData.created_by);
			setUpdatedBy(editData.updated_by);
		}

		if (!editpage) {
			setVisualStatus('1');
		}

		if (editpage) {
			editData?.buttons_json.forEach(option => {
				const { dependency_name, label, value, dependency } = option;

				if (!tempOptions[dependency_name]) {
					tempOptions[dependency_name] = [];
				}

				tempOptions[dependency_name].push({ label, value, dependency, dependency_name });
			});

			for (const dependency_name in tempOptions) {
				groupedDepOptions.push({
					label: dependency_name,
					options: tempOptions[dependency_name]
				});
			}

			editData?.buttons_json.forEach(option => {
				const { dependency, label, value, dependency_name } = option;

				if (!tempOptions2[dependency]) {
					tempOptions2[dependency] = [];
				}

				tempOptions2[dependency].push({ label, value, dependency_name });
			});

			for (const dependency in tempOptions2) {
				tempArray.push({
					label: dependency,
					options: tempOptions2[dependency]
				});
			}

			tempArray.forEach(({ label, options }) => {
				const dependency = parseInt(label);

				options.forEach(({ label: optionLabel, value: optionValue, dependency_name }) => {
					transformedArray.push({ label: optionLabel, value: optionValue, dependency, dependency_name });
				});
			});

		}
		setSelectedButtonsEdit(groupedDepOptions);
		setSelectedParts(transformedArray);
	}, [props]);

	useEffect(() => {

		// Categories dropdowns
		getData(API_URL + '/v1/partsConfiguration/nodeDropdown?visualizer_id=' + storedValue, 'GET', '', 5, setDependencyOptions, '', false, false, '', setGroupedOptions, 'dependencyArray', 'groupedOptionsArray');

	}, []);

	useEffect(() => {

		// GLB dropdown
		getData(API_URL + '/v1/partsConfiguration/fetchPartNames?visualizer_id=' + storedValue, 'GET', '', 2, setOptions);

	}, []);

	const handlePartsChange = (selectedOptions, { removedValue }) => {
		const updatedSelectedOptions = [...selectedParts];

		// Remove the deselected option if it exists
		if (removedValue) {
			const removedIndex = updatedSelectedOptions.findIndex(
				(option) => option.label === removedValue.label && option.value === removedValue.value
			);
			if (removedIndex !== -1) {
				updatedSelectedOptions.splice(removedIndex, 1);
			}
		}
		// Add the newly selected options
		selectedOptions.forEach(({ label, value, dependency, dependency_name }) => {
			const existingIndex = updatedSelectedOptions.findIndex(
				(option) => option.label === label && option.value === value
			);
			if (existingIndex === -1) {
				updatedSelectedOptions.push({ label, value, dependency, dependency_name });
			}
		});

		setSelectedParts(updatedSelectedOptions);

		if (editpage) {
			const finalArray = [];

			updatedSelectedOptions.forEach(option => {
				const { dependency, dependency_name } = option;

				// Check if an existing label already exists for the dependency_name
				const existingLabelIndex = finalArray.findIndex(item => item.label === dependency_name);

				if (existingLabelIndex === -1) {
					finalArray.push({
						label: dependency_name,
						options: [{ label: option.label, value: option.value, dependency, dependency_name }]
					});
				} else {
					finalArray[existingLabelIndex].options.push({ label: option.label, value: option.value, dependency, dependency_name });
				}
			});

			setSelectedButtonsEdit(finalArray);
		}
	};

	// Saving
	const onSubmit = (data) => {
		setStartSave(true);

		let formData = new FormData();
		formData.append("part_dependency_name", data.dependency_name);
		formData.append("parts_json", JSON.stringify(selectedGlb));
		formData.append("buttons_json", JSON.stringify(selectedParts));
		formData.append("active", visualStatus || 0);
		formData.append("visualizer_id", storedValue);

		if (!editpage) {
			formData.append("created_by", userId);
		} else {
			formData.append("updated_by", userId);
		}

		if (id !== undefined) {
			formData.append("GID", id);
		}

		handleSave(API_URL + '/v1/partsConfiguration/save', setSuccessMessage, Paths.partsconfiguration.path, setErrorMessage, setStartSave, formData, navigate);
	}

	const handleSelectChange = (option) => {
		field.onChange(option[0]?.value)
		setSelectedGlb(option);
		
		if(option.length < 1) {
			setValue("glb", undefined);
		}
	}

	return (
		<>
			<LayoutWrapper breadcrumb_title='Parts configuration '
				form={true}
				label={Paths.partsconfiguration.name}
				linkto={Paths.partsconfiguration.path}
				title={title}>
				{successMessage && !errMessage && <Alert variant="success">{successMessage}</Alert>}
				<Row>
					<Col lg={12} className=''>
						<DashboardCard className='pt-4'>
							<h5 className="w-100 px-4 py-4 m-0">{title}</h5>
							<Form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" className="w-100 px-4 pb-4" >
								<Col xl={12} lg={12} md={12} className="mb-3">
									<Form.Group id="dependency_name">
										<Form.Label className="required ">Part Dependency name <span style={{ color: 'red', marginLeft: '4px' }}>*</span></Form.Label>
										<Form.Control
											{...register("dependency_name")}
										/>
									</Form.Group>
									<div style={{ color: 'red' }}>{errors.dependency_name?.message}</div>
								</Col>
								<Col xl={12} lg={12} md={12} className="mb-4">
									<Form.Label className="required">GLB <span style={{ color: 'red', marginLeft: '4px' }}>*</span></Form.Label>
									<Form.Group id="glb">
										<Select
											{...register("glb")}
											value={selectedGlb}
											onChange={handleSelectChange}
											options={options}
											isSearchable
											isMulti
										/>
									</Form.Group>
									<div style={{ color: 'red' }}>{errors.glb?.message}</div>
								</Col>
								<hr className="pt-2" />
								<h5 className="w-100 mb-3">CATEGORIES</h5>
								{dependencyOptions.map((dependency, index) => (
									<Col key={index} xl={12} lg={12} md={12} className="mb-3">
										<Form.Label className="required">{dependency}</Form.Label>
										<Form.Group id={dependency}>
											<Select
												isMulti
												isSearchable
												name={dependency}
												options={groupedOptions[index]}
												value={selectedButtonsEdit.find(
													(option) => option.label === dependency
												)?.options}
												onChange={handlePartsChange}
											/>
										</Form.Group>
									</Col>
								))}
								<Col md={12} className="mb-3">
									<Form.Group id="active">
										<Form.Label>Status</Form.Label>
										<div className="d-block">
											<div className="d-inline-block me-4">
												<div className="d-inline-block pe-2">
													<input type="radio" id="active1" name="active" label="Ja" checked={visualStatus === '1' ? true : false} value="1" onChange={(e) => {
														setVisualStatus(e.target.value);
													}} style={{ cursor: 'pointer' }}
													/>
												</div>
												<div className="d-inline-block">
													<label htmlFor="active1" style={{ cursor: 'pointer' }}>Active</label>
												</div>
											</div>
											<div className="d-inline-block">
												<div className="d-inline-block pe-2">
													<input type="radio" id="active0" name="active" label="Nein" checked={visualStatus === '0' ? true : false} value="0" onChange={(e) => {
														setVisualStatus(e.target.value);
													}} style={{ cursor: 'pointer' }}
													/>
												</div>
												<div className="d-inline-block">
													<label htmlFor="active0" style={{ cursor: 'pointer' }}>Inactive</label>
												</div>
											</div>
										</div>
									</Form.Group>
								</Col>
								{!startSave && <Row>
									<Col xl={5} lg={12} md={12} className="cancel_block">
										<div style={{ display: "flex" }} className="cancel_button">
											<Button className="list_button m-0" type="submit" >
												Save</Button>
											<Button
												className="list_button m-0"
												onClick={(e) => {
													e.preventDefault();
													navigate(`${Paths.partsconfiguration.path}`);
												}}
											>
												Cancel</Button>
										</div>
									</Col>
								</Row>}
								{editpage && <ModuleEditScreenOwnerInfo ownerInfo={ownerInfo} />}
							</Form>
						</DashboardCard>
					</Col>
				</Row>
			</LayoutWrapper>
		</>
	);
}

export default PartsConfigForm;