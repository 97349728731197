import { ReactComponent as Loader } from '../../../assets/images/loader.svg';
import "./LoginLayout.scss";

const Button = ({ text, loading, disabled, error, active }) => {

    const error_msg = localStorage.getItem("error");

    return (
        <>
            {error && <p style={{ color: 'red' }}>{error_msg}</p>}
            <button className={`submit-btn ${active}`} type="submit" disabled={disabled}>
                {!loading ? text : <Loader className="spinner" />}
            </button>
        </>
    )
}

export default Button