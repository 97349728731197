import React from 'react';
import { Breadcrumb } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

const ModuleBreadcrumb = (props) => {

	const renderLinks = () => {
		if ((props.type !== "list") && (props.links)) {
			return (<>
				{props.links.map((linkObj, index) => (
					linkObj.linkto ?
						<Breadcrumb.Item key={index} href={linkObj.linkto} >{linkObj.label}</Breadcrumb.Item>
						:
						<Breadcrumb.Item key={index} active >{linkObj.label}</Breadcrumb.Item>
				))}
			</>);
		}
	}

	return (
		<>
			<h4 className={`fw-bold m-0 breadcrumb-title ${props.className}`} >
				<Breadcrumb>
					<Breadcrumb.Item href="/#/" ><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
					<Breadcrumb.Item active><ul className='p-0 m-0 d-flex'>{renderLinks()}</ul></Breadcrumb.Item>
				</Breadcrumb>
			</h4>
		</>
	);
}

export default ModuleBreadcrumb;
