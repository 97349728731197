import React from "react";
import ColorForm from "./ColorForm";
import { Paths } from "../../routes/route";
const Add = () => {

    const title = Paths.colours.c1_name;

    return (
        <>
            <ColorForm addform={1} hexCode="#ffffff" colStatus={1} metalness={1} roughness={0} repeatX={0} repeatY={0} title={title} />
        </>
    );
}

export default Add;