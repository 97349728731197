import { Card, Alert } from 'react-bootstrap';
import ConfirmDeleteModal from '../../components/ConfirmDeleteModal';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import React, { useEffect, useState } from "react";
import edit from "../../assets/images/edit.svg";
import delete_icon from "../../assets/images/delete.svg";
import { Link, useLocation } from "react-router-dom";
import Grid from '../../components/Grid';
import LayoutWrapper from '../../components/UI/LayoutWrapper';
import { handleOpenInNewTab, naviLinks } from '../../framework/utilities.js/utilities';
import { Paths } from '../../routes/route';
import AddButton from '../../components/AddButton';
import { deleteBtn, handleOnDeleteSubmitted } from '../../helpers/API/Delete';
import { listData } from '../../helpers/API/List';

const List = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const [gridApi, setGridApi] = useState(null);
    const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row
    const [successMessage, setSuccessMessage] = useState('');

    let [modalShow, setModalShow] = useState(false);
    let [delRecTitle, setDelRecTitle] = useState('');
    let [delRecId, setDelRecId] = useState(0);

    // Code to fetch title based on URL
    const { pathname } = useLocation();
    const naviArr = naviLinks(pathname);
    const createPerm = naviArr['edit_perm'];
    const deletePerm = naviArr['delete_perm'];
    const readPerm = naviArr['add_perm'];

    const storedValue = localStorage?.getItem('viz_id');

    // Each Column Definition results in one Column.
    const [columnDefs] = useState([
        { headerName: "Category", field: 'type', filter: true, sortable: true, rowDrag: true, resizable: true },
        { headerName: "Part name", field: 'name', flex: 1, filter: true, sortable: true, resizable: true },
        { headerName: "Weight", field: 'weight', flex: 1, filter: true, sortable: true, resizable: true },
        { headerName: "Item Code", field: 'pdf_part_name', flex: 1, filter: true, sortable: true, resizable: true },
        {
            headerName: "", field: "id", flex: 1, minWidth: 50, maxWidth: 50, headerClass: "ag-theme-custom-text-center", cellClass: "pm-grid-number-cell ag-theme-custom-content-center",
            cellRenderer: (params) => <div className="ag-theme-content-text-center">
                {createPerm === 1 ?
                    <Link onClick={handleOpenInNewTab} to={(`${Paths.partsmedia.c2_path.split(":").slice(0, -1)[0]}` + params.value)} rel="noopener noreferrer" title="Edit" className="grid-action-btns text-success link"><img src={edit} alt="edit" className="me-2" style={{ width: '24px', height: '24px' }} /></Link>
                    : null}
            </div>
        },
        {
            headerName: "", field: "id", flex: 1, minWidth: 50, maxWidth: 50, headerClass: "ag-theme-custom-text-center", cellClass: "pm-grid-number-cell ag-theme-custom-content-center",
            cellRenderer: (params) => <div className="ag-theme-content-text-center">

                {deletePerm === 1 ? <span onClick={() => deleteBtn(params, 'name', setDelRecId, setDelRecTitle, setModalShow, 'value', 2)} title="Delete" className="grid-action-btns delete-icon-color link"><img src={delete_icon} alt="delete" className="me-2" style={{ width: '24px', height: '24px' }} /></span> : null}
            </div>
        }
    ]);

    const onDelete = () => {
        handleOnDeleteSubmitted('/v1/parts/deletePart', setSuccessMessage, '', setModalShow, setDelRecId, setDelRecTitle, '', delRecId, gridApi, 2);
    }

    // Example load data from sever
    useEffect(() => {

        listData(API_URL + '/v1/parts/configuratorPartsList?visualizer_id=' + storedValue, setRowData);

    }, []);

    return (
        <>
            <LayoutWrapper
                modal={<ConfirmDeleteModal modalShow={modalShow} handleModelClose={() => { setModalShow(false); }} delRecTitle={delRecTitle} handleOnDeleteSubmitted={onDelete} />}
                heading={Paths.partsmedia.name}
                breadcrumb_text='Contain all parts within the visualiser. Here you can add, edit or delete individual parts. In
                each part, you can assign a part to a sub-category, rename the part name seen on the
                visualiser, amend the part weight and Item Code. You also have the option to add or
                replace 3D glb models here.'
            >
                <Card.Body>
                    <AddButton
                        className="custom-button"
                        button_name={readPerm === 1 ? Paths.partsmedia.c1_name : null}
                        button_path={Paths.partsmedia.c1_path}
                        button_title={Paths.partsmedia.c1_name}
                        btn_class="list_button"
                    />
                    {successMessage && <Alert variant="success">{successMessage}</Alert>}
                    {(rowData !== undefined) && <Grid gridData={rowData} columnDefs={columnDefs} url={'v1/parts/updateOrder'} />}
                </Card.Body>
            </LayoutWrapper>
        </>
    );
}

export default List;