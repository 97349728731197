import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import Visualiser from "./Visualiser";
import Logo from '../../assets/images/sunrise-medical-logo-color.svg';
import dashboard from '../../assets/images/icons/dashboard.svg'
import arrow from '../../assets/images/toggle_arrow.svg'
import './Sidebar.scss';
import DataContext from "../../context/DataContext";
import { Paths } from "../../routes/route";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const Sidebar = () => {
    const { auth } = useContext(DataContext);
    const advancecontrol = auth[0].access_modules['Advanced Controls'];
    const regionaligation = auth[0].access_modules['Regionalisation'];
    const [show, setShow] = useState();
    
    const ToggleData = () => {
        setShow(!show);
        document.querySelector("body").classList.toggle("sidebar-close");
    };

    return (
        <>
            <nav className={`layout-menu sidebar ${!show ? 'open' : 'close'}`}>
                <header>
                    <div className="image-text">
                        <a href="/">
                            <span className="image">
                                <img src={Logo} alt="Sunrise Logo" />
                            </span>
                        </a>
                    </div>
                    <div className='toggle' onClick={ToggleData}>
                        <img src={arrow} alt="arrow" />
                    </div>
                </header>
                <div className="menu-bar scroll-width-thin">
                    <div className="menu">
                        <ul className="m-0 p-0">
                            <li className="bottom-separator-pages">
                                <div className="bottom-line-separator-before"></div>
                                <div className="bottom-pages-separator"> Active Visualiser </div>
                                <div className="bottom-line-separator-after"></div>
                            </li>
                            <li style={{ marginBottom: '12px', backgroundColor: '#181818', paddingLeft: '15px' }}>
                                <Visualiser />
                            </li>
                        </ul>
                        <ul className="menu-links p-0 mb-3">
                            <li className="nav-link">
                                <NavLink className="text nav-text d-flex justify-content-flex-start" to={Paths.visualizer.path}>
                                    <div className='icon ps-2'>
                                        <FontAwesomeIcon icon={faPlus} className="me-2" />
                                    </div>
                                    <span>
                                        Add New Visualiser
                                    </span>
                                </NavLink>
                            </li>
                            <li className="nav-link">
                                <NavLink className="text nav-text" to="/">
                                    <div className='icon'>
                                        <img src={dashboard} alt="dashboard" />
                                    </div>
                                    <span>Dashboard</span>
                                </NavLink>
                            </li>
                        </ul>
                        <ul className="menu-links p-0 bottom_ul_links moz_scroll">
                            <li className="bottom-separator-pages">
                                <div className="bottom-line-separator-before"></div>
                                <div className="bottom-pages-separator">Regionalisation</div>
                                <div className="bottom-line-separator-after"></div>
                            </li>
                            {
                                regionaligation?.map((x, i) => {
                                    return (
                                        <React.Fragment key={i + "fragment"}>
                                            <li className="nav-link">
                                                <NavLink className="text nav-text" to={x.module_path}>
                                                    <div className='icon'>
                                                        <img src={x.icon} alt="" />
                                                    </div>
                                                    <span>{x.name}</span>
                                                </NavLink>
                                            </li>
                                        </React.Fragment>
                                    );
                                })
                            }
                            <li className="bottom-separator-pages">
                                <div className="bottom-line-separator-before"></div>
                                <div className="bottom-pages-separator">Advanced Controls</div>
                                <div className="bottom-line-separator-after"></div>
                            </li>
                            {
                                advancecontrol?.map((x, i) => {
                                    return (
                                        <React.Fragment key={i + "fragment"}>
                                            <li className="nav-link">
                                                <NavLink className="text nav-text" to={x.module_path}>
                                                    <div className='icon'>
                                                        <img src={x.icon} alt="" />
                                                    </div>
                                                    <span>{x.name}</span>
                                                </NavLink>
                                            </li>
                                        </React.Fragment>
                                    );
                                })
                            }
                        </ul>
                    </div>
                </div>
                <div className="copyright">
                    <p>
                        © 2023 Sunrise Medical
                    </p>
                </div>
            </nav>
        </>
    );
}

export default Sidebar;