import React, { useState, useEffect, useContext } from "react";
import { Col, Row, Form, Button, Alert } from 'react-bootstrap';
import LayoutWrapper from "../../components/UI/LayoutWrapper";
import DashboardCard from '../../components/UI/DashboardCard'
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useParams, useNavigate } from "react-router-dom";
import { getOwnerInfo } from "../../framework/utilities.js/utilities";
import ModuleEditScreenOwnerInfo from "../../components/ModuleEditScreenOwnerInfo";
import DataContext from "../../context/DataContext";
import { useForm } from "react-hook-form";
import { Paths } from "../../routes/route";
import Select from 'react-select';
import { handleSave } from "../../helpers/API/Save";
import { getData } from "../../helpers/API/Misc";

const CountryForm = (props) => {
	const { handleSubmit, register, setValue, formState: { errors } } = useForm();
	const navigate = useNavigate();
	const API_URL = process.env.REACT_APP_API_URL;
	const { id } = useParams();
	const { auth } = useContext(DataContext);
	let userId = auth[0].user_profile.GID;
	const { editpage, editData, title } = props;

	const [dealerLocationLink, setDealerLocationLink] = useState('');
	const [testDriveLink, setTestDriveLink] = useState('');
	const [countryLink, setCountryLink] = useState('');
	const [productLink, setProductLink] = useState('');
	const [countryCode, setCountryCode] = useState('');
	const [startSave, setStartSave] = useState(false);

	const [unitData, setUnitData] = useState([]);
	const [weightValue, setWeightValue] = useState('');
	const [weightLabel, setWeightLabel] = useState('');
	const [linkedIn, setLinkedIn] = useState(0);
	const [instagram, setInstagram] = useState(0);
	const [whatsApp, setWhatsApp] = useState(0);
	const [tikTok, setTikTok] = useState(0);
	const [twitter, setTwitter] = useState(0);
	const [facebook, setFacebook] = useState(0);

	const [createdAt, setCreatedAt] = useState("");
	const [updatedAt, setUpdatedAt] = useState("");
	const [createdBy, setCreatedBy] = useState("");
	const [updatedBy, setUpdatedBy] = useState("");

	const [visualStatus, setVisualStatus] = useState('');
	const [errMessage, setErrorMessage] = useState('');
	const [successMessage, setSuccessMessage] = useState('');

	// Edited data
	useEffect(() => {
		if (editData) {
			setValue("country_name", editData.country_name);
			setDealerLocationLink(editData.dealer_location_link);
			setTestDriveLink(editData.test_drive_link);
			setCountryLink(editData.country_link);
			setProductLink(editData.product_link);
			setCountryCode(editData.country_code);
			setValue("unit", Number(editData.weight_id));
			setWeightValue(Number(editData.weight_id));
			setWeightLabel(editData.weight_label);
			setLinkedIn(editData.social_media[0] ? 1 : 0);
			setInstagram(editData.social_media[1] ? 1 : 0);
			setWhatsApp(editData.social_media[2] ? 1 : 0);
			setTikTok(editData.social_media[3] ? 1 : 0);
			setTwitter(editData.social_media[4] ? 1 : 0);
			setFacebook(editData.social_media[5] ? 1 : 0);
			setVisualStatus(editData.active ? '1' : '0');
			setCreatedAt(editData.created_at);
			setUpdatedAt(editData.updated_at);
			setCreatedBy(editData.created_by);
			setUpdatedBy(editData.updated_by);
		}

		if (!editpage) {
			setVisualStatus('1');
		}
	}, [props]);

	const ownerInfo = getOwnerInfo(createdAt, updatedAt, createdBy, updatedBy);

	// Selected configurator
	const storedValue = localStorage?.getItem('viz_id');

	// Weight unit dropdown
	useEffect(() => {

		// Weight Unit dropdown
		getData(API_URL + '/v1/country/weightList', 'GET', setUnitData, 1, '', 'unit');

	}, []);

	// Saving
	const onSubmit = (data) => {

		setStartSave(true);

		const socialMedia = { linkedIn, instagram, whatsApp, tikTok, twitter, facebook };
		const socialMediaArray = Object.values(socialMedia);

		const fields = [
			{ name: 'dealer_location_link', value: dealerLocationLink },
			{ name: 'test_drive_link', value: testDriveLink },
			{ name: 'country_link', value: countryLink },
			{ name: 'product_link', value: productLink },
			{ name: 'country_code', value: countryCode },
			{ name: 'weight_id', value: weightValue ? weightValue : 1 },
			{ name: 'social_media', value: JSON.stringify(socialMediaArray) }
		];

		let formData = new FormData();
		formData.append('country_name', data.country_name);

		fields.forEach(field => {
			if (field.value !== undefined && field.value !== null) {
				formData.append(field.name, field.value);
			}
		});

		if (!editpage) {
			formData.append("created_by", userId);
		} else {
			formData.append("updated_by", userId);
		}

		formData.append('active', visualStatus || 0);
		formData.append('visualizer_id', storedValue);

		if (id !== undefined) {
			formData.append("GID", id);
		}

		handleSave(API_URL + '/v1/country/save', setSuccessMessage, Paths.country.path, setErrorMessage, setStartSave, formData, navigate);
	}

	return (
		<>
			<LayoutWrapper breadcrumb_title='Country Module'
				form={true}
				label={Paths.country.name}
				linkto={Paths.country.path}
				title={title}>
				{successMessage && !errMessage && <Alert variant="success">{successMessage}</Alert>}
				<Row>
					<Col lg='12' className=''>
						<DashboardCard className='pt-4'>
							<h5 className="w-100 px-4 py-4 m-0">{title}</h5>
							<Form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" className="w-100 px-4 pb-4" >
								<Col xl={12} lg={12} md={12} className="mb-3">
									<Form.Group id="country_name">
										<Form.Label className="required ">Country name <span style={{ color: 'red', marginLeft: '4px' }}>*</span></Form.Label>
										<Form.Control
											{...register("country_name", {
												required: 'Country name is required',
												validate: {
													minLength: (v) => v.length >= 3 || 'Country name should have at least 3 characters',
													matchPattern: (v) => /^[a-z, A-Z]+$/.test(v) || 'Country name must contain only alphabetic characters',
												},
											})}
										/>
									</Form.Group>
									{errors.country_name?.message && (
										<small style={{ color: 'red' }}>{errors.country_name.message}</small>
									)}
								</Col>
								<Col xl={12} lg={12} md={12} className="mb-3">
									<Form.Group id="dealer_location_link">
										<Form.Label className="required ">Dealer location link</Form.Label>
										<Form.Control
											name="dealer_location_link"
											type="url"
											value={dealerLocationLink}
											onChange={(e) => {
												setDealerLocationLink(e.target.value);
											}}
										/>
									</Form.Group>
								</Col>
								<Col xl={12} lg={12} md={12} className="mb-3">
									<Form.Group id="test_drive_link">
										<Form.Label className="required ">Test drive link </Form.Label>
										<Form.Control
											name="test_drive_link"
											type="url"
											value={testDriveLink}
											onChange={(e) => {
												setTestDriveLink(e.target.value);
											}}
										/>
									</Form.Group>
								</Col>
								<Col xl={12} lg={12} md={12} className="mb-3">
									<Form.Group id="country_link">
										<Form.Label className="required ">Country link </Form.Label>
										<Form.Control
											name="country_link"
											type="url"
											value={countryLink}
											onChange={(e) => {
												setCountryLink(e.target.value);
											}}
										/>
									</Form.Group>
								</Col>
								<Col xl={12} lg={12} md={12} className="mb-3">
									<Form.Group id="product_link">
										<Form.Label className="required ">Product link </Form.Label>
										<Form.Control
											name="product_link"
											type="url"
											value={productLink}
											onChange={(e) => {
												setProductLink(e.target.value);
											}}
										/>
									</Form.Group>
								</Col>
								<Col xl={12} lg={12} md={12} className="mb-3">
									<Form.Group id="country_code">
										<Form.Label className="required ">Country Code </Form.Label>
										<Form.Control
											name="country_code"
											value={countryCode}
											onChange={(e) => {
												setCountryCode(e.target.value);
											}}
										/>
									</Form.Group>
								</Col>
								<Col xl={12} lg={12} md={12} className="mb-3">
									<Form.Group id="weight-unit">
										<Form.Label>Weight unit</Form.Label>
										<Select
											isSearchable
											name="weight_unit"
											options={unitData}
											value={{ value: weightValue, label: weightLabel }}
											onChange={(option) => {
												setWeightValue(option.value);
												setWeightLabel(option.label);
											}
											}
										/>
									</Form.Group>
								</Col>
								<Col xl={12} lg={12} md={12} className="mb-3">
									<Form.Group id="social_media">
										<Form.Label className="required" style={{ display: "block" }}>Social Media </Form.Label>
										<div className="d-inline-block me-4">
											<div className="d-inline-block pe-2">
												<input type="checkbox" id="linkedin" checked={linkedIn} name="linkedin" onChange={(e) => { setLinkedIn(e.target.checked ? 1 : 0); }} style={{ cursor: 'pointer' }} />
											</div>
											<div className="d-inline-block">
												<label htmlFor="linkedin" style={{ cursor: 'pointer' }}>LinkedIn</label>
											</div>
										</div>
										<div className="d-inline-block me-4">
											<div className="d-inline-block pe-2">
												<input type="checkbox" id="instagram" checked={instagram} name="instagram" onChange={(e) => { setInstagram(e.target.checked ? 1 : 0); }} style={{ cursor: 'pointer' }} />
											</div>
											<div className="d-inline-block">
												<label htmlFor="instagram" style={{ cursor: 'pointer' }}>Instagram</label>
											</div>
										</div>
										<div className="d-inline-block me-4">
											<div className="d-inline-block pe-2">
												<input type="checkbox" id="whatsApp" checked={whatsApp} name="whatsApp" onChange={(e) => { setWhatsApp(e.target.checked ? 1 : 0); }} style={{ cursor: 'pointer' }} />
											</div>
											<div className="d-inline-block">
												<label htmlFor="whatsApp" style={{ cursor: 'pointer' }}>WhatsApp</label>
											</div>
										</div>
										<div className="d-inline-block me-4">
											<div className="d-inline-block pe-2">
												<input type="checkbox" id="tikTok" checked={tikTok} name="tikTok" onChange={(e) => { setTikTok(e.target.checked ? 1 : 0); }} style={{ cursor: 'pointer' }} />
											</div>
											<div className="d-inline-block">
												<label htmlFor="tikTok" style={{ cursor: 'pointer' }}>TikTok</label>
											</div>
										</div>
										<div className="d-inline-block me-4">
											<div className="d-inline-block pe-2">
												<input type="checkbox" id="twitter" checked={twitter} name="twitter" onChange={(e) => { setTwitter(e.target.checked ? 1 : 0); }} style={{ cursor: 'pointer' }} />
											</div>
											<div className="d-inline-block">
												<label htmlFor="twitter" style={{ cursor: 'pointer' }}>Twitter</label>
											</div>
										</div>
										<div className="d-inline-block me-4">
											<div className="d-inline-block pe-2">
												<input type="checkbox" id="facebook" checked={facebook} name="facebook" onChange={(e) => { setFacebook(e.target.checked ? 1 : 0); }} style={{ cursor: 'pointer' }} />
											</div>
											<div className="d-inline-block">
												<label htmlFor="facebook" style={{ cursor: 'pointer' }}>Facebook</label>
											</div>
										</div>
									</Form.Group>
								</Col>
								<Col md={6} className="mb-3">
									<Form.Group id="active">
										<Form.Label>Status</Form.Label>
										<div className="d-block">
											<div className="d-inline-block me-4">
												<div className="d-inline-block pe-2">
													<input type="radio" id="active1" name="active" label="Ja" checked={visualStatus === '1' ? true : false} value="1" onChange={(e) => {
														setVisualStatus(e.target.value);
													}} style={{ cursor: 'pointer' }}
													/>
												</div>
												<div className="d-inline-block">
													<label htmlFor="active1" style={{ cursor: 'pointer' }}>Active</label>
												</div>
											</div>
											<div className="d-inline-block">
												<div className="d-inline-block pe-2">
													<input type="radio" id="active0" name="active" label="Nein" checked={visualStatus === '0' ? true : false} value="0" onChange={(e) => {
														setVisualStatus(e.target.value);
													}} style={{ cursor: 'pointer' }}
													/>
												</div>
												<div className="d-inline-block">
													<label htmlFor="active0" style={{ cursor: 'pointer' }}>Inactive</label>
												</div>
											</div>
										</div>
									</Form.Group>
								</Col>
								{!startSave && <Col xl={5} lg={12} md={12} className="cancel_block">
									<div style={{ display: "flex" }} className="cancel_button">
										<Button className="list_button m-0" type="submit" >
											Save</Button>
										<Button
											className="list_button m-0"
											onClick={(e) => {
												e.preventDefault();
												navigate(`${Paths.country.path}`);
											}}
										>
											Cancel</Button>
									</div>
								</Col>}
								{editpage && <ModuleEditScreenOwnerInfo ownerInfo={ownerInfo} />}
							</Form>
						</DashboardCard>
					</Col>
				</Row>
			</LayoutWrapper>
		</>
	);
}

export default CountryForm;