import { scrollToTop } from "../../framework/utilities.js/utilities";

const API_URL = process.env.REACT_APP_API_URL;

export const deleteBtn = (params, record_title = 'name', setDelRecId, setDelRecTitle, setModalShow, record_id = 'value', type = 1) => {
    // type 2 is only for parts media module
    // type 3 is only for translation surface finish

    if (type === 3) setDelRecId(params.data[record_id]);
    else setDelRecId(params[record_id]);
    if (type === 2) setDelRecTitle(params.data.type + " > " + params.data[record_title]);
    else setDelRecTitle(params.data[record_title]);
    setModalShow(true);
}

export const handleOnDeleteSubmitted = (url = '', setSuccessMessage, setItemDeleted, setModalShow, setDelRecId, setDelRecTitle, itemDeleted, delRecId, gridApi, type = 1, button_data = '', setDisplayLoader) => {

    // type 2 is for ordered grid, which require reload on delete
    // type 3 is only for surface finish

    if (type !== 3) setSuccessMessage('');
    if (url.includes('translation')) setDisplayLoader(false);

    let formData = new FormData();
    formData.append("GID", delRecId);
    if (url.includes('deleteButton')) formData.append("options", button_data);

    fetch(API_URL + url, {
        method: 'POST',
        body: formData,
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem("access_token"),
        },
    })
        .then(response => response.json())
        .then(jsondata => {
            if (type === 3) {
                setItemDeleted(!itemDeleted);
                setModalShow(false);
                setDelRecId(0);
                setDelRecTitle('');
            } else {
                if(!url.includes('deleteSubNode')) scrollToTop();
                setSuccessMessage('Record deleted successfully.');
                setTimeout(function () {
                    setSuccessMessage('');
                    if (type === 2) window.location.reload(false);
                }, 2000);
                if (type === 1) setItemDeleted(!itemDeleted);
                setModalShow(false);
                setDelRecId(0);
                setDelRecTitle('');
                const selectedData = gridApi.getSelectedRows();
                gridApi.applyTransaction({ remove: selectedData });
            }
        }).catch((err) => {

        });
}