import Errorimage from '../../assets/images/404.svg'

const PageNotFound = () => {
    return (
        <div className='error404'>
            <img src={Errorimage} alt="PageNotFound" />
        </div>
    )
}

export default PageNotFound