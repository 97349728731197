import { useContext, useEffect, useState } from 'react';
import { useRoutes } from 'react-router-dom'
import AutoLogoutModal from './components/AutoLogoutModal';
import Route from './routes/route';
import DataContext from "./context/DataContext";
import './App.scss';
import { handleLogout } from './framework/utilities.js/utilities';

function App() {
	const { dispatchAuth } = useContext(DataContext);
	const API_URL = process.env.REACT_APP_API_URL;
	const API_KEY = process.env.REACT_APP_API_KEY;
	const [autoLogoutModal, setAutoLogoutModal] = useState(false);

	const tokenReceivedTime = parseInt(localStorage.getItem("tokenReceivedTime"));
	const refreshTime = tokenReceivedTime + 50 * 60 * 1000;
	const currTime = Date.now();

	useEffect(() => {
		// Check if token is expired and show the autoLogoutModal if it is
		if (tokenReceivedTime && currTime >= refreshTime) {
			let formData = new FormData();

			formData.append("refresh_token", localStorage.getItem("refresh_token"));
			formData.append("api_key", API_KEY);
			formData.append("action", 'refresh');
			fetch(API_URL + 'v1/user/refresh', {
				method: "POST",
				body: formData,
				headers: {
					Authorization: 'Bearer ' + localStorage.getItem("access_token"),
				},
			})
				.then((response) => response.json())
				.then((data) => {
					if (data.error) {
						setAutoLogoutModal(true);
						setTimeout(() => {
							setAutoLogoutModal(false);
							// Token has expired, clear local storage, and redirect to login
							handleLogout(dispatchAuth);
						}, 6000); // 6 seconds	
					} else {
						// Set the new access and refresh tokens
						const newAccessToken = data.access_token;
						const newRefreshToken = data.refresh_token;

						// Save the new tokens in localStorage for future use
						localStorage.setItem("access_token", newAccessToken);
						localStorage.setItem("refresh_token", newRefreshToken);

						// Change the tokenReceivedTime
						localStorage.setItem("tokenReceivedTime", Date.now());
					}
				})
				.catch((error) => {
					console.error("Error refreshing token:", error);
				});
		}
	}, [currTime, dispatchAuth, refreshTime, tokenReceivedTime]);
	// Check acess token present , if not present redirect to login
	// If present check if its valid by making api call (api needs to be created)

	useEffect(() => {
		if (localStorage.getItem("access_token") && localStorage.getItem("isLoggedin")) {
			dispatchAuth({
				type: 'SET_AUTH', setvalue: {
					'isLoggedin': true,
					'expires_in': localStorage.getItem("expires_in"),
					'access_token': localStorage.getItem("access_token"),
					'refresh_token': localStorage.getItem("refresh_token"),
					'user_profile': JSON.parse(localStorage.getItem("user_profile")),
					'access_modules': JSON.parse(localStorage.getItem("access_modules")),
				}
			});
		};
	}, [])

	return (
		<>
			<AutoLogoutModal
				modalShow={autoLogoutModal}
			/>
			{useRoutes(Route)}
		</>
	);
}

export default App;
