import React, { useEffect, useState, useContext } from "react";
import { Col, Row, Form, Alert, Button, Spinner } from 'react-bootstrap';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import LayoutWrapper from '../../components/UI/LayoutWrapper';
import DashboardCard from '../../components/UI/DashboardCard';
import Select from 'react-select'
import GlbList from "./GlbList";
import Uploader from "../../moduleBuilder/components/Uploader";
import { useParams, useNavigate } from "react-router-dom";
import { getOwnerInfo } from "../../framework/utilities.js/utilities";
import ModuleEditScreenOwnerInfo from "../../components/ModuleEditScreenOwnerInfo";
import { Paths } from "../../routes/route";
import DataContext from "../../context/DataContext";
import { useForm, useController } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { handleSave } from "../../helpers/API/Save";
import { getData } from "../../helpers/API/Misc";

const PartsMediaForm = (props) => {
	const schema = z.object({
		part_name: z.string({ required_error: 'Please enter the valid part name' }).min(1),
		category_id: z.number({ required_error: "Please select any one option" }),
	});

	const navigate = useNavigate();
	const { handleSubmit, register, setValue, control, formState: { errors } } = useForm({ resolver: zodResolver(schema) });
	const { part_id } = useParams();
	const { auth } = useContext(DataContext);
	let userId = auth[0].user_profile.GID;
	const API_URL = process.env.REACT_APP_API_URL;
	const { editform, editData, title } = props;
	const [selectOptions, setSelectOptions] = useState('');
	const [partsSelectOptions, setPartsSelectOptions] = useState([]);
	const [selectHidden, setSelectHidden] = useState('');
	const [componentHide, setComponentHide] = useState([]);
	const [componentHideData, setComponentHideData] = useState([]);
	const [startSave, setStartSave] = useState(false);

	const [fileUploadProgress, setFileUploadProgress] = useState(false);
	const [errMessage, setErrorMessage] = useState('');
	const [successMessage, setSuccessMessage] = useState('');
	const [weightLabel, setWeightLabel] = useState("");
	const [weight, setWeight] = useState(0);
	const [pdfFieldName, setPdfFieldName] = useState("");
	const [pdfPartName, setPdfPartName] = useState("");
	const [catId, setCatId] = useState(0);
	const [catName, setCatName] = useState("");
	const [active, setStatus] = useState('1');
	const [dedupstatus, setdedupstatus] = useState('0');
	const [createdAt, setCreatedAt] = useState("");
	const [updatedAt, setUpdatedAt] = useState("");
	const [createdBy, setCreatedBy] = useState("");
	const [updatedBy, setUpdatedBy] = useState("");

	const { field } = useController({ name: 'category_id', control })

	let [uploderState, setUploderState] = useState([]);
	const storedValue = localStorage?.getItem('viz_id');

	useEffect(() => {
		// Category dropdown
		getData(API_URL + 'v1/subCategories?visualizer_id=' + storedValue, 'POST', '', 2, setSelectOptions);

	}, [part_id]);

	useEffect(() => {

		// Hide Parts dropdown
		getData(API_URL + 'v1/parts/hideDropDownList?id=' + part_id + '&cid=' + catId + '&visualizer_id=' + storedValue, 'POST', '', 2, setPartsSelectOptions);

	}, [part_id, catId]);

	// Example load data from sever to hide component
	useEffect(() => {

		// Hide Components dropdown
		getData(API_URL + '/v1/colorcomponent/list?visualizer_id=' + storedValue, 'GET', setComponentHideData, 1, '', 'component_name', true, true, '');

	}, []);

	useEffect(() => {
		if (editData) {
			setValue("part_name", editData.name);
			setValue("category_id", editData.cat_id);
			setCatName(editData.type);
			setWeightLabel(editData.weight_label);
			setWeight(editData.weight);
			setPdfFieldName(editData.pdf_field_name);
			setPdfPartName(editData.pdf_part_name);
			setSelectHidden(editData.hide_part);
			setComponentHide(editData.hide_components);
			setCreatedAt(editData.created_at);
			setUpdatedAt(editData.updated_at);
			setCreatedBy(editData.created_by);
			setUpdatedBy(editData.updated_by);
			setStatus(editData.active ? '1' : '0');
		}
		if (!editform) {
			setStatus('1');
		}
	}, [props]);

	const ownerInfo = getOwnerInfo(createdAt, updatedAt, createdBy, updatedBy);

	const onSubmit = (data) => {

		setStartSave(true);

		setErrorMessage("");
		setSuccessMessage("");
		setFileUploadProgress(true);
		let formData = new FormData();
		if (part_id !== undefined) {
			formData.append("GID", part_id);
			formData.append("hide_part", JSON.stringify(selectHidden));
			formData.append("hide_components", JSON.stringify(componentHide));
		}
		for (let i = 0; i < uploderState.length; i++) {
			formData.append("FileData" + [i], uploderState[i]);
			formData.append("dedup" + [i], uploderState[i].dedup || 0);
		}
		formData.append("cat_id", data.category_id);
		formData.append("part_name", data.part_name);
		formData.append("weight_label", weightLabel);
		formData.append("weight", weight || 0);
		formData.append("pdf_field_name", pdfFieldName);
		formData.append("pdf_part_name", pdfPartName);
		formData.append("active", active || 0);
		formData.append("visualizer_id", storedValue);
		if (!editform) {
			formData.append("created_by", userId);
		} else {
			formData.append("updated_by", userId);
		}

		handleSave(API_URL + '/v1/part/save', setSuccessMessage, Paths.partsmedia.path, setErrorMessage, setStartSave, formData, navigate);
	}

	const handleSelectChange = (option) => {
		field.onChange(option.value)
		setCatId(option.value);
		setCatName(option.label);
	}

	return (
		<>
			<LayoutWrapper breadcrumb_title={Paths.partsmedia.name}
				form={true}
				label={Paths.partsmedia.name}
				linkto={Paths.partsmedia.path}
				title={title}>
				{successMessage && !errMessage && <Alert variant="success">{successMessage}</Alert>}
				<Row>
					<Col lg='12' className=''>
						<DashboardCard className='pt-4'>
							<h5 className="w-100 px-4 py-4 m-0">{title}</h5>
							<Form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" className="w-100 px-4 pb-4" >
								<Row>
									<Col xl={12} lg={12} md={12} className="mb-3">
										<Form.Group id="part_name">
											<Form.Label className="required " >Part name <span style={{ color: 'red', marginLeft: '4px' }}>*</span></Form.Label>
											<Form.Control
												{...register("part_name")}
											/>
										</Form.Group>
										<div style={{ color: 'red' }}>{errors.part_name?.message}</div>
									</Col>
								</Row>
								<Row>
									<Col xl={12} lg={12} md={12} className="mb-3">
										<Form.Label>Category <span style={{ color: 'red', marginLeft: '4px' }}>*</span></Form.Label>
										<Form.Group id="category_id">
											<Select
												{...register("category_id")}
												value={{ value: catId, label: catName }}
												onChange={handleSelectChange}
												options={selectOptions}
												isSearchable
											/>
										</Form.Group>
										<div style={{ color: 'red' }}>{errors.category_id?.message}</div>
									</Col>
								</Row>
								{editform === 1 && <Row>
									<Col xl={12} lg={12} md={12} className="mb-3">
										<Form.Label>Hide parts</Form.Label>
										<Form.Group id="selectHidden">
											<Select
												isMulti
												name="selectHidden"
												value={selectHidden}
												options={partsSelectOptions}
												onChange={event => {
													setSelectHidden(event);
												}}
											/>
										</Form.Group>
									</Col>
								</Row>}
								{editform === 1 && <Row>
									<Col xl={12} lg={12} md={12} className="mb-3">
										<Form.Label>Hide components</Form.Label>
										<Form.Group id="hidecomponent">
											<Select
												isMulti
												name="hidecomponent"
												value={componentHide}
												options={componentHideData}
												onChange={event => {
													setComponentHide(event);
												}}
											/>
										</Form.Group>
									</Col>
								</Row>}
								<Row>
									<Col xl={12} lg={12} md={12} className="mb-3">
										<Form.Group id="weight_label">
											<Form.Label className="required ">Weight label</Form.Label>
											<Form.Control name="weight_label" value={weightLabel} type="text" onChange={e => {
												setWeightLabel(e.target.value);
											}} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col xl={12} lg={12} md={12} className="mb-3">
										<Form.Group id="weight">
											<Form.Label className="required ">Weight (g)</Form.Label>
											<Form.Control name="weight" value={weight} type="text" onChange={e => {
												setWeight(e.target.value);
											}} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col xl={12} lg={12} md={12} className="mb-3">
										<Form.Group id="pdf_field_name">
											<Form.Label className="required ">PDF Field Name</Form.Label>
											<Form.Control name="pdf_field_name" value={pdfFieldName} type="text" onChange={e => {
												setPdfFieldName(e.target.value);
											}} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col xl={12} lg={12} md={12} className="mb-3">
										<Form.Group id="pdf_part_name">
											<Form.Label className="required ">Item Code</Form.Label>
											<Form.Control name="pdf_part_name" value={pdfPartName} type="text" onChange={e => {
												setPdfPartName(e.target.value);
											}} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col md={12} className="mb-3">
										<Form.Group id="active">
											<Form.Label>Status</Form.Label>
											<div className="d-block">
												<div className="d-inline-block me-4">
													<div className="d-inline-block pe-2">
														<input type="radio" id="active1" name="active" label="Ja" checked={active === '1' ? true : false} value="1" onChange={(e) => {
															setStatus(e.target.value);
														}} style={{ cursor: 'pointer' }}
														/>
													</div>
													<div className="d-inline-block">
														<label htmlFor="active1" style={{ cursor: 'pointer' }}>Active</label>
													</div>
												</div>
												<div className="d-inline-block">
													<div className="d-inline-block pe-2">
														<input type="radio" id="active0" name="active" label="Nein" checked={active === '0' ? true : false} value="0" onChange={(e) => {
															setStatus(e.target.value);
														}} style={{ cursor: 'pointer' }}
														/>
													</div>
													<div className="d-inline-block">
														<label htmlFor="active0" style={{ cursor: 'pointer' }}>Inactive</label>
													</div>
												</div>
											</div>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col xl={12} lg={12} md={12} className="mb-3">
										<Uploader maxFiles={12} uploderState={uploderState} setUploderState={setUploderState} counter={1} dedup={1} status={dedupstatus} setdedupstatus={setdedupstatus} accept={{ 'text/glb': ['.glb', '.gltf'] }} filesAcceptedTxt="You can only upload GLBs here" maxSizeLabel='with combined size less than 50 MB' />
										{((fileUploadProgress === true) && !errMessage) &&
											<>
												<Button variant="primary" disabled>
													<Spinner
														as="span"
														animation="grow"
														size="sm"
														role="status"
														aria-hidden="true"
													/>
													Uploading. Please wait...
												</Button>
											</>
										}
									</Col>
								</Row>
								{!startSave && <Row>
									<Col xl={5} lg={12} md={12} className="cancel_block">
										<div style={{ display: "flex" }} className="cancel_button">
											<Button className="list_button m-0" type="submit" >
												Save</Button>
											<Button
												className="list_button m-0"
												onClick={(e) => {
													e.preventDefault();
													navigate(`${Paths.partsmedia.path}`);
												}}
											>
												Cancel</Button>
										</div>
									</Col>
								</Row>}
								{editform && <ModuleEditScreenOwnerInfo ownerInfo={ownerInfo} />}
							</Form>
						</DashboardCard>
					</Col>
					{editform &&
						<Col className='mt-2' lg={10}>
							<GlbList />
						</Col>
					}
				</Row>
			</LayoutWrapper>
		</>
	);
}

export default PartsMediaForm;