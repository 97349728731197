import React, { useEffect, useState, useContext } from "react";
import { Col, Row, Form, Alert, Button, Spinner, InputGroup } from 'react-bootstrap';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import LayoutWrapper from '../../components/UI/LayoutWrapper';
import DashboardCard from '../../components/UI/DashboardCard';
import Select from 'react-select'
import Uploader from "../../moduleBuilder/components/Uploader";
import { useParams, useNavigate } from "react-router-dom";
import ModuleEditScreenOwnerInfo from "../../components/ModuleEditScreenOwnerInfo";
import { Paths } from "../../routes/route";
import DataContext from "../../context/DataContext";
import { useForm } from "react-hook-form";
import { getOwnerInfo } from "../../framework/utilities.js/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import UnorderedGrid from "../../components/UnorderedGrid";
import delete_icon from "../../assets/images/delete.svg";
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal";
import { handleSave } from "../../helpers/API/Save";
import { getData } from "../../helpers/API/Misc";
import { deleteBtn, handleOnDeleteSubmitted } from "../../helpers/API/Delete";

const ColorForm = (props) => {
	const { handleSubmit, register, setValue, formState: { errors } } = useForm();
	const navigate = useNavigate();
	const { col_id } = useParams();
	const { auth } = useContext(DataContext);
	const userId = auth[0].user_profile.GID;
	const { editpage, editData, title } = props;

	const [hexCode, setHexCode] = useState("");
	const [metalness, setMetalness] = useState(1);
	const [roughness, setRoughness] = useState(0);
	const [tooltipText, setTooltipText] = useState("");
	const [errMessage, setErrorMessage] = useState('');
	const [successMessage, setSuccessMessage] = useState('');
	const [startSave, setStartSave] = useState(false);
	const API_URL = process.env.REACT_APP_API_URL;
	const [active, setStatus] = useState(1);

	const [createdAt, setCreatedAt] = useState("");
	const [updatedAt, setUpdatedAt] = useState("");
	const [createdBy, setCreatedBy] = useState("");
	const [updatedBy, setUpdatedBy] = useState("");

	useEffect(() => {
		if (editData) {
			setValue("color_name", editData.name);
			setHexCode(editData.code);
			setMetalness(editData.metalness);
			setRoughness(editData.roughness);
			setTooltipText(editData.tooltip_text);
			setSurfaceFinishLabel(editData.surface_finish);
			setRepeatX(editData.repeat_x);
			setRepeatY(editData.repeat_y);
			setStatus(editData.active ? 1 : 0);
			setCreatedAt(editData.created_at);
			setUpdatedAt(editData.updated_at);
			setCreatedBy(editData.created_by);
			setUpdatedBy(editData.updated_by);
		}
		if (!editpage) {
			setStatus(1);
		}
	}, [props]);

	const ownerInfo = getOwnerInfo(createdAt, updatedAt, createdBy, updatedBy);


	let [surfaceFinishValue, setSurfaceFinishValue] = useState('');
	let [surfaceFinishLabel, setSurfaceFinishLabel] = useState('');

	const [repeatX, setRepeatX] = useState(0);
	const [repeatY, setRepeatY] = useState(0);

	const [surfaceFinishData, SetSurfaceFinishData] = useState([]);
	const [fileUploadProgress, setFileUploadProgress] = useState(false);
	const [fileUploadProgress1, setFileUploadProgress1] = useState(false);
	let [uploderState, setUploderState] = useState([]);
	let [uploderState2, setUploderState2] = useState([]);
	const storedValue = localStorage?.getItem('viz_id');

	const [showGridPopup, setShowGridPopup] = useState(false);
	const [gridData, setGridData] = useState([]);
	const [surfaceFinishAdded, setSurfaceFinishAdded] = useState(false);
	const [surfaceFinishEdited, setSurfaceFinishEdited] = useState(false);
	const [surfaceFinishDeleted, setSurfaceFinishDeleted] = useState(false);
	const [modalShow, setModalShow] = useState(false);
	const [delRecTitle, setDelRecTitle] = useState('');
	const [delRecId, setDelRecId] = useState(0);

	const columnDefs = [
		{ headerName: "Name", field: "name", editable: true },
		{ headerName: "Metalness", field: "metalness", editable: true },
		{ headerName: "Roughness", field: "roughness", editable: true },
		{
			headerName: "",
			field: "",
			width: 60,
			cellRendererFramework: (params) => (
				<div className="text-column d-flex justify-content-between">
					<span
						className="delete-icon delete-icon-color link"
						onClick={() => deleteBtn(params, 'name', setDelRecId, setDelRecTitle, setModalShow, 'id', 3)}
						title="Delete"
					>
						<img
							src={delete_icon}
							alt="delete"
							className=""
							style={{ width: '24px', height: '24px' }}
						/>
					</span>
					<span className="ellipsis" title={params.value}>
						{params.value}
					</span>
				</div>
			),
		},
	];

	useEffect(() => {

		// Surface Finish dropdown
		getData(API_URL + 'v1/color/listSurfaceFinish', 'GET', SetSurfaceFinishData, 3, setGridData, 'name');

	}, [surfaceFinishAdded, surfaceFinishEdited, surfaceFinishDeleted]);

	const handleAddSurfaceFinish = () => {

		handleSave(API_URL + 'v1/color/addSurfaceFinish', ...Array(8).fill(''), 4, '', '', '', '', '', setSurfaceFinishAdded, surfaceFinishAdded);
	};


	const handleToggleGridPopup = () => {
		setShowGridPopup(!showGridPopup);
	};

	const handleCellEditingStopped = (event) => {
		const { data, newValue, oldValue } = event;
		const id = data.id;
		const name = data.name;
		const metalness = data.metalness;
		const roughness = data.roughness;

		if (oldValue !== newValue && newValue !== undefined) {
			let formData = new FormData();
			formData.append("id", id);
			formData.append("name", name);
			formData.append("metalness", metalness);
			formData.append("roughness", roughness);

			handleSave(API_URL + 'v1/color/editSurfaceFinish', '', '', '', '', formData, '', '', '', 4);
		}
		setSurfaceFinishEdited(!surfaceFinishEdited);
	};

	const onDelete = () => {

		handleOnDeleteSubmitted('/v1/color/deleteSurfaceFinish', '', setSurfaceFinishDeleted, setModalShow, setDelRecId, setDelRecTitle, surfaceFinishDeleted, delRecId, '', 3);
	}

	const handleSurfaceFinishChange = (data) => {
		// Find the corresponding item in gridData
		const selectedItem = gridData.find(item => item.name === data.label);

		// If a matching item is found, set the metalness and roughness
		if (editpage && data.label === editData.surface_finish) {
			setMetalness(editData.metalness);
			setRoughness(editData.roughness);
		} else {
			if (selectedItem) {
				setMetalness(selectedItem.metalness);
				setRoughness(selectedItem.roughness);
			} else {
				// If no matching item is found, set default values
				setMetalness(0);
				setRoughness(0);
			}
		}

		// Set the surface finish value and label
		setSurfaceFinishValue(data.value);
		setSurfaceFinishLabel(data.label);
	};

	const onSubmit = (data) => {

		setStartSave(true);

		setErrorMessage("");
		setSuccessMessage("");
		setFileUploadProgress(true);
		setFileUploadProgress1(true);

		const fields = [
			{ name: 'surface_finish', value: surfaceFinishLabel },
			{ name: 'tooltip_text', value: tooltipText }
		];

		let formData = new FormData();
		if (col_id !== undefined) {
			formData.append("GID", col_id);
		}

		for (let i = 0; i < uploderState.length; i++) {
			formData.append("FileData" + [i], uploderState[i]);
			formData.append("counter0", 1);
		}
		for (let i = 0; i < uploderState2.length; i++) {
			formData.append("FileData1" + [i], uploderState2[i]);
			formData.append("counter1", 2);
		}
		formData.append("color_name", data.color_name);
		formData.append("hex_code", hexCode);
		formData.append("metalness", metalness);
		formData.append("roughness", roughness);
		formData.append("repeat_x", repeatX || 0);
		formData.append("repeat_y", repeatY || 0);
		formData.append("active", active);
		formData.append("visualizer_id", storedValue);
		if (!editpage) {
			formData.append("created_by", userId);
		} else {
			formData.append("updated_by", userId);
		}

		fields.forEach(field => {
			if (field.value !== undefined && field.value !== null) {
				formData.append(field.name, field.value);
			}
		});

		handleSave(API_URL + '/v1/color/save', setSuccessMessage, Paths.colours.path, setErrorMessage, setStartSave, formData, navigate, setFileUploadProgress, setUploderState, 3, setFileUploadProgress1, setUploderState2, setHexCode, setStatus);
	}

	return (
		<>
			<LayoutWrapper breadcrumb_title='Colours Module'
				form={true}
				label={Paths.colours.name}
				linkto={Paths.colours.path}
				title={title}
				modal={
					<ConfirmDeleteModal
						modalShow={modalShow}
						handleModelClose={() => { setModalShow(false); }}
						delRecTitle={delRecTitle}
						handleOnDeleteSubmitted={onDelete}
					/>
				}
			>
				{errMessage && <Alert variant="danger">{errMessage}</Alert>}
				{successMessage && !errMessage && <Alert variant="success">{successMessage}</Alert>}
				<Row>
					<Col lg='12' className=''>
						<DashboardCard className='pt-4'>
							<h5 className="w-100 px-4 py-4 m-0">{title}</h5>
							<Form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" className="w-100 px-4 pb-4" >
								<Row>
									<Col xl={12} lg={12} md={12} className="mb-3">
										<Form.Group id="color_name">
											<Form.Label className="required">Colour name <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
												{editData?.name && <span class="text-muted" style={{ 'font-size': '13px' }}> <FontAwesomeIcon icon={faTriangleExclamation} /> This field is used in order PDF and cannot be edited </span>} </Form.Label >
											<Form.Control
												{...register("color_name", {
													required: 'Color name is required'
												})}
												className={`${(editData?.name) ? 'disable' : ''}`} />
										</Form.Group>
										{errors.color_name?.message && (
											<small style={{ color: 'red' }}>{errors.color_name.message}</small>
										)}
									</Col>
								</Row>
								<Row>
									<Col xl={12} lg={12} md={12} className="mb-3">
										<Form.Group id="hex_code">
											<Form.Label className="required ">Hex code</Form.Label>
											<Form.Control name="hex_code" value={hexCode} type="color" onChange={e => {
												setHexCode(e.target.value);
											}} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col xl={12} lg={12} md={12} className="mb-3">
										<Button type="button" onClick={handleToggleGridPopup}>
											{showGridPopup ? 'Hide surface finish' : 'Show surface finish'}
										</Button>
										{showGridPopup && (
											<Button variant="primary" onClick={handleAddSurfaceFinish}>
												Add
											</Button>
										)}
										{showGridPopup && <UnorderedGrid columnDefs={columnDefs} gridData={gridData} handleCellEditingStopped={handleCellEditingStopped} />}
									</Col>
								</Row>
								<Row>
									<Col xl={12} lg={12} md={12} className="mb-3">
										<Form.Group id="component">
											<Form.Label>Surface finish</Form.Label>
											<Select
												isSearchable
												name="surface_finish"
												options={surfaceFinishData}
												value={{ value: surfaceFinishValue, label: surfaceFinishLabel }}
												onChange={handleSurfaceFinishChange}
											/>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col xl={12} lg={12} md={12} className="mb-3">
										<Form.Group id="metalness">
											<Form.Label className="required ">Metalness </Form.Label>
											<Form.Control name="metalness" type="number" value={metalness} placeholder="enter value 0-1" onChange={e => {
												setMetalness(e.target.value);
											}} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col xl={12} lg={12} md={12} className="mb-3">
										<Form.Group id="roughness">
											<Form.Label className="required ">Roughness </Form.Label>
											<Form.Control name="roughness" type="number" value={roughness} placeholder="enter value 0-1" onChange={e => {
												setRoughness(e.target.value);
											}} />
										</Form.Group>
									</Col>
								</Row>

								<Row>
									<Col xl={12} lg={12} md={12} className="mb-3">
										<Form.Group id="tooltip_text">
											<Form.Label className="required ">Tooltip text</Form.Label>
											<Form.Control name="tooltip_text" value={tooltipText} type="text" placeholder="" onChange={e => {
												setTooltipText(e.target.value);
											}} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col>Colour icon: {editData?.url && <a href={editData?.url} target="_blank" rel="noreferrer">{editData?.url}</a>}</Col>
								</Row>
								<Row>
									<Col xl={12} lg={12} md={12} className="mb-3">
										<Uploader uploderState={uploderState} setUploderState={setUploderState} counter={1} maxSize={1000000} maxSizeLabel='with size less than 10 MB' />
										{((fileUploadProgress === true) && !errMessage) &&
											<>
												<Button variant="primary" disabled>
													<Spinner
														as="span"
														animation="grow"
														size="sm"
														role="status"
														aria-hidden="true"
													/>
													Uploading. Please wait...
												</Button>
											</>
										}
									</Col>
								</Row>
								<Row>
									<Col>Texture image: {editData?.url1 && <a href={editData?.url1} target="_blank" rel="noreferrer">{editData?.url1}</a>}</Col>
								</Row>
								<Row>
									<Col xl={12} lg={12} md={12} className="mb-3">
										<Uploader uploderState={uploderState2} setUploderState={setUploderState2} counter={2} maxSize={1000000} maxSizeLabel='with size less than 10 MB' />
										{((fileUploadProgress1 === true) && !errMessage) &&
											<>
												<Button variant="primary" disabled>
													<Spinner
														as="span"
														animation="grow"
														size="sm"
														role="status"
														aria-hidden="true"
													/>
													Uploading. Please wait...
												</Button>
											</>
										}
									</Col>
								</Row>
								{(uploderState2.length > 0 || editData?.url1) &&
									<Row>
										<Col xl={12} lg={12} md={12} className="mb-3">
											<InputGroup className="mb-3">
												<InputGroup.Text>Texture</InputGroup.Text>
												<InputGroup.Text>Repeat  X</InputGroup.Text><Form.Control aria-label="x"
													name="repeat_x"
													value={repeatX}
													onChange={(e) => {
														setRepeatX(e.target.value);
													}}
												/>
												<InputGroup.Text>Repeat Y</InputGroup.Text> <Form.Control aria-label="y"
													name="repeat_y"
													value={repeatY}
													onChange={(e) => {
														setRepeatY(e.target.value);
													}}
												/>
											</InputGroup>
										</Col>
									</Row>
								}
								<Row>
									<Col md={12} className="mb-3">
										<Form.Group id="active">
											<Form.Label>Status</Form.Label>
											<div className="d-block">
												<div className="d-inline-block me-4">
													<div className="d-inline-block pe-2">
														<input type="radio" id="active1" name="active" label="Ja" checked={Number(active) === 1 ? true : false} value="1" onChange={(e) => {
															setStatus(e.target.value);
														}} style={{ cursor: 'pointer' }}
														/>
													</div>
													<div className="d-inline-block">
														<label htmlFor="active1" style={{ cursor: 'pointer' }}>Active</label>
													</div>
												</div>
												<div className="d-inline-block">
													<div className="d-inline-block pe-2">
														<input type="radio" id="active0" name="active" label="Nein" checked={Number(active) === 0 ? true : false} value="0" onChange={(e) => {
															setStatus(e.target.value);
														}} style={{ cursor: 'pointer' }}
														/>
													</div>
													<div className="d-inline-block">
														<label htmlFor="active0" style={{ cursor: 'pointer' }}>Inactive</label>
													</div>
												</div>
											</div>
										</Form.Group>
									</Col>
								</Row>
								{!startSave && <Row>
									<Col xl={5} lg={12} md={12} className="cancel_block">
										<div style={{ display: "flex" }} className="cancel_button">
											<Button className="list_button m-0" type="submit" >
												Save
											</Button>
											<Button
												className="list_button m-0"
												onClick={(e) => {
													e.preventDefault();
													navigate(`${Paths.colours.path}`);
												}}
											>
												Cancel
											</Button>
										</div>
									</Col>
								</Row>}
								{editpage && <ModuleEditScreenOwnerInfo ownerInfo={ownerInfo} />}
							</Form>
						</DashboardCard>
					</Col>
				</Row>
			</LayoutWrapper>
		</>
	);
}

export default ColorForm;