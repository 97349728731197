import UserRoleForm from './UserRoleForm';
import { useParams } from "react-router-dom";
import useFetch from "../../framework/hooks/useFetch";
import { Paths } from '../../routes/route';

const Edit = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const { role_id } = useParams();

    /* Fetching data and setting the values to the form elements */
    const { data } = useFetch(API_URL + '/v1/userroles/data/view/' + role_id, 'GET', true);

    let permVal = data?.permissions;

    let selectedVisualiser = [];
    if (data?.visualiser) {
        selectedVisualiser = JSON.parse(data?.visualiser);
    }

    return (
        <>
            {data && <UserRoleForm data={data} title={Paths.userrole.c2_name} permission={permVal} selectedVisualiser={selectedVisualiser} />}
        </>
    );
}

export default Edit;

