import { useCallback, useState, useRef, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Col, Row } from 'react-bootstrap';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const Grid = (props) => {
    const gridRef = useRef();

    const [rowData, setRowData] = useState([]);

    const columnDefs = props.columnDefs;
    let immutableStore;

    const onGridReady = useCallback(() => {
        immutableStore = props.gridData;
        setRowData(immutableStore);
    }, []);

    const onRowDragMove = useCallback(
        (event) => {
            let movingNode = event.node;
            let overNode = event.overNode;
            let rowNeedsToMove = movingNode !== overNode;
            if (rowNeedsToMove) {
                // The list of rows we have is data, not row nodes, so extract the data
                let movingData = movingNode.data;
                let overData = overNode?.data;
                let fromIndex = immutableStore.indexOf(movingData);
                let toIndex = immutableStore.indexOf(overData);
                let newStore = immutableStore.slice();
                moveInArray(newStore, fromIndex, toIndex);
                immutableStore = newStore;
                setRowData(newStore);
                gridRef.current.api.clearFocusedCell();
            }
            function moveInArray(arr, fromIndex, toIndex) {
                let element = arr[fromIndex];
                arr.splice(fromIndex, 1);
                arr.splice(toIndex, 0, element);
            }
        },
        [immutableStore]
    );

    useEffect(() => {
        let formData = new FormData();
        formData.append("gridData", JSON.stringify(rowData));
        let API_URL = process.env.REACT_APP_API_URL + props.url;
        fetch(API_URL, {
            method: 'POST',
            body: formData,
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("access_token"),
            },
        })
            .then(response => response.json())
            .then(jsondata => {
                //  console.log(jsondata);
            }).catch((err) => {
                // console.log("Login error: " + err);
            });
        // eslint-disable-next-line
    }, [rowData]);

    const getRowId = useCallback((params) => {
        return params.data.id;
    }, []);

    return (
        <Row>
            <Col>
                <div className="ag-theme-alpine" style={{ width: '100%', height: 500 }}>
                    <AgGridReact
                        ref={gridRef}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        animateRows={true}
                        pagination={true}
                        rowSelection="single"
                        getRowId={getRowId}
                        onGridReady={onGridReady}
                        onRowDragMove={onRowDragMove}
                    >
                    </AgGridReact>
                </div>
            </Col>
        </Row>
    );
}

export default Grid;