import React, { useState, useEffect, useContext } from "react";
import { Col, Row, Form, Button, Alert } from 'react-bootstrap';
import LayoutWrapper from "../../components/UI/LayoutWrapper";
import DashboardCard from '../../components/UI/DashboardCard'
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useParams, useNavigate } from "react-router-dom";
import Select from 'react-select';
import ModuleEditScreenOwnerInfo from "../../components/ModuleEditScreenOwnerInfo";
import { Paths } from "../../routes/route";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import DataContext from "../../context/DataContext";
import { getOwnerInfo } from "../../framework/utilities.js/utilities";
import { handleSave } from "../../helpers/API/Save";
import { getData } from "../../helpers/API/Misc";

const VisualizerForm = (props) => {
	const schema = z.object({
		visualizer_name: z.string({ required_error: 'Please enter the valid visualizer name' }),
	});

	const navigate = useNavigate();
	const { handleSubmit, register, setValue, formState: { errors } } = useForm({ resolver: zodResolver(schema) });
	const API_URL = process.env.REACT_APP_API_URL;
	const { id } = useParams();
	const { auth } = useContext(DataContext);
	let userId = auth[0].user_profile.GID;
	const { editpage, editData, title } = props;

	const [visualStatus, setVisualStatus] = useState(1);
	const [errMessage, setErrorMessage] = useState('');
	const [successMessage, setSuccessMessage] = useState('');
	const [langId, setLangId] = useState('');
	const [langName, setLangName] = useState('');
	const [countryId, setCountryId] = useState('');
	const [countryName, setCountryName] = useState('');
	const [selectLangOptions, setSelectLangOptions] = useState([]);
	const [selectCountryOptions, setSelectCountryOptions] = useState([]);
	const [startSave, setStartSave] = useState(false);

	const [createdAt, setCreatedAt] = useState("");
	const [updatedAt, setUpdatedAt] = useState("");
	const [createdBy, setCreatedBy] = useState("");
	const [updatedBy, setUpdatedBy] = useState("");

	// Weight unit dropdown
	useEffect(() => {

		// Country dropdown
		getData(API_URL + '/v1/country/list?visualizer_id=' + id, 'GET', setSelectCountryOptions, 1, '', 'country_name', true);

		// Language dropdown
		getData(API_URL + '/v1/language/languageList?visualizer_id=' + id, 'GET', setSelectLangOptions, 1, '', 'name', true);

	}, [id]);

	// Edited data
	useEffect(() => {
		if (editData) {
			setValue("visualizer_name", editData.visualizer_name);
			setLangId(editData.lang_code);
			setLangName(editData.lang_name);
			setCountryId(editData.country_code);
			setCountryName(editData.country_name);
			setVisualStatus(editData.active ? '1' : '0');
			setCreatedAt(editData.created_at);
			setUpdatedAt(editData.updated_at);
			setCreatedBy(editData.created_by);
			setUpdatedBy(editData.updated_by);
		}

		if (!editpage) {
			setVisualStatus('1');
		}
	}, [props]);

	const ownerInfo = getOwnerInfo(createdAt, updatedAt, createdBy, updatedBy);

	// Saving
	const onSubmit = (data) => {

		setStartSave(true);

		let formData = new FormData();
		formData.append("visualizer_name", data.visualizer_name);
		formData.append("country_code", countryId || 0);
		formData.append("lang_code", langId || 0);
		formData.append("active", visualStatus);
		if (!editpage) {
			formData.append("created_by", userId);
		} else {
			formData.append("updated_by", userId);
		}

		if (id !== undefined) {
			formData.append("GID", id);
		}

		handleSave(API_URL + '/v1/visualizer/save', setSuccessMessage, Paths.visualizer.path, setErrorMessage, setStartSave, formData, navigate);
	}

	return (
		<>
			<LayoutWrapper breadcrumb_title={Paths.visualizer.name}
				form={true}
				label={Paths.visualizer.name}
				linkto={Paths.visualizer.path}
				title={title}>
				<Row>
					<Col lg={12} className=''>
						{errMessage && <Alert variant="danger">{errMessage}</Alert>}
						{successMessage && !errMessage && <Alert variant="success">{successMessage}</Alert>}
						<DashboardCard className='pt-4'>
							<h5 className="w-100 px-4 py-4 m-0">{title}</h5>
							<Form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" className="w-100 px-4 pb-4" >
								<Col xl={12} lg={12} md={12} className="mb-3">
									<Form.Group id="visualizer_name">
										<Form.Label>Visualizer name <span style={{ color: 'red', marginLeft: '4px' }}>*</span></Form.Label>
										<Form.Control
											{...register("visualizer_name")}
										/>
									</Form.Group>
									<div style={{ color: 'red' }}>{errors.visualizer_name?.message}</div>
								</Col>
								{editpage && <Col xl={12} lg={12} md={12} className="mb-3">
									<Form.Label>Default language</Form.Label>
									<Form.Group id="lang_id">
										<Select
											value={{ value: langId, label: langName }}
											onChange={(event) => {
												setLangId(event.value);
												setLangName(event.label);
											}
											}
											options={selectLangOptions}
											isSearchable
										/>
									</Form.Group>
								</Col>}
								{editpage && <Col xl={12} lg={12} md={12} className="mb-3">
									<Form.Label>Default Country</Form.Label>
									<Form.Group id="country_id">
										<Select
											value={{ value: countryId, label: countryName }}
											onChange={(event) => {
												setCountryId(event.value);
												setCountryName(event.label);
											}
											}
											options={selectCountryOptions}
											isSearchable
										/>
									</Form.Group>
								</Col>}
								<Col md={6} className="mb-3">
									<Form.Group id="active">
										<Form.Label>Status</Form.Label>
										<div className="d-block">
											<div className="d-inline-block me-4">
												<div className="d-inline-block pe-2">
													<input type="radio" id="active1" name="active" label="Ja" checked={visualStatus === '1' ? true : false} value="1" onChange={(e) => {
														setVisualStatus(e.target.value);
													}} style={{ cursor: 'pointer' }}
													/>
												</div>
												<div className="d-inline-block">
													<label htmlFor="active1" style={{ cursor: 'pointer' }}>Active</label>
												</div>
											</div>
											<div className="d-inline-block">
												<div className="d-inline-block pe-2">
													<input type="radio" id="active0" name="active" label="Nein" checked={visualStatus === '0' ? true : false} value="0" onChange={(e) => {
														setVisualStatus(e.target.value);
													}} style={{ cursor: 'pointer' }}
													/>
												</div>
												<div className="d-inline-block">
													<label htmlFor="active0" style={{ cursor: 'pointer' }}>Inactive</label>
												</div>
											</div>
										</div>
									</Form.Group>
								</Col>
								{!startSave && <Col xl={5} lg={12} md={12} className="cancel_block">
									<div style={{ display: "flex" }} className="cancel_button">
										<Button className="list_button m-0" type="submit" >
											Save</Button>
										<Button
											className="list_button m-0"
											onClick={(e) => {
												e.preventDefault();
												navigate(`${Paths.visualizer.path}`);
											}}
										>
											Cancel</Button>
									</div>
								</Col>}
								{editpage && <ModuleEditScreenOwnerInfo ownerInfo={ownerInfo} />}
							</Form>
						</DashboardCard>
					</Col>
				</Row>
			</LayoutWrapper>
		</>
	);
}

export default VisualizerForm;