import React from "react";
import ConfiguratorNodeForm from "../NodeConfiguration/Form";
import { Paths } from "../../routes/route";
const Add = () => {
    return (
        <>
            <ConfiguratorNodeForm addform={1} title={Paths.nodesconfiguration.c3_name} type={2} />
        </>
    );
}

export default Add;