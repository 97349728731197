import { useContext } from 'react';
import { Outlet } from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faAngleRight, faMagnifyingGlass, faHouse, faUsers, faFolderOpen, faUpload, faPalette, faLanguage, faTentArrowLeftRight, faRightFromBracket, faMoon, faSun, faDiagramSuccessor, faGears, faLocationDot } from '@fortawesome/free-solid-svg-icons';

import DataContext from './context/DataContext';
import Sidebar from './components/UI/Sidebar';
import Header from './components/UI/Header';

const Layout = () => {
    library.add(faAngleRight, faMagnifyingGlass, faHouse, faUsers, faFolderOpen, faUpload, faPalette, faLanguage, faTentArrowLeftRight, faRightFromBracket, faMoon, faSun, faDiagramSuccessor, faGears, faLocationDot);

    const { auth } = useContext(DataContext);

    return (
        <>
            <main className="App">
                <div className="layout-container">
                    {auth[0].isLoggedin &&
                        <>
                            <Sidebar />
                            <Header className='header' />
                            <div className="layout-page">
                                <Outlet />
                            </div>
                        </>
                    }
                    {!auth[0].isLoggedin && <Outlet />}
                </div>
            </main>
        </>
    )
}

export default Layout