import React, { useState, useEffect } from "react";
import ConfirmDeleteModal from "./ConfirmDeleteModal";

const AutoLogoutModal = ({ modalShow }) => {

	const [countdown, setCountdown] = useState(5);
	const message = "Session Timeout! You will be redirected to the login page in " + countdown + " seconds";

	// Function to reset the countdown timer
	const resetCountdown = () => {
		setCountdown(5); // Reset the countdown to 5
	};

	useEffect(() => {
		// Start the countdown timer when the modal is shown
		let timer = null;
		if (modalShow) {
			resetCountdown(); // Reset the countdown before starting
			timer = setInterval(() => {
				setCountdown((prevCountdown) => prevCountdown - 1);
			}, 1000); // Update the countdown every second
		} else {
			// Reset the countdown when the modal is hidden
			setTimeout(() => {
				resetCountdown();
			}, 1000);
		}

		return () => {
			// Clean up the timer when the component unmounts or modalShow becomes false
			clearInterval(timer);
		};
	}, [modalShow]);

	return (

		<ConfirmDeleteModal modalShow={modalShow} labelBtn={message} modalTitle="Error" className="autologout" />
	);
}

export default AutoLogoutModal;
