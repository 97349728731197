import { Col, Row, Card } from '@themesberg/react-bootstrap';
import ActivityLog from "./ActivityLog";

const ModuleEditScreenOwnerInfo = (props) => {

    return (
        <>
            <Card.Footer>
                <Row>
                    <Col xl={7} lg={12} md={12}>
                        {props.ownerInfo &&
                            <ActivityLog ownerInfo={props.ownerInfo} />
                        }
                    </Col>
                </Row>
            </Card.Footer>
        </>
    );
}

export default ModuleEditScreenOwnerInfo; 