export const handleOpenInNewTab = (event) => {
    if (event.button === 0) {
        // Open link in the same tab
        return;
    }
    const link = document.createElement('a');
    link.href = event.currentTarget.href;
    link.target = '_blank';
    link.click();
    event.preventDefault();
}

export const PasswordCheck = (param) => {

    let paswd = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    // 1 capital, 1small case
    // 1 special character
    // 1 number
    // Length check for 8 to 15

    if (param.match(paswd)) {
        return true;
    }
    else {
        return false;
    }
}

export const passRules = () => {
    return (
        <>
            <strong>'Passwordshouldcontain'</strong><ul><li>'minimum8characters_maximum50characters'</li><li>'atleastonelowercase'</li><li>'atleastoneuppercase'</li><li>'atleastonenumber'</li><li>'atleastonespecialcharacter'</li><li>'notbesameasyouremail'</li></ul>
        </>
    )
}

export const scrollToTop = () => {
    const body = document.querySelector('#root');
    body.scrollIntoView({
        behavior: 'smooth'
    }, 500);
}

export const RefreshToken = (param) => {
    const API_URL = process.env.REACT_APP_API_URL;
    let refresh_token_time = process.env.REACT_APP_REFRESH_TOKENTIME;
    refresh_token_time = refresh_token_time * 60;
    let t = 0;
    function renderr() {

        t = t + 1;
        if (t > (param - refresh_token_time)) {

            t = 0;

            var formData = new FormData();
            formData.append("refresh_token", localStorage.getItem("refresh_token"));
            formData.append("api_key", process.env.REACT_APP_API_KEY);
            formData.append("action", "refresh");

            fetch(API_URL + '/v1/user/refresh', { method: 'POST', body: formData })
                .then(response => response.json())
                .then(jsondata => {
                    localStorage.setItem("expires_in", jsondata.expires_in);
                    localStorage.setItem("token", jsondata.access_token);
                    localStorage.setItem("refresh_token", jsondata.refresh_token);
                }).catch((err) => {
                    console.log("Login error: " + err);
                    setTimeout(function () {
                        console.log("error");
                    }, 2000);
                });
        }
    }
    setInterval(function () {
        renderr();
    }, 1000);
}

export const StatusInGrid = (param) => {
    const API_ACTIVE = process.env.REACT_APP_ACTIVE;
    const API_INACTIVE = process.env.REACT_APP_INACTIVE;
    if (Number(param.value) === 0) {
        return '<span class="badge rounded-pill badge-light-warning">' + API_INACTIVE + '</span>';
    } else {
        return '<span class="badge rounded-pill badge-light-success">' + API_ACTIVE + '</span>';
    }
}

export const naviLinks = (pathname) => {
    let naviLinks = [];
    const moduleArr = [];

    const modules = Object.values(JSON.parse(localStorage.getItem("user_profile")).Modules);
    modules?.map((element) => {
        element?.map((value) => {
            moduleArr.push(value)
        })
    })

    const permArr = Object.values(moduleArr).filter(user => user.module_path === (pathname.indexOf(user.module_path) !== -1 ? user.module_path : ""));
    if (permArr.length === 0) {
        return '';
    }

    naviLinks['label'] = '';
    naviLinks['title'] = '';
    naviLinks['edit_perm'] = permArr[0]['edit_perm'] || 0;
    naviLinks['delete_perm'] = permArr[0]['delete_perm'] || 0;
    naviLinks['add_perm'] = permArr[0]['add_perm'] || 0;

    return naviLinks;

}

export const getOwnerInfo = (createdAt, updatedAt, createdBy, updatedBy) => {
    const createdDate = new Date(createdAt);
    const updatedDate = new Date(updatedAt);

    const formattedCrDate = `${createdDate.getDate().toString().padStart(2, '0')}/${(createdDate.getMonth() + 1).toString().padStart(2, '0')}/${createdDate.getFullYear()} at ${createdDate.getHours().toString().padStart(2, '0')}:${createdDate.getMinutes().toString().padStart(2, '0')}:${createdDate.getSeconds().toString().padStart(2, '0')}`;

    const formattedUpDate = `${updatedDate.getDate().toString().padStart(2, '0')}/${(updatedDate.getMonth() + 1).toString().padStart(2, '0')}/${updatedDate.getFullYear()} at ${updatedDate.getHours().toString().padStart(2, '0')}:${updatedDate.getMinutes().toString().padStart(2, '0')}:${updatedDate.getSeconds().toString().padStart(2, '0')}`;

    const ownerInfo = {
        'createdBy': createdBy,
        'createdAt': formattedCrDate,
        'updatedBy': updatedBy,
        'updatedAt': formattedUpDate
    };

    return ownerInfo;
};

export const handleLogout = (dispatchAuth) => {

    dispatchAuth({
        type: 'SET_AUTH', setvalue: {
            'isLoggedin': false,
            'user-name': '',
            'user-email': '',
            'expires_in': '',
            'access_token': '',
            'refresh_token': '',
        }
    });

    // Extract the values of rememberMe, and rememberedUsername
    const rememberMe = localStorage.getItem("rememberMe");
    const rememberedUsername = localStorage.getItem("rememberedUsername");

    // Clear the entire localStorage
    localStorage.clear();

    // If remember me is checked, store the check and username in localStorage
    if (rememberMe === "true") {
        localStorage.setItem("rememberMe", rememberMe);
        localStorage.setItem("rememberedUsername", rememberedUsername);
    }

}