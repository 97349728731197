import React from "react";
import PartsMediaForm from "./PartsMediaForm";
import { Paths } from "../../routes/route";
const Add = () => {

    const title = Paths.partsmedia.c1_name;

    return (
        <>
            <PartsMediaForm addform={1} title={title} weightLabel='' weight={0} pdfFieldName='' pdfIndex={0} pdfPartName='' />
        </>
    );
}

export default Add;