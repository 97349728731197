export const layoutReducer = (state, action) => {
	switch (action.type) {
		case 'SET_LAYOUT_MODE':
			state[0].mode_selector = !action.setvalue;
			return [...state]
		case 'SET_UPLOADED_FILES':
			state[0].uploaded_files = action.setvalue;
			return [...state]

		default:
			return state
	}
}