import React from 'react';
import { Link } from 'react-router-dom';

const AddButton = ({ button_name, button_path, button_title, btn_class = '' }) => {
    return (
        <div className='d-flex gap-3'>
            {button_name && (
                <Link to={button_path} rel="noopener noreferrer" title={button_title} className={`list_button ${btn_class}`}>
                    {button_name}
                </Link>
            )}
        </div>
    );
};

export default AddButton;