import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Col, Row, Form, Alert, Spinner, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select'
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import LayoutWrapper from "../../components/UI/LayoutWrapper";
import DashboardCard from "../../components/UI/DashboardCard";
import ModuleEditScreenOwnerInfo from "../../components/ModuleEditScreenOwnerInfo";
import { Paths } from "../../routes/route";
import DataContext from "../../context/DataContext";
import { useForm } from "react-hook-form";
import Uploader from "../../moduleBuilder/components/Uploader";
import { getOwnerInfo } from "../../framework/utilities.js/utilities";
import { handleSave } from "../../helpers/API/Save";
import { getData } from "../../helpers/API/Misc";

const CategoryForm = (props) => {

	const { cat_id } = useParams();

	const { auth } = useContext(DataContext);
	let userId = auth[0].user_profile.GID;
	const { editform, editData, title } = props;

	const { handleSubmit, register, setValue, formState: { errors } } = useForm();
	const [queryParameters] = useSearchParams();
	const [errMessage, setErrorMessage] = useState('');
	const [successMessage, setSuccessMessage] = useState('');
	const API_URL = process.env.REACT_APP_API_URL;
	const navigate = useNavigate();
	const storedValue = localStorage?.getItem('viz_id');

	let [isParent, setIsParent] = useState(true);
	const [selectPartOptions, setSelectPartOptions] = useState('');

	const [selectOptions, setSelectOptions] = useState('');
	const [catId, setCatId] = useState(0);
	const [parentName, setParentName] = useState("");

	const [partId, setPartId] = useState("");
	const [partName, setPartName] = useState("");
	const [uploderState, setUploderState] = useState([]);

	const [catStatus, setCatStatus] = useState('1');
	const [catImgUrl, setCatImgUrl] = useState('');
	const [startSave, setStartSave] = useState(0);
	const [showSave, setShowSave] = useState(false);

	const [createdAt, setCreatedAt] = useState("");
	const [updatedAt, setUpdatedAt] = useState("");
	const [createdBy, setCreatedBy] = useState("");
	const [updatedBy, setUpdatedBy] = useState("");

	// For file uploader
	const [fileUploadProgress, setFileUploadProgress] = useState(false);

	useEffect(() => {

		if (editData) {
			setValue("cat_name", editData.name);
			setCatId(editData.parent_id);
			setParentName(editData.parent_name);
			setPartId(editData.part_id);
			setPartName(editData.part_name);
			setCatStatus(editData.active);
			setCatImgUrl(editData.url);
			setCreatedAt(editData.created_at);
			setUpdatedAt(editData.updated_at);
			setCreatedBy(editData.created_by);
			setUpdatedBy(editData.updated_by);

			if (editData.parent_id) {
				setIsParent(false);
			}
			else {
				setIsParent(true);
			}
		}

		if (props.addform) {
			if (queryParameters.get("type") === '1') {
				setIsParent(true);
			}
			else {
				setIsParent(false);
			}
		}
	}, [props]);

	const ownerInfo = getOwnerInfo(createdAt, updatedAt, createdBy, updatedBy);

	useEffect(() => {

		// Parent Category dropdown
		getData(API_URL + 'v1/category/catDropDownList?visualizer_id=' + storedValue, 'POST', '', 2, setSelectOptions);

	}, []);

	useEffect(() => {

		// Default Part dropdown
		getData(API_URL + 'v1/parts/partsDropDownList?id=' + catId + '&visualizer_id=' + storedValue, 'POST', '', 2, setSelectPartOptions);

	}, [catId]);

	const onSubmit = (data) => {

		setShowSave(true);

		setErrorMessage("");
		setSuccessMessage("");
		setFileUploadProgress(true);

		var formData = new FormData();

		for (let i = 0; i < uploderState.length; i++) {
			formData.append("FileData" + [i], uploderState[i]);
		}

		formData.append("category_name", data.cat_name);
		formData.append("parent_id", catId);
		formData.append("active", catStatus);
		formData.append("visualizer_id", storedValue);

		if (!editform) {
			formData.append("created_by", userId);
		} else {
			formData.append("updated_by", userId);
		}

		if (cat_id !== undefined) {
			formData.append("GID", cat_id);
			formData.append("default_part", partId);
		}

		handleSave(API_URL + '/v1/category/save', setSuccessMessage, Paths.partscategory.path, setErrorMessage, setShowSave, formData, navigate);
	}

	const handleSelectChange = (option) => {
		setCatId(option.value);
		setParentName(option.label);
	}

	return (
		<>
			<LayoutWrapper
				form={true}
				label={Paths.partscategory.name}
				linkto={Paths.partscategory.path}
				title={title}
			>
				{successMessage && !errMessage && <Alert variant="success">{successMessage}</Alert>}
				<DashboardCard border="light" className='pt-4'>
					<h5 className="w-100 px-4 py-4 m-0">{(queryParameters.get("type") === '1') ? Paths.partscategory.c1_name : Paths.partscategory.c2_name}</h5>
					<Form onSubmit={handleSubmit(onSubmit)} className="w-100 px-4 pb-4">
						<Row>
							<Col xl={12} lg={12} md={12} className="mb-3">
								<Form.Group id="cat_name">
									<Form.Label>Category name <span style={{ color: 'red', marginLeft: '4px' }}>*</span></Form.Label>
									<Form.Control
										{...register("cat_name", {
											required: 'Category name is required',
										})}
									/>
								</Form.Group>
								<div style={{ color: 'red' }}>{errors.cat_name?.message}</div>
							</Col>
							{isParent ? '' :
								<Col xl={12} lg={12} md={12} className="mb-3">
									<Form.Label>Parent category
										{props.editform &&
											<>
												<br />
												<span class="text-danger" style={{ 'font-size': '12px' }}>! Please remember to change the Main UI to match the below change </span>
											</>}
									</Form.Label>
									<Form.Group id="category_id">
										<Select
											value={{ value: catId, label: parentName }}
											onChange={handleSelectChange}
											options={selectOptions}
											isSearchable
										/>
									</Form.Group>
								</Col>
							}
							{(queryParameters.get("type") || (editform === 1 && isParent)) ? "" :
								<Col xl={12} lg={12} md={12} className="mb-3">
									<Form.Label>Default part</Form.Label>
									<Form.Group id="part_id">
										<Select name="part_id" value={{ value: partId, label: partName }}
											options={selectPartOptions} onChange={event => {
												setPartId(event.value);
												setPartName(event.label);
											}} />
									</Form.Group>
								</Col>
							}
							{isParent ?
								<>
									<Col>Icon image: {catImgUrl && <a style={{ wordBreak: 'break-all' }} href={catImgUrl} target="_blank" rel="noreferrer">{catImgUrl}</a>}</Col>
									<Col xl={12} lg={4} md={12} className="mb-3">
										<Uploader uploderState={uploderState} setUploderState={setUploderState} maxSize={1000000} maxSizeLabel='with size less than 10 MB' />
										{((fileUploadProgress === true) && !errMessage) &&
											<>
												<Button variant="primary" disabled>
													<Spinner
														as="span"
														animation="grow"
														size="sm"
														role="status"
														aria-hidden="true"
													/>
													Uploading. Please wait...
												</Button>
											</>
										}
									</Col>
								</> : ''
							}
							<Col md={6} className="mb-3">
								<Form.Group id="active">
									<Form.Label>Status</Form.Label>
									<div className="d-block">
										<div className="d-inline-block me-4">
											<div className="d-inline-block pe-2">
												<input type="radio" id="active1" name="active" label="Ja" checked={Number(catStatus) === 1 ? true : false} value="1" onChange={(e) => {
													setCatStatus(e.target.value);
												}} style={{ cursor: 'pointer' }}
												/>
											</div>
											<div className="d-inline-block">
												<label htmlFor="active1" style={{ cursor: 'pointer' }}>Active</label>
											</div>
										</div>
										<div className="d-inline-block">
											<div className="d-inline-block pe-2">
												<input type="radio" id="active0" name="active" label="Nein" checked={Number(catStatus) === 0 ? true : false} value="0" onChange={(e) => {
													setCatStatus(e.target.value);
												}} style={{ cursor: 'pointer' }}
												/>
											</div>
											<div className="d-inline-block">
												<label htmlFor="active0" style={{ cursor: 'pointer' }}>Inactive</label>
											</div>
										</div>
									</div>
								</Form.Group>
							</Col>
						</Row>
						{
							<>
								{
									<>
										{!showSave && (
											<>
												{startSave ? (
													<Button variant="primary" disabled>
														<Spinner
															as="span"
															animation="grow"
															size="sm"
															role="status"
															aria-hidden="true"
														/>
														Saving. Please wait...
													</Button>
												) : (
													<div className="cancel_block">
														<div style={{ display: "flex" }} className="cancel_button">
															<Button className="list_button m-0" type="submit">
																<FontAwesomeIcon icon={faSave} className="me-2" />Save
															</Button>
															<Button
																className="list_button m-0"
																onClick={(e) => {
																	e.preventDefault();
																	navigate(`${Paths.partscategory.path}`);
																}}
															>
																Cancel
															</Button>
														</div>
													</div>
												)}
											</>
										)}
									</>
								}
							</>
						}
						{editform && <ModuleEditScreenOwnerInfo ownerInfo={ownerInfo} />}
					</Form>
				</DashboardCard>
			</LayoutWrapper>
		</>
	);
}

export default CategoryForm;