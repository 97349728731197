import { Row, Col } from '@themesberg/react-bootstrap';


export default function ActivityLog(props = {}) {

    return (
        <Row>
            <Col className='pb-3'>
                <div>{props.ownerInfo.createdBy && `Created by ${props.ownerInfo.createdBy} on ${props.ownerInfo.createdAt}`}</div>
                <div>{props.ownerInfo.updatedBy && `Updated by ${props.ownerInfo.updatedBy} on ${props.ownerInfo.updatedAt}`}</div>
            </Col>
        </Row>
    );
};