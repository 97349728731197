import { Card, Alert } from 'react-bootstrap';
import ConfirmDeleteModal from '../../components/ConfirmDeleteModal';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import React, { useContext, useEffect, useState } from "react";
import delete_icon from "../../assets/images/delete.svg";
import LayoutWrapper from '../../components/UI/LayoutWrapper';
import UnorderedGrid from '../../components/UnorderedGrid';
import { Paths } from '../../routes/route';
import DataContext from '../../context/DataContext';
import Loader from '../../components/UI/Loader';
import { deleteBtn, handleOnDeleteSubmitted } from '../../helpers/API/Delete';
import { handleSave } from '../../helpers/API/Save';

const List = () => {
	const API_URL = process.env.REACT_APP_API_URL;
	const [gridApi, setGridApi] = useState(null);
	const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row

	let [modalShow, setModalShow] = useState(false);
	let [delRecTitle, setDelRecTitle] = useState('');
	let [delRecId, setDelRecId] = useState(0);

	const [errMessage, setErrorMessage] = useState('');
	const [successMessage, setSuccessMessage] = useState('');
	const storedValue = localStorage?.getItem('viz_id');

	const { dispatchInfo, info } = useContext(DataContext);

	const [columnDefs, setColumnDefs] = useState([]);
	const [translationAdded, setTranslationAdded] = useState(false);
	const [translationDeleted, setTranslationDeleted] = useState(false);
	const [displayLoader, setDisplayLoader] = useState(true);

	const onGridReady = (params) => {
		setGridApi(params.api);
	};

	const onDelete = () => {
		handleOnDeleteSubmitted('/v1/translation/deleteTranslation', setSuccessMessage, setTranslationDeleted, setModalShow, setDelRecId, setDelRecTitle, translationDeleted, delRecId, gridApi, 1, '', setDisplayLoader);
	}

	// Example load data from sever
	useEffect(() => {
		// Get user_profile data from localStorage
		const userProfileString = localStorage.getItem("user_profile");
		const userProfile = JSON.parse(userProfileString);

		// Get Translation module object and store the permissions array from it
		const translationModule = userProfile.Modules.Regionalisation.find(module => module.name === "Translations");

		let permissionsArray = []
		if (userProfile.Utype !== 1) {
			permissionsArray = translationModule.permissions.map(id => parseInt(id, 10));
		}
		if (displayLoader) {
			dispatchInfo({ type: 'SET_LOADER', setvalue: true });
		} else {
			setDisplayLoader(true);
		}
		fetch(API_URL + 'v1/translation/listTranslation?visualizer_id=' + storedValue, {
			method: 'GET',
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem("access_token"),
			},
		})
			.then(response => response.json())
			.then(data => {
				const translationsArray = data.translations.reverse();
				setRowData(translationsArray);
				const languageArray = data.languages;

				const dynamicColumnDefs = languageArray.map(language => ({
					// Set language as the header name for each column
					headerName: language.lang_name,
					// Get the translation for the corresponding language
					valueGetter: (params) => {
						const translations = params.data.translations; // Fetch the translations directly from the row data
						const translationObj = translations.find(item => item.lang_id === language.id);
						return translationObj ? translationObj.translation : '';
					},
					// Set the translation when edited
					valueSetter: (params) => {
						const translations = params.data.translations;;
						const translationObjIndex = translations.findIndex(item => item.lang_name === language.lang_name);
						// Update the existing translation if it exists, meaning cell is not empty
						if (translationObjIndex !== -1) {
							translations[translationObjIndex].translation = params.newValue;
						} else {
							// Create a new translation if it doesn't exist, cell is empty
							translations.push({
								lang_name: language.lang_name,
								translation: params.newValue,
							});
						}
						return true;
					},
					suppressMovable: true,
					editable: true,
					suppressAutoSize: true,
					hide: (userProfile.Utype === 1 || permissionsArray.includes(language.id)) ? false : true,
					width: 150,
					cellEditor: 'agLargeTextCellEditor',
					cellEditorPopup: true,
					filter: true,
					cellEditorParams: {
						maxLength: 1000,
						rows: 10,
						cols: 50
					},
					cellRendererFramework: (params) => (
						<div className="text-column d-flex justify-content-between">
							<span className="ellipsis" title={params.value}>{params.value}</span>
						</div>
					),
				}));

				const updatedColumnDefs = [
					{
						headerName: 'Translation code',
						field: 'text',
						editable: true,
						width: 300,
						pinned: 'left',
						suppressAutoSize: true,
						suppressMovable: true,
						autoHeight: true,
						filter: true,
						cellEditor: 'agLargeTextCellEditor',
						cellEditorPopup: true,
						cellEditorParams: {
							maxLength: 1000,
							rows: 10,
							cols: 50
						},
						cellRendererFramework: (params) => (
							<div className="text-column d-flex justify-content-between">
								<span className="delete-icon delete-icon-color link" onClick={() => deleteBtn(params, 'text', setDelRecId, setDelRecTitle, setModalShow, 'text_id', 3)} title="Delete">
									<img src={delete_icon} alt="delete" className="" style={{ width: '24px', height: '24px' }} />
								</span>
								<span className="ellipsis" title={params.value}>{params.value}</span>
							</div>
						),
					},
					{
						...dynamicColumnDefs[0], // Get English (UK) column
						pinned: 'left',
						suppressAutoSize: true,
					},
					...dynamicColumnDefs.slice(1), // Add the remaining columns
				];
				setColumnDefs(updatedColumnDefs);
			})
			.catch(error => {
				console.error('Error:', error);
			});
	}, [translationAdded, translationDeleted]);

	const handleAddTranslation = () => {
		setDisplayLoader(false);

		let url = API_URL + 'v1/translation/addTranslation?visualizer_id=' + storedValue;

		handleSave(url, setSuccessMessage, '', setErrorMessage, ...Array(5).fill(''), 5, ...Array(5).fill(''), setTranslationAdded, translationAdded)

	};

	const handleCellEditingStopped = (event) => {
		setSuccessMessage('');
		const { colDef, data, newValue, oldValue } = event;
		const { headerName } = colDef;
		const { text, text_id } = data;

		if (oldValue !== newValue && newValue !== undefined) {
			let formData = new FormData();
			formData.append("text", text);
			formData.append("text_id", text_id);
			formData.append("newValue", newValue);
			formData.append("oldValue", oldValue);
			formData.append("headerName", headerName);

			if (headerName === 'Translation code') {
				formData.append("type", 1); // Translation Code will be edited
			} else {
				formData.append("type", 2); // Translation will be edited
			}

			let url = API_URL + 'v1/translation/updateText?visualizer_id=' + storedValue;

			handleSave(url, setSuccessMessage, '', setErrorMessage, '', formData, '', '', '', 5);
		}
	};


	const [gridLoaded, setGridLoaded] = useState(false);

	const handleGridLoaded = () => {
		setGridLoaded(true);
	};

	useEffect(() => {
		if (gridLoaded) {
			dispatchInfo({ type: 'SET_LOADER', setvalue: false });
		}
	}, [gridLoaded]);

	return (
		<>
			{info[0].loader && <Loader />}
			<LayoutWrapper
				modal={
					<ConfirmDeleteModal
						modalShow={modalShow}
						handleModelClose={() => { setModalShow(false); }}
						delRecTitle={delRecTitle}
						handleOnDeleteSubmitted={onDelete}
					/>
				}
				heading={Paths.translations.name}
				breadcrumb_text='This is the master database for all text used within the visualiser. To search for a word,
				hover over the translation code and click the filter button on the column title and search for
				keywords. To amend a translation, double click the cell – to exit, click either side of the text
				box.'
			>
				<button onClick={handleAddTranslation} title={Paths.translations.c1_name} className='list_button'>{Paths.translations.c1_name}</button>
				<Card.Body className='translation-aggrid'>
					{successMessage && !errMessage && <Alert variant="success">{successMessage}</Alert>}
					{rowData !== undefined && (
						<UnorderedGrid
							gridData={rowData}
							columnDefs={columnDefs}
							onGridReady={onGridReady}
							handleCellEditingStopped={handleCellEditingStopped}
							onFirstDataRendered={handleGridLoaded}
						/>
					)}
				</Card.Body>
			</LayoutWrapper>
		</>
	);

}

export default List;