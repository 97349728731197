import { Alert, Card } from 'react-bootstrap';
import ConfirmDeleteModal from '../../components/ConfirmDeleteModal';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import React, { useEffect, useState } from "react";
import edit from "../../assets/images/edit.svg";
import delete_icon from "../../assets/images/delete.svg";
import { Link, useLocation, useParams } from "react-router-dom";
import { Paths } from '../../routes/route';
import { naviLinks } from '../../framework/utilities.js/utilities';
import Grid from '../../components/Grid';
import { deleteBtn, handleOnDeleteSubmitted } from '../../helpers/API/Delete';
import { listData } from '../../helpers/API/List';

const List = (props) => {
    const API_URL = process.env.REACT_APP_API_URL;
    const [gridApi, setGridApi] = useState(null);
    const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row

    let [modalShow, setModalShow] = useState(false);
    let [delRecTitle, setDelRecTitle] = useState('');
    let [delRecId, setDelRecId] = useState(0);
    const { cat_id } = useParams();

    // Code to fetch title based on URL
    const { pathname } = useLocation();
    const naviArr = naviLinks(pathname);
    const createPerm = naviArr['edit_perm'];
    const deletePerm = naviArr['delete_perm'];
    const readPerm = naviArr['add_perm'];

    const [columnDefs, setColumnDefs] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        localStorage.setItem('parentCatId', props.parentCatId);
    }, []);

    useEffect(() => {
        localStorage.setItem('parentCatId', props.parentCatId);

        // Each Column Definition results in one Column.
        setColumnDefs([
            { headerName: "Sub node name", field: 'node_name', filter: true, rowDrag: true, sortable: true, flex: 1 },
            { headerName: "Type", field: 'subNode_type', filter: true, sortable: true, flex: 1 },
            { headerName: "Category", field: 'parent_cat', filter: true, sortable: true, flex: 1 },
            {
                headerName: "", field: "id", minWidth: 50, maxWidth: 50, headerClass: "ag-theme-custom-text-center", cellClass: "pm-grid-number-cell ag-theme-custom-content-center",
                cellRenderer: (params) =>
                    <div className="ag-theme-content-text-center">
                        {createPerm === 1 ? <Link to={`${Paths.nodesconfiguration.c4_path.split(":").slice(0, -1)[0]}` + params.value + `?catId=` + localStorage.getItem("parentCatId") + `&dId=` + cat_id}><span title="Edit" className="grid-action-btns text-success link"><img src={edit} alt="edit" className="me-2" style={{ width: '24px', height: '24px' }} /></span></Link> : null}

                    </div>
            },
            {
                headerName: "", field: "id", minWidth: 50, maxWidth: 50, headerClass: "ag-theme-custom-text-center", cellClass: "pm-grid-number-cell ag-theme-custom-content-center",
                cellRenderer: (params) => <div className="ag-theme-content-text-center">

                    {deletePerm === 1 ? <span onClick={() => deleteBtn(params, 'node_name', setDelRecId, setDelRecTitle, setModalShow)} title="Delete" className="grid-action-btns delete-icon-color link"><img src={delete_icon} alt="delete" className="me-2" style={{ width: '24px', height: '24px' }} /></span> : null}
                </div>
            }

        ]);
    }, [props])

    const onDelete = () => {
        handleOnDeleteSubmitted('/v1/dependency/deleteSubNode', setSuccessMessage, '', setModalShow, setDelRecId, setDelRecTitle, '', delRecId, gridApi, 2);
    }

    // Example load data from sever
    useEffect(() => {

        listData(API_URL + '/v1/dependency/subnodeList?dependency_id=' + cat_id, setRowData);

    }, []);

    return (
        <>
            {modalShow && <ConfirmDeleteModal modalShow={modalShow} handleModelClose={() => { setModalShow(false); }} delRecTitle={delRecTitle} handleOnDeleteSubmitted={onDelete} message='Please also make related parts inactive' />}
            <Card.Body style={{ margin: '30px 0 0' }}>
                {successMessage && <Alert variant="success">{successMessage}</Alert>}
                {(rowData !== undefined) && <Grid gridData={rowData} columnDefs={columnDefs} url={'v1/dependency/updateOrder2'} />}
                <div style={{ margin: '30px 0 0', display: 'flex', justifyContent: 'right' }}>
                    <Link to={(`${Paths.nodesconfiguration.c3_path}` + `?catId=` + props.parentCatId + `&dId=` + cat_id)} rel="noopener noreferrer" title={Paths.nodesconfiguration.c3_name} className="list_button">{Paths.nodesconfiguration.c3_name}</Link>
                </div>
            </Card.Body>
        </>
    );
}

export default List;