import { Card, Alert } from 'react-bootstrap';
import ConfirmDeleteModal from '../../components/ConfirmDeleteModal';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import React, { useEffect, useState } from "react";
import edit from "../../assets/images/edit.svg";
import delete_icon from "../../assets/images/delete.svg";
import { useLocation, useNavigate } from "react-router-dom";
import LayoutWrapper from '../../components/UI/LayoutWrapper';
import { Paths } from '../../routes/route';
import { naviLinks } from '../../framework/utilities.js/utilities';
import Grid from '../../components/Grid';
import AddButton from '../../components/AddButton';
import { deleteBtn, handleOnDeleteSubmitted } from '../../helpers/API/Delete';
import { listData } from '../../helpers/API/List';

const List = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const [gridApi, setGridApi] = useState(null);
    const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row
    const [successMessage, setSuccessMessage] = useState('');

    let [modalShow, setModalShow] = useState(false);
    let [delRecTitle, setDelRecTitle] = useState('');
    let [delRecId, setDelRecId] = useState(0);

    // Code to fetch title based on URL
    const { pathname } = useLocation();
    const naviArr = naviLinks(pathname);
    const createPerm = naviArr['edit_perm'];
    const deletePerm = naviArr['delete_perm'];
    const readPerm = naviArr['add_perm'];

    const storedValue = localStorage?.getItem('viz_id');

    // Each Column Definition results in one Column.
    const [columnDefs] = useState([
        { headerName: "Node name", field: 'dependency_name', filter: true, rowDrag: true, sortable: true, flex: 1 },
        { headerName: "Link category", field: 'parent_dependency', filter: true, sortable: true, flex: 1 },
        { headerName: "Status", field: 'active', filter: true, sortable: true, flex: 1 },
        {
            headerName: "", field: "id", minWidth: 50, maxWidth: 50, headerClass: "ag-theme-custom-text-center", cellClass: "pm-grid-number-cell ag-theme-custom-content-center",
            cellRenderer: (params) =>
                <div className="ag-theme-content-text-center">
                    {createPerm === 1 ? <span onClick={() => editBtn(params)} title="Edit" className="grid-action-btns text-success link"><img src={edit} alt="edit" className="me-2" style={{ width: '24px', height: '24px' }} /></span> : null}

                </div>
        },
        {
            headerName: "", field: "id", minWidth: 50, maxWidth: 50, headerClass: "ag-theme-custom-text-center", cellClass: "pm-grid-number-cell ag-theme-custom-content-center",
            cellRenderer: (params) => <div className="ag-theme-content-text-center">

                {deletePerm === 1 ? <span onClick={() => deleteBtn(params, 'dependency_name', setDelRecId, setDelRecTitle, setModalShow)} title="Delete" className="grid-action-btns delete-icon-color link"><img src={delete_icon} alt="delete" className="me-2" style={{ width: '24px', height: '24px' }} /></span> : null}
            </div>
        }

    ]);

    const onDelete = () => {
        handleOnDeleteSubmitted('/v1/dependency/deleteDependency', setSuccessMessage, '', setModalShow, setDelRecId, setDelRecTitle, '', delRecId, gridApi, 2);
    }

    const editBtn = (params) => {
        navigate(`${Paths.nodesconfiguration.c2_path.split(":").slice(0, -1)[0]}` + params.value);
    }

    // Example load data from sever
    useEffect(() => {

        listData(API_URL + '/v1/dependency/dependencyList?visualizer_id=' + storedValue, setRowData);

    }, []);

    return (
        <>
            <LayoutWrapper
                modal={<ConfirmDeleteModal modalShow={modalShow} handleModelClose={() => { setModalShow(false); }} delRecTitle={delRecTitle} handleOnDeleteSubmitted={onDelete} message='Please also make related parts inactive' />}
                heading={Paths.nodesconfiguration.name}
                breadcrumb_text='This is where you control the visual layout and buttons of the main menu selection UI.'
            >
                <Card.Body>
                    <AddButton
                        className="custom-button"
                        button_name={readPerm === 1 ? Paths.nodesconfiguration.c1_name : null}
                        button_path={Paths.nodesconfiguration.c1_path}
                        button_title={Paths.nodesconfiguration.c1_name}
                        btn_class="list_button"
                    />
                    {successMessage && <Alert variant="success">{successMessage}</Alert>}
                    {(rowData !== undefined) && <Grid gridData={rowData} columnDefs={columnDefs} url={'v1/dependency/updateOrder'} />}
                </Card.Body>
            </LayoutWrapper>
        </>
    );
}

export default List;