import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ConfiguratorNodeForm from "../NodeConfiguration/Form"
import { Paths } from "../../routes/route";
import { fetchEditData } from "../../helpers/API/Edit";

const Edit = () => {

    const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row
    const { cat_id } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;
    const title = Paths.nodesconfiguration.c4_name;

    useEffect(() => {

        fetchEditData(API_URL + 'v1/dependency/editSubDependency?id=' + cat_id, setRowData);

    }, [cat_id]);

    return (
        <>
            <ConfiguratorNodeForm editpage={1} data={rowData} lg={11} title={title} type={2} />
        </>
    );
}

export default Edit;