import React from "react";
import HotspotForm from "./Form";
import { Paths } from "../../routes/route";
const Add = () => {

    const title = Paths.hotspot.c1_name;


    return (
        <>
            <HotspotForm addform={1} title={title} />
        </>
    );
}

export default Add;