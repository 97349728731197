import React, { useState, useEffect, useContext } from "react";
import { Col, Row, Form, Alert, Button } from 'react-bootstrap';
import Select from 'react-select'
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import LayoutWrapper from '../../components/UI/LayoutWrapper';
import DashboardCard from '../../components/UI/DashboardCard';
import ModuleEditScreenOwnerInfo from "../../components/ModuleEditScreenOwnerInfo";
import { getOwnerInfo } from "../../framework/utilities.js/utilities";
import { Paths } from "../../routes/route";
import { useForm } from "react-hook-form";
import DataContext from "../../context/DataContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import NodeList from "../NodeConfiguration/NodeList";
import Buttons from "../NodeConfiguration/Buttons"
import { handleSave } from "../../helpers/API/Save";
import { getData } from "../../helpers/API/Misc";

const ConfiguratorNodeForm = (props) => {
    const { handleSubmit, register, setValue, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const { auth } = useContext(DataContext);
    const [queryParameters] = useSearchParams();
    const userId = auth[0].user_profile.GID;
    const [subCatName, setSubCatName] = useState("");
    const [subCatId, setSubCatId] = useState(0);
    const [subNodeType, setSubNodeType] = useState("");
    const [active, setStatus] = useState(1);
    const [parentCatId, setParentCatId] = useState(0);
    const [parentId, setParentId] = useState(0);
    const [parentCatName, setParentCatName] = useState("");
    const [selectOptions, setSelectOptions] = useState('');
    const [errMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [startSave, setStartSave] = useState(false);
    const storedValue = localStorage?.getItem('viz_id');
    const [createdAt, setCreatedAt] = useState("");
    const [updatedAt, setUpdatedAt] = useState("");
    const [createdBy, setCreatedBy] = useState("");
    const [updatedBy, setUpdatedBy] = useState("");
    const editpage = props.editpage;
    const formType = props.type;
    const editData = props.data;
    const API_URL = process.env.REACT_APP_API_URL;
    let catList = '';

    if (formType === 1) {
        catList = API_URL + 'v1/category/catDropDownList?visualizer_id=' + storedValue;
    }
    else {
        catList = API_URL + '/v1/subCategories?id=' + queryParameters.get("catId") + '&visualizer_id=' + storedValue;
    }

    useEffect(() => {

        // Category / Sub Category Dropdown
        getData(catList, 'POST', '', 2, setSelectOptions);

    }, []);

    useEffect(() => {
        if (editData) {
            setValue("node_name", editData.name);
            setStatus(editData.active);
            setParentCatId(editData.parent_id);
            setParentId(editData.parent_id);
            setParentCatName(editData.parent_name);

            setValue("sub_node", editData.subNode_name);
            setSubCatName(editData.category_name);
            setSubCatId(editData.subCategory);
            setSubNodeType(editData.subNode_type);
            setCreatedAt(editData.created_at);
            setUpdatedAt(editData.updated_at);
            setCreatedBy(editData.created_by);
            setUpdatedBy(editData.updated_by);
        }
    }, [editData]);

    const ownerInfo = getOwnerInfo(createdAt, updatedAt, createdBy, updatedBy);

    const onSubmit = (data) => {

        setStartSave(true);

        setErrorMessage("");
        setSuccessMessage("");

        let formData = new FormData();
        let saveAPI = '';
        if (editData) {
            formData.append("GID", editData.id);
        }

        if (formType === 1) {
            formData.append("dependency_name", data.node_name);
            formData.append("parent_dependency", parentCatId);
            formData.append("active", active);
            formData.append("visualizer_id", storedValue);

            saveAPI = API_URL + '/v1/dependency/saveMain';
        }

        if (formType === 2) {
            formData.append("subNode_name", data.sub_node);
            formData.append("subCategory", subCatId);
            formData.append("dependency_id", queryParameters.get("dId"));
            formData.append("subNode_type", subNodeType);
            saveAPI = API_URL + '/v1/dependency/saveSub';
        }

        formData.append("formType", formType);
        if (!editpage) {
            formData.append("created_by", userId);
        } else {
            formData.append("updated_by", userId);
        }

        handleSave(saveAPI, setSuccessMessage, Paths.nodesconfiguration.path, setErrorMessage, setStartSave, formData, navigate);
    }

    return (
        <>
            <LayoutWrapper breadcrumb_title='Node config Module'
                form={true}
                label={Paths.nodesconfiguration.name}
                linkto={Paths.nodesconfiguration.path}
                title={props.title}>
                {successMessage && !errMessage && <Alert variant="success">{successMessage}</Alert>}
                <Row>
                    <Col lg='12' className=''>
                        <DashboardCard className='pt-4'>
                            <h5 className="w-100 px-4 py-4 m-0">{props.title}</h5>
                            <Form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" className="w-100 px-4 pb-4" >
                                {formType === 1 &&
                                    <>
                                        <Row>
                                            <Col xl={12} lg={12} md={12} className="mb-3 mt-2">
                                                <Form.Group id="node_name">
                                                    <Form.Label className="required ">Node name <span style={{ color: 'red', marginLeft: '4px' }}>*</span><br />
                                                        {<span class="text-danger" style={{ 'font-size': '12px' }}>! Please add this Node name in the tranlations section, otherwise the Node name will not work </span>}</Form.Label>
                                                    <Form.Control
                                                        {...register("node_name", {
                                                            required: 'Node Name is required'
                                                        })}
                                                    />
                                                </Form.Group>
                                                {errors.node_name?.message && (
                                                    <small style={{ color: 'red' }}>{errors.node_name.message}</small>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={12} lg={12} md={12} className="mb-3">
                                                <Form.Label>Link category</Form.Label>
                                                <Form.Group id="link_category_id">
                                                    <Select name="link_category_id" value={{ value: parentCatId, label: parentCatName }}
                                                        options={selectOptions} onChange={event => {
                                                            setParentCatName(event.label);
                                                            setParentCatId(event.value);
                                                        }} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6} className="mb-3">
                                                <Form.Group id="active">
                                                    <Form.Label>Status</Form.Label>
                                                    <div className="d-block">
                                                        <div className="d-inline-block me-4">
                                                            <div className="d-inline-block pe-2">
                                                                <input type="radio" id="active1" name="active" label="Ja" checked={Number(active) === 1 ? true : false} value="1" onChange={(e) => {
                                                                    setStatus(e.target.value);
                                                                }} style={{ cursor: 'pointer' }}
                                                                />
                                                            </div>
                                                            <div className="d-inline-block">
                                                                <label htmlFor="active1" style={{ cursor: 'pointer' }}>Active</label>
                                                            </div>
                                                        </div>
                                                        <div className="d-inline-block">
                                                            <div className="d-inline-block pe-2">
                                                                <input type="radio" id="active0" name="active" label="Nein" checked={Number(active) === 0 ? true : false} value="0" onChange={(e) => {
                                                                    setStatus(e.target.value);
                                                                }} style={{ cursor: 'pointer' }}
                                                                />
                                                            </div>
                                                            <div className="d-inline-block">
                                                                <label htmlFor="active0" style={{ cursor: 'pointer' }}>Inactive</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </>
                                }
                                {formType === 2 &&
                                    <>
                                        <Row>
                                            <Col xl={12} lg={12} md={12} className="mb-3 mt-2">
                                                <Form.Group id="sub_node">
                                                    <Form.Label className="required ">Sub node name <span style={{ color: 'red', marginLeft: '4px' }}>*</span><br />
                                                        {<span class="text-danger" style={{ 'font-size': '12px' }}>! Please add this Sub Node name in the tranlations section, otherwise the Sub Node name will not work </span>}</Form.Label>
                                                    <Form.Control
                                                        {...register("sub_node", {
                                                            required: 'Sub Node Name is required'
                                                        })} />
                                                </Form.Group>
                                                {errors.sub_node?.message && (
                                                    <small style={{ color: 'red' }}>{errors.sub_node.message}</small>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={12} lg={12} md={12} className="mb-3">
                                                <Form.Label>Sub category</Form.Label>
                                                <Form.Group id="subCategory">
                                                    <Select name="subCategory" value={{ value: subCatId, label: subCatName }}
                                                        options={selectOptions} onChange={event => {
                                                            setSubCatId(event.value);
                                                            setSubCatName(event.label);
                                                        }} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={12} lg={12} md={12} className="mb-3">
                                                <Form.Group id="subNode_type">
                                                    <Form.Label>SUB NODE TYPE</Form.Label>
                                                    <Form.Select
                                                        className="w-100"
                                                        name="subNode_type"
                                                        type="text"
                                                        placeholder="sub node type"
                                                        onChange={event => {
                                                            setSubNodeType(event.target.value)
                                                        }}
                                                        value={subNodeType}
                                                    >
                                                        <option>Select Options</option>
                                                        <option>Required for part loading</option>
                                                        <option>Required for part properties</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </>
                                }
                                {!startSave && <Row>
                                    <Col xl={5} lg={12} md={12} className="cancel_block">
                                        <div style={{ display: "flex" }} className="cancel_button">
                                            <Button className="list_button m-0" type="submit" >
                                                Save</Button>
                                            <Button
                                                className="list_button m-0"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    navigate(`${Paths.nodesconfiguration.path}`);
                                                }}
                                            >
                                                Cancel</Button>
                                        </div>
                                    </Col>
                                </Row>
                                }
                                {formType === 1 && editpage && <NodeList parentCatId={parentId} />}

                                {formType === 2 && editpage && <Buttons subCatId={subCatId} />}


                                {editpage && <ModuleEditScreenOwnerInfo ownerInfo={ownerInfo} />}
                            </Form>
                        </DashboardCard>
                    </Col>
                </Row>
            </LayoutWrapper>
        </>
    );
}

export default ConfiguratorNodeForm;