export const listData = (url = '', setRowData) => {

    fetch(url, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem("access_token"),
        },
    })
        .then(result => result.json())
        .then(jsondata => {
            setRowData(jsondata.configurator);
        })
};