import { Card, Alert } from 'react-bootstrap';
import ConfirmDeleteModal from '../../components/ConfirmDeleteModal';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import React, { useEffect, useState } from "react";
import edit from "../../assets/images/edit.svg";
import delete_icon from "../../assets/images/delete.svg";
import { useLocation } from "react-router-dom";
import { Link } from 'react-router-dom';
import LayoutWrapper from '../../components/UI/LayoutWrapper';
import UnorderedGrid from '../../components/UnorderedGrid';
import { Paths } from '../../routes/route';
import { naviLinks } from '../../framework/utilities.js/utilities';
import AddButton from '../../components/AddButton';
import { deleteBtn, handleOnDeleteSubmitted } from '../../helpers/API/Delete';
import { listData } from '../../helpers/API/List';

const List = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const [gridApi, setGridApi] = useState(null);
    const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row
    const [hotspotDeleted, setHotspotDeleted] = useState(false);

    let [modalShow, setModalShow] = useState(false);
    let [delRecTitle, setDelRecTitle] = useState('');
    let [delRecId, setDelRecId] = useState(0);

    // Code to fetch title based on URL
    const { pathname } = useLocation();
    const naviArr = naviLinks(pathname);
    const createPerm = naviArr['edit_perm'];
    const deletePerm = naviArr['delete_perm'];
    const readPerm = naviArr['add_perm'];
    const [successMessage, setSuccessMessage] = useState('');

    const storedValue = localStorage?.getItem('viz_id');

    // Each Column Definition results in one Column.
    const [columnDefs] = useState([
        { headerName: "Hotspot name", field: 'hotspot_name', filter: true, sortable: true, resizable: true, flex: 1 },
        { headerName: "left/right Coordinate", field: 'position_x', filter: true, sortable: true, resizable: true, flex: 1 },
        { headerName: "up/down Coordinate", field: 'position_y', filter: true, sortable: true, resizable: true, flex: 1 },
        { headerName: "forward/backward Coordinate", field: 'position_z', filter: true, sortable: true, resizable: true, flex: 1 },
        { headerName: "Type", field: 'hotspot_type', filter: true, sortable: true, resizable: true, flex: 1 },
        {
            headerName: "", field: "id", minWidth: 50, maxWidth: 50, headerClass: "ag-theme-custom-text-center", cellClass: "pm-grid-number-cell ag-theme-custom-content-center",
            cellRenderer: (params) => <div className="ag-theme-content-text-center">
                {createPerm === 1 ? <Link onClick={handleOpenInNewTab} to={(`${Paths.hotspot.c2_path.split(":").slice(0, -1)[0]}` + params.value)} title="Edit" className="grid-action-btns text-success link"><img src={edit} alt="edit" className="me-2" style={{ width: '24px', height: '24px' }} /></Link> : null}
            </div>
        },
        {
            headerName: "", field: "id", minWidth: 50, maxWidth: 50, headerClass: "ag-theme-custom-text-center", cellClass: "pm-grid-number-cell ag-theme-custom-content-center",
            cellRenderer: (params) => <div className="ag-theme-content-text-center">

                {deletePerm === 1 ? <span onClick={() => deleteBtn(params, 'hotspot_name', setDelRecId, setDelRecTitle, setModalShow)} title="delete" className="grid-action-btns delete-icon-color link"><img src={delete_icon} alt="delete" className="me-2" style={{ width: '24px', height: '24px' }} /></span> : null}
            </div>
        }

    ]);

    const handleOpenInNewTab = (event) => {
        if (event.button === 0) {
            // Left mouse button clicked
            // Open link in the same tab
            return;
        }
        const link = document.createElement('a');
        link.href = event.currentTarget.href;
        link.target = '_blank';
        link.click();
    }

    const onDelete = () => {
        handleOnDeleteSubmitted('/v1/hotspot/delete', setSuccessMessage, setHotspotDeleted, setModalShow, setDelRecId, setDelRecTitle, hotspotDeleted, delRecId, gridApi);
    }

    useEffect(() => {

        listData(API_URL + '/v1/hotspot/list?visualizer_id=' + storedValue, setRowData);

    }, [hotspotDeleted]);

    return (
        <>
            <LayoutWrapper
                modal={<ConfirmDeleteModal modalShow={modalShow} handleModelClose={() => { setModalShow(false); }} delRecTitle={delRecTitle} handleOnDeleteSubmitted={onDelete} />}
                heading={Paths.hotspot.name}
                breadcrumb_text='Controls the name, 3D position and image/video used of the Hotspot graphic. To amend the
                body text, you will need to refer to the Hotspot in Translations.'
            >
                <Card.Body>
                    <AddButton
                        className="custom-button"
                        button_name={readPerm === 1 ? Paths.hotspot.c1_name : null}
                        button_path={Paths.hotspot.c1_path}
                        button_title={Paths.hotspot.c1_name}
                        btn_class="list_button"
                    />
                    {successMessage && <Alert variant="success">{successMessage}</Alert>}
                    {(rowData !== undefined) && <UnorderedGrid gridData={rowData} columnDefs={columnDefs} />}
                </Card.Body>
            </LayoutWrapper>
        </>
    );
}

export default List;