export const getData = (url = '', method = 'GET', setData, type = 1, setOptions, label = '', configurator = false, initialZero = false, initialLabel = '', setDataTwo, dataOne, dataTwo) => {

    // type 2 will directly setOptions to jsondata in the last else condition
    // type 3 will setOptions directly to jsondata or jsondata.configurator based on configurator
    // type 4 is for user role, language list checkboxes
    // type 5 is for buttons in node config
    // initialZero is the blank option in the dropdown

    fetch(url, {
        method: method,
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem("access_token"),
        },
    })
        .then(result => result.json())
        .then(jsondata => {
            if (configurator) {
                if (type === 3) setOptions(jsondata.configurator);
                const dropdownOptions = jsondata.configurator.map((item) => ({
                    value: item.id,
                    label: item[label],
                }));
                if (initialZero) {
                    dropdownOptions.unshift({ value: '0', label: initialLabel });
                }
                setData(dropdownOptions);
            } else {
                if (type === 1 || type === 3) {
                    if (type === 3) setOptions(jsondata);
                    const dropdownOptions = jsondata.map((item) => ({
                        value: item.id,
                        label: item[label],
                    }));
                    setData(dropdownOptions);
                } else {
                    if (type === 4) setOptions(jsondata.data[0].data);
                    if (type === 5) {
                        setOptions(jsondata[dataOne]);
                        setDataTwo(jsondata[dataTwo]);
                    }
                    else setOptions(jsondata);
                }
            }
        });
};