import React from "react";
import UserForm from "./Form";
import { Paths } from "../../routes/route";
const Add = () => {

    const title = Paths.users.c1_name;

    return (
        <>
            <UserForm addform={1} title={title} usertype={2} visualstatus={1} />
        </>
    );
}

export default Add;