import React from "react";
import CountryForm from "./Form";
import { Paths } from "../../routes/route";
const Add = () => {

    const title = Paths.country.c1_name;

    return (
        <>
            <CountryForm addform={1} title={title} />
        </>
    );
}

export default Add;