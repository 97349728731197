import React from "react";
import ColorCompForm from "./Form";
import { Paths } from "../../routes/route";
const Add = () => {

	const title = Paths.colourconfiguration.c1_name;

	return (
		<>
			<ColorCompForm addform={1} title={title} />
		</>
	);
}

export default Add;