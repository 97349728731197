import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ColorCompForm from "./Form";
import { Paths } from "../../routes/route";
import { fetchEditData } from "../../helpers/API/Edit";

const Edit = () => {
	const API_URL = process.env.REACT_APP_API_URL;
	const { id } = useParams();

	const [editData, setEditData] = useState([]);
	const title = Paths.colourconfiguration.c2_name;

	useEffect(() => {
		fetchEditData(API_URL + 'v1/colorcomponent/edit?id=' + id, setEditData);

	}, [id]);

	let modifiedData = [];
	if(editData.colors) {
		modifiedData = editData.colors.map(item => {
			if (!item.hasOwnProperty('order_pdf')) {
			  return {
				...item,
				order_pdf: '' // Add the property and set it to an empty string
			  };
			}
			return item;
		  });
	}
	editData.colors = modifiedData;

	return (
		<>
			<ColorCompForm editpage={1} editData={editData} title={title} />
		</>
	);
}

export default Edit;