import React, { useState, useEffect, useContext } from "react";
import { Col, Row, Form, Button, Alert } from 'react-bootstrap';
import Select from 'react-select'
import LayoutWrapper from "../../components/UI/LayoutWrapper";
import DashboardCard from '../../components/UI/DashboardCard'
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useParams, useNavigate } from "react-router-dom";
import ModuleEditScreenOwnerInfo from "../../components/ModuleEditScreenOwnerInfo";
import DataContext from "../../context/DataContext";
import { useForm, useController } from "react-hook-form";
import { Paths } from "../../routes/route";
import { scrollToTop } from "../../framework/utilities.js/utilities";
import UnorderedGrid from "../../components/UnorderedGrid";
import { getOwnerInfo } from "../../framework/utilities.js/utilities";
import { handleSave } from "../../helpers/API/Save";
import { getData } from "../../helpers/API/Misc";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

const ColorCompForm = (props) => {
	const schema = z.object({
		name: z.string({ required_error: 'Please enter the valid component name' }).min(1),
		defaultColor: z.number({ required_error: "Please select any one option" }),
	});

	const { handleSubmit, register, setValue, control, formState: { errors } } = useForm({ resolver: zodResolver(schema) });
	const navigate = useNavigate();
	const { id } = useParams();
	const { auth } = useContext(DataContext);
	let userId = auth[0].user_profile.GID;
	const { editpage, editData, title } = props;

	const API_URL = process.env.REACT_APP_API_URL;
	const [glbName, setGlbName] = useState('');
	const [notAvailableName, setNotAvailableName] = useState('');
	const [pdfPartName, setPdfPartName] = useState('');
	const [chairColor, setChairColor] = useState(0);
	const [buttonOne, setButtonOne] = useState('');
	const [buttonTwo, setButtonTwo] = useState('');
	const [defaultColourPdfName, setDefaultColourPdfName] = useState('');
	const [chairColourPdfName, setChairColourPdfName] = useState('');
	const [filterNames, setFilterNames] = useState([]);
	const [showFilter, setShowFilter] = useState(0);

	const [partValue, setPartValue] = useState([]);
	const [defaultColorLabel, setDefaultColorLabel] = useState('');
	const [defaultColorValue, setDefaultColorValue] = useState('');
	const [colors, setColors] = useState([]);
	const [visualStatus, setVisualStatus] = useState(1);
	const [colorstData, setColorstData] = useState([]);
	const [filterOptions, setfilterOptions] = useState([]);
	const [defaultColortData, setDefaultColortData] = useState([]);
	const [partData, setPartData] = useState([]);
	const [startSave, setStartSave] = useState(false);

	const [createdAt, setCreatedAt] = useState("");
	const [updatedAt, setUpdatedAt] = useState("");
	const [createdBy, setCreatedBy] = useState("");
	const [updatedBy, setUpdatedBy] = useState("");

	const [errMessage, setErrorMessage] = useState('');
	const [successMessage, setSuccessMessage] = useState('');

	const storedValue = localStorage?.getItem('viz_id');

	const { field } = useController({ name: 'defaultColor', control })

	const handleChaircolorChange = (e) => {
		setChairColor(e.target.checked ? 1 : 0);
	};

	// Edited data
	useEffect(() => {
		if (editData) {
			setValue("name", editData.component_name);
			setGlbName(editData.glb_node_name);
			setNotAvailableName(editData.not_available_name);
			setPdfPartName(editData.pdf_part_name);
			setChairColor(editData.chair_color ? 1 : 0);
			setButtonOne(editData.button_one);
			setButtonTwo(editData.button_two);
			setDefaultColourPdfName(editData.pdf_name_one);
			setChairColourPdfName(editData.pdf_name_two);
			setShowFilter(editData.show_filter ? 1 : 0);
			setFilterNames(editData.filter_names);
			setPartValue(editData.parts);
			setColors(editData.colors);
			setValue("defaultColor", Number(editData.default_color));
			setDefaultColorValue(editData.default_color);
			setDefaultColorLabel(editData.default_color_label);
			setVisualStatus(editData.active ? 1 : 0);
			setCreatedAt(editData.created_at);
			setUpdatedAt(editData.updated_at);
			setCreatedBy(editData.created_by);
			setUpdatedBy(editData.updated_by);
		}
		if (!editpage) {
			setVisualStatus(1);
		}
	}, [props]);

	const ownerInfo = getOwnerInfo(createdAt, updatedAt, createdBy, updatedBy);
	const columnDefs = [
		{ headerName: "Colour", field: "label" },
		{ headerName: "Item Code", field: "order_pdf", editable: true }
	];


	useEffect(() => {

		// Colours dropdown
		getData(API_URL + '/v1/color/colorList', 'GET', setColorstData, 1, '', 'name', true);

		// Filter Name dropdown
		getData(API_URL + '/v1/color/listSurfaceFinish', 'GET', setfilterOptions, 1, '', 'name');

	}, []);

	// Defaulter color dropdown
	useEffect(() => {
		setDefaultColortData(colors);
	}, [colors]);

	const handleColorsChange = (data, e) => {
		setColors(data);

		// Code to remove the default colour if it is removed from colours dropdown
		if(e.action === 'remove-value'){
			if(defaultColorLabel === e.removedValue.label && defaultColorValue == e.removedValue.value)
			{
				setDefaultColorLabel('');
				setDefaultColorValue('');
				setValue("defaultColor", undefined);
			}
		}
	};

	// Parts dropdown
	useEffect(() => {

		// Parts dropdown
		getData(API_URL + '/v1/parts/partsDropDownList1?visualizer_id=' + storedValue, 'GET', '', 2, setPartData);

	}, []);

	const handleCellEditingStopped = (event) => {
		const updated = event.api.getModel();
		const rowsToDisplay = updated.rowsToDisplay;
		const finalArrayOfObjects = rowsToDisplay.map(rowNode => rowNode.data);
		setColors(finalArrayOfObjects);
    };

	////saving
	const onSubmit = (data) => {
		setStartSave(true);

		const fields = [
			{ name: 'glb_node_name', value: glbName },
			{ name: 'not_available_name', value: notAvailableName },
			{ name: 'pdf_part_name', value: pdfPartName },
			{ name: 'parts', value: JSON.stringify(partValue) },
			{ name: 'colors', value: JSON.stringify(colors) },
			{ name: 'default_color', value: defaultColorValue },
			{ name: 'chair_colour', value: chairColor },
			{ name: 'button1', value: buttonOne },
			{ name: 'button2', value: buttonTwo },
			{ name: 'pdf_name_one', value: defaultColourPdfName },
			{ name: 'pdf_name_two', value: chairColourPdfName },
			{ name: 'show_filter', value: showFilter },
			{ name: 'filter_names', value: JSON.stringify(filterNames) },
		];

		let formData = new FormData();
		formData.append("component_name", data.name);

		if (!editpage) {
			formData.append("created_by", userId);
		} else {
			formData.append("updated_by", userId);
		}

		fields.forEach(field => {
			if (field.value !== undefined && field.value !== null) {
				formData.append(field.name, field.value);
			}
		});

		formData.append("materials", 1);

		formData.append("active", visualStatus);
		formData.append("visualizer_id", storedValue);

		if (id !== undefined) {
			formData.append("GID", id);
		}

		handleSave(API_URL + '/v1/colorcomponent/save', setSuccessMessage, Paths.colourconfiguration.path, setErrorMessage, setStartSave, formData, navigate);
	}

	const handleSelectChange = (option) => {
		field.onChange(option.value)
		setDefaultColorLabel(option.label);
		setDefaultColorValue(option.value);
	}

	return (
		<>
			<LayoutWrapper breadcrumb_title='Colour component module'
				form={true}
				label={Paths.colourconfiguration.name}
				linkto={Paths.colourconfiguration.path}
				title={title}>
				{errMessage && <Alert variant="danger">{errMessage}</Alert>}
				{successMessage && !errMessage && <Alert variant="success">{successMessage}</Alert>}
				<Row>
					<Col lg='12' className=''>
						<DashboardCard className='pt-4'>
							<h5 className="w-100 px-4 py-4 m-0">{title}</h5>
							<Form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" className="w-100 px-4 pb-4" >
								<Col xl={12} lg={12} md={12} className="mb-3">
									<Form.Group id="name">
										<Form.Label className="required ">Component name <span style={{ color: 'red', marginLeft: '4px' }}>*</span></Form.Label>
										<Form.Control
											{...register("name")}
										/>
									</Form.Group>
									<div style={{ color: 'red' }}>{errors.name?.message}</div>
								</Col>
								<Col xl={12} lg={12} md={12} className="mb-3">
									<Form.Group id="glb_name">
										<Form.Label className="required ">Material reference</Form.Label>
										<Form.Control
											name="glb_name"
											value={glbName}
											onChange={(e) => {
												setGlbName(e.target.value);
											}}
										/>
									</Form.Group>
								</Col>
								<Col xl={12} lg={12} md={12} className="mb-3">
									<Form.Group id="not_available_name">
										<Form.Label className="required ">Not Available Name</Form.Label>
										<Form.Control
											name="not_available_name"
											value={notAvailableName}
											onChange={(e) => {
												setNotAvailableName(e.target.value);
											}}
										/>
									</Form.Group>
								</Col>
								<Col xl={12} lg={12} md={12} className="mb-3">
									<Form.Group id="pdf_part_name">
										<Form.Label className="required ">PDF Field Name</Form.Label>
										<Form.Control
											name="pdf_part_name"
											value={pdfPartName}
											onChange={(e) => {
												setPdfPartName(e.target.value);
											}}
										/>
									</Form.Group>
								</Col>
								<Col xl={12} lg={12} md={12} className="mb-3">
									<Form.Label className="required">Parts</Form.Label>
									<Form.Group id="Part">
										<Select
											isMulti
											isSearchable
											name="Part"
											options={partData}
											value={partValue}
											onChange={(data) => setPartValue(data)}
										/>
									</Form.Group>
								</Col>
								<Col xl={12} lg={12} md={12} className="mb-3">
									<Form.Label className="required">Colours</Form.Label>
									<Form.Group id="Colors">
										<Select
											isMulti
											isSearchable
											name="Colors"
											options={colorstData}
											value={colors}
											onChange={handleColorsChange}
										/>
									</Form.Group>
								</Col>
								<Col xl={12} lg={12} md={12} className="mb-3">
									<UnorderedGrid columnDefs={columnDefs} gridData={colors} handleCellEditingStopped={handleCellEditingStopped} />
								</Col>
								{colors &&
									<Col xl={12} lg={12} md={12} className="mb-3">
										<Form.Label>Default color <span style={{ color: 'red', marginLeft: '4px' }}>*</span></Form.Label>
										<Form.Group id="defaultColor">
											<Select
												{...register("defaultColor")}
												value={{ value: defaultColorValue, label: defaultColorLabel }}
												onChange={handleSelectChange}
												options={defaultColortData}
												isSearchable
											/>
										</Form.Group>
										<div style={{ color: 'red' }}>{errors.defaultColor?.message}</div>
									</Col>}
								<Col xl={6} lg={6} md={12} className="mb-3">
									<Form.Group id="chaircolor">
										<div className="d-inline-block me-4">
											<div className="d-inline-block pe-2">
												<input type="checkbox" id="chaircolor" checked={chairColor} name="chaircolor" onChange={handleChaircolorChange} style={{ cursor: 'pointer' }} />
											</div>
											<div className="d-inline-block">
												<label htmlFor="chaircolor" style={{ cursor: 'pointer' }}>Chair colour</label>
											</div>
										</div>
									</Form.Group>
								</Col>
								<Col xl={12} lg={12} md={12} className="mb-3" style={{ display: chairColor ? 'block' : 'none' }}>
									<Form.Group id="button_one">
										<Form.Label className="required">Button 1</Form.Label>
										<Form.Control name="button_one" value={buttonOne} onChange={(e) => setButtonOne(e.target.value)} />
									</Form.Group>
								</Col>
								<Col xl={12} lg={12} md={12} className="mb-3" style={{ display: chairColor ? 'block' : 'none' }}>
									<Form.Group id="button_two">
										<Form.Label className="required">Button 2</Form.Label>
										<Form.Control name="button_two" value={buttonTwo} onChange={(e) => setButtonTwo(e.target.value)} />
									</Form.Group>
								</Col>
								<Col xl={12} lg={12} md={12} className="mb-3" style={{ display: chairColor ? 'block' : 'none' }}>
									<Form.Group id="default_colour_pdf_name">
										<Form.Label className="required">Item Code For Black</Form.Label>
										<Form.Control name="default_colour_pdf_name" value={defaultColourPdfName} onChange={(e) => setDefaultColourPdfName(e.target.value)} />
									</Form.Group>
								</Col>
								<Col xl={12} lg={12} md={12} className="mb-3" style={{ display: chairColor ? 'block' : 'none' }}>
									<Form.Group id="chair_colour_pdf_name">
										<Form.Label className="required">Item Code For Coloured</Form.Label>
										<Form.Control name="chair_colour_pdf_name" value={chairColourPdfName} onChange={(e) => setChairColourPdfName(e.target.value)} />
									</Form.Group>
								</Col>
								<Col xl={6} lg={6} md={12} className="mb-3">
									<Form.Group id="show_filter">
										<div className="d-inline-block me-4">
											<div className="d-inline-block pe-2">
												<input type="checkbox" id="show_filter" checked={showFilter} name="show_filter" onChange={(e) => setShowFilter(e.target.checked ? 1 : 0)} style={{ cursor: 'pointer' }} />
											</div>
											<div className="d-inline-block">
												<label htmlFor="show_filter" style={{ cursor: 'pointer' }}>Show Filters</label>
											</div>
										</div>
									</Form.Group>
								</Col>
								<Col xl={12} lg={12} md={12} className="mb-3" style={{ display: showFilter ? 'block' : 'none' }}>
									<Form.Group id="filter_names">
										<Form.Label className="required ">Filter Name</Form.Label>
										<Select
											isMulti
											isSearchable
											name="filter_names"
											options={filterOptions}
											value={filterNames}
											onChange={(e) => {
												setFilterNames(e);
											}}
										/>
									</Form.Group>
								</Col>
								<Col md={6} className="mb-3">
									<Form.Group id="active">
										<Form.Label>Status</Form.Label>
										<div className="d-block">
											<div className="d-inline-block me-4">
												<div className="d-inline-block pe-2">
													<input type="radio" id="active1" name="active" label="Ja" checked={Number(visualStatus) === 1 ? true : false} value="1" onChange={(e) => {
														setVisualStatus(e.target.value);
													}} style={{ cursor: 'pointer' }}
													/>
												</div>
												<div className="d-inline-block">
													<label htmlFor="active1" style={{ cursor: 'pointer' }}>Active</label>
												</div>
											</div>
											<div className="d-inline-block">
												<div className="d-inline-block pe-2">
													<input type="radio" id="active0" name="active" label="Nein" checked={Number(visualStatus) === 0 ? true : false} value="0" onChange={(e) => {
														setVisualStatus(e.target.value);
													}} style={{ cursor: 'pointer' }}
													/>
												</div>
												<div className="d-inline-block">
													<label htmlFor="active0" style={{ cursor: 'pointer' }}>Inactive</label>
												</div>
											</div>
										</div>
									</Form.Group>
								</Col>
								{!startSave && <Row>
									<Col xl={5} lg={12} md={12} className="cancel_block">
										<div style={{ display: "flex" }} className="cancel_button">
											<Button className="list_button m-0" type="submit" >
												Save</Button>
											<Button
												className="list_button m-0"
												onClick={(e) => {
													e.preventDefault();
													navigate(`${Paths.colourconfiguration.path}`);
												}}
											>
												Cancel</Button>
										</div>
									</Col>
								</Row>}
								{editpage && <ModuleEditScreenOwnerInfo ownerInfo={ownerInfo} />}
							</Form>
						</DashboardCard>
					</Col>
				</Row>
			</LayoutWrapper>
		</>
	);
}

export default ColorCompForm;