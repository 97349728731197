import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import VisualizerForm from "./Form";
import { Paths } from "../../routes/route";
import { fetchEditData } from "../../helpers/API/Edit";

const Edit = () => {
	const API_URL = process.env.REACT_APP_API_URL;
	const { id } = useParams();
	const title = Paths.visualizer.c2_name;
	const [editData, setEditData] = useState([]);

	useEffect(() => {

		fetchEditData(API_URL + 'v1/visualizer/edit?id=' + id, setEditData);

	}, [id]);

	return (
		<>
			<VisualizerForm editpage={1} editData={editData} title={title} />
		</>
	);
}

export default Edit;