import { Card, Alert } from 'react-bootstrap';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import React, { useEffect, useState } from "react";
import UnorderedGrid from '../../components/UnorderedGrid';
import ConfirmDeleteModal from '../../components/ConfirmDeleteModal';
import { useParams } from "react-router-dom";
import delete_icon from "../../assets/images/delete.svg";
import { deleteBtn, handleOnDeleteSubmitted } from '../../helpers/API/Delete';
import { listData } from '../../helpers/API/List';

const GlbList = () => {
    const { part_id } = useParams();
    const API_URL = process.env.REACT_APP_API_URL;
    const [gridApi, setGridApi] = useState(null);
    let [modalShow, setModalShow] = useState(false);
    let [delRecTitle, setDelRecTitle] = useState('');
    let [delRecId, setDelRecId] = useState(0);

    let [glbData, setGlbData] = useState();
    const [successMessage, setSuccessMessage] = useState('');
    const [glbChange, setGlbChange] = useState();

    useEffect(() => {

        listData(API_URL + 'v1/filesList?id=' + part_id + '&table=tbl_parts', setGlbData);

    }, [part_id, glbChange]);

    // Each Column Definition results in one Column.
    const [columnDefs] = useState([
        {
            headerName: "Filename", field: 'url', flex: 1, filter: true, sortable: true, resizable: true, cellRendererFramework: (params) => {
                let path = params.data.url;
                let url = `${API_URL}/v1/download?file=${path}`;
                return (
                    <div>
                        <a href={url} rel="noopener noreferrer" target="blank"> {path} </a>
                    </div>
                );

            }
        },
        { headerName: "File size", field: 'size', minWidth: 120, maxWidth: 120, filter: true, sortable: true, resizable: true },
        {
            headerName: "", field: "id", flex: 1, minWidth: 50, maxWidth: 50, headerClass: "ag-theme-custom-text-center", cellClass: "pm-grid-number-cell ag-theme-custom-content-center",
            cellRenderer: (params) => <div className="ag-theme-content-text-center">

                {<span onClick={() => deleteBtn(params, 'caption', setDelRecId, setDelRecTitle, setModalShow)} title="Delete" className="grid-action-btns delete-icon-color link"><img src={delete_icon} alt="delete" className="me-2" style={{ width: '24px', height: '24px' }} /></span>}
            </div>
        }

    ]);

    const onDelete = () => {
        handleOnDeleteSubmitted('/v1/deleteFile?GID=' + delRecId, setSuccessMessage, setGlbChange, setModalShow, setDelRecId, setDelRecTitle, glbChange, delRecId, gridApi);
    }

    return (
        <>

            <ConfirmDeleteModal modalShow={modalShow} handleModelClose={() => { setModalShow(false); }} delRecTitle={delRecTitle} handleOnDeleteSubmitted={onDelete} />
            <Card.Body>
                {successMessage && <Alert variant="success">{successMessage}</Alert>}
                {(glbData !== undefined) && <UnorderedGrid gridData={glbData} columnDefs={columnDefs} />}
            </Card.Body>
        </>
    );
}

export default GlbList;