import { useState } from "react";
import { Navigate } from "react-router-dom";
import sunrise_video from "../../../assets/videos/about-sunrise.mp4";
import logo from "../../../assets/images/Logo.svg";
import { faMoon, faSun, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "../../../components/UI/Sidebar.scss";
import "./LoginLayout.scss";
import LoginForm from "./LoginForm";
import ForgotPassword from "../ForgotPassword";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LoginLayout = () => {
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const goBackToLogin = () => {
    setShowForgotPassword(false);
  };

  const switchHandler = () => {
    document.querySelector("body").classList.toggle("dark");

    if (document.querySelector("body").classList.contains("dark")) {
      document.querySelector("body").querySelector(".mode-text1").innerText =
        "Dark mode";
    } else {
      document.querySelector("body").querySelector(".mode-text1").innerText =
        "Light mode";
    }
  };
  if (localStorage.getItem("isLoggedin")) {
    return <Navigate to="/" replace />;
  }

  return (
    <>
      <div className="mode login-dark-mode">
        <div className="sun-moon">
          {showForgotPassword && (
            <div
              className="icon"
              onClick={goBackToLogin}
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faArrowLeft} className="left-arrow-icon" />
            </div>
          )}
          <div
            className="icon"
            onClick={switchHandler}
            style={{ cursor: "pointer" }}
          >
            <FontAwesomeIcon icon={faMoon} className="dark-moon" />
            <FontAwesomeIcon icon={faSun} className="light-sun" />
          </div>
        </div>
      </div>
      <div className="login_main">
        <div className="bg_video">
          <div className="video-container">
            <video id="background_video" width="100%" autoPlay loop muted>
              <source src={sunrise_video} type="video/mp4" />
            </video>
            <div className="content caption">
              <img src={logo} alt="sunrise logo" />
              <h1>
                Visualiser <span>Management</span> System
              </h1>
            </div>
          </div>
        </div>

        <div className="login_form_block">
          <div>
            {showForgotPassword ? (
              <ForgotPassword />
            ) : (
              <LoginForm
                onForgotPasswordClick={() => setShowForgotPassword(true)}
              />
            )}
          </div>
        </div>
      </div>

      <div className="mobile-screen">
        <h2 className="fw-bold mb-0 text-center text-white">
          Please use ipad or desktop
        </h2>
      </div>
    </>
  );
};

export default LoginLayout;
