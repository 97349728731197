import React from 'react'
import './Loader.scss'

const Loader = () => {
    return (
        <div className='loaderwrapper'>
            <div id='outer'>
                <div id='middle'>
                    <div id='inner'>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Loader