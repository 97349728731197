import React, { useState, useContext } from "react";
import { Col, Row, Form, Button, Alert } from 'react-bootstrap';
import LayoutWrapper from "../../components/UI/LayoutWrapper";
import DashboardCard from '../../components/UI/DashboardCard'
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useParams, useNavigate } from "react-router-dom";
import { Paths } from "../../routes/route";
import DataContext from "../../context/DataContext";
import Loader from '../../components/UI/Loader';
import { handleSave } from "../../helpers/API/Save";

const Clone = () => {
	const navigate = useNavigate();
	const API_URL = process.env.REACT_APP_API_URL;
	const { id } = useParams();
	const { dispatchInfo, info } = useContext(DataContext);

	const [visualizerName, setVisualizerName] = useState('');
	const [visualStatus, setVisualStatus] = useState('1');
	const [errMessage, setErrorMessage] = useState('');
	const [successMessage, setSuccessMessage] = useState('');
	const [glbClone, setGlbClone] = useState('1');

	// Saving
	const handleSubmit = (e) => {
		dispatchInfo({ type: 'SET_LOADER', setvalue: true });
		e.preventDefault();
		if (!visualizerName) {
			setErrorMessage('Please fill the required fields');
			return;
		}

		let formData = new FormData();
		formData.append("visualizer_name", visualizerName);
		formData.append("active", visualStatus || 1);
		formData.append("glbclone", glbClone);

		let url = API_URL + '/v1/data/duplicate';
		if (id !== undefined) {
			formData.append("GID", id);
		}

		handleSave(url, setSuccessMessage, Paths.visualizer.path, setErrorMessage, '', formData, navigate, '', '', '', 1, '', '', '', dispatchInfo);
	}

	return (
		<>
			{info[0].loader && <Loader />}
			<LayoutWrapper
				breadcrumb_title={Paths.visualizer.name}
				form={true}
				label={Paths.visualizer.name}
				linkto={Paths.visualizer.path}
				title={Paths.visualizer.c3_name}
			>
				<Row>
					<Col lg={12} className=''>
						{errMessage && <Alert variant="danger">{errMessage}</Alert>}
						{successMessage && !errMessage && <Alert variant="success">{successMessage}</Alert>}
						<DashboardCard className='pt-4'>
							<h5 className="w-100 px-4 py-4 m-0">{Paths.visualizer.c3_name}</h5>
							<Form onSubmit={handleSubmit} encType="multipart/form-data" className="w-100 px-4 pb-4" >
								<Col xl={12} lg={12} md={12} className="mb-3">
									<Form.Group id="visualizer_name">
										<Form.Label className="required ">Visualizer name <span style={{ color: 'red', marginLeft: '4px' }}>*</span></Form.Label>
										<Form.Control
											name="visualizer_name"
											value={visualizerName}
											onChange={(e) => {
												setVisualizerName(e.target.value);
											}}
										/>
									</Form.Group>
								</Col>
								<Col md={6} className="mb-3">
									<Form.Group id="glbclone">
										<Form.Label>Clone</Form.Label>
										<div className="d-block">
											<div className="d-inline-block me-4">
												<div className="d-inline-block pe-2">
													<input type="radio" id="glbclone1" name="glbclone" label="Yes" checked={glbClone === '1' ? true : false} value="1" onChange={(e) => {
														setGlbClone(e.target.value);
													}} style={{ cursor: 'pointer' }}
													/>
												</div>
												<div className="d-inline-block">
													<label htmlFor="glbclone1" style={{ cursor: 'pointer' }}>UI And All 3D Models</label>
												</div>
											</div>
											<div className="d-inline-block">
												<div className="d-inline-block pe-2">
													<input type="radio" id="glbclone0" name="glbclone" label="No" checked={glbClone === '0' ? true : false} value="0" onChange={(e) => {
														setGlbClone(e.target.value);
													}} style={{ cursor: 'pointer' }}
													/>
												</div>
												<div className="d-inline-block">
													<label htmlFor="glbclone0" style={{ cursor: 'pointer' }}>UI Only Without 3D Models</label>
												</div>
											</div>
										</div>
									</Form.Group>
								</Col>
								<Col md={6} className="mb-3">
									<Form.Group id="active">
										<Form.Label>Status</Form.Label>
										<div className="d-block">
											<div className="d-inline-block me-4">
												<div className="d-inline-block pe-2">
													<input type="radio" id="active1" name="active" label="Ja" checked={visualStatus === '1' ? true : false} value="1" onChange={(e) => {
														setVisualStatus(e.target.value);
													}} style={{ cursor: 'pointer' }}
													/>
												</div>
												<div className="d-inline-block">
													<label htmlFor="active1" style={{ cursor: 'pointer' }}>Active</label>
												</div>
											</div>
											<div className="d-inline-block">
												<div className="d-inline-block pe-2">
													<input type="radio" id="active0" name="active" label="Nein" checked={visualStatus === '0' ? true : false} value="0" onChange={(e) => {
														setVisualStatus(e.target.value);
													}} style={{ cursor: 'pointer' }}
													/>
												</div>
												<div className="d-inline-block">
													<label htmlFor="active0" style={{ cursor: 'pointer' }}>Inactive</label>
												</div>
											</div>
										</div>
									</Form.Group>
								</Col>
								<Col xl={5} lg={12} md={12} className="">
									<div >
										<Button variant="m-0 btn btn-primary list_button" type="submit" >
											Save</Button>
									</div>
								</Col>
							</Form>
						</DashboardCard>
					</Col>
				</Row>
			</LayoutWrapper>
		</>
	);
}

export default Clone;