import { createContext, useReducer } from 'react';
import { layoutReducer } from '../reducers/layoutReducer';
import { authReducer } from '../reducers/authReducer';
import { infoReducer } from '../reducers/infoReducer';

const DataContext = createContext({});

export const DataProvider = ({ children }) => {

	const [auth, dispatchAuth] = useReducer(authReducer, [
		{
			'isLoggedin': false,
			'expires_in': '',
			'access_token': '',
			'refresh_token': '',
			'user_profile': [],
			'access_modules': [],
		}
	])

	const [layout, dispatchLayout] = useReducer(layoutReducer, [
		{
			'mode_selector': false,
			'uploaded_files': []
		}
	])

	const [info, dispatchInfo] = useReducer(infoReducer, [
		{
			'visualizer_id': 1,
			'loader': false,
		}
	])

	return (
		<DataContext.Provider
			value={{
				layout, dispatchLayout,
				auth, dispatchAuth,
				info, dispatchInfo
			}}
		>
			{children}
		</DataContext.Provider>
	)
}

export default DataContext;