import { Card, Alert } from 'react-bootstrap';
import ConfirmDeleteModal from '../../components/ConfirmDeleteModal';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import React, { useEffect, useState } from "react";
import edit from "../../assets/images/edit.svg";
import delete_icon from "../../assets/images/delete.svg";
import { useLocation } from "react-router-dom";
import { Link } from 'react-router-dom';
import LayoutWrapper from '../../components/UI/LayoutWrapper';
import { handleOpenInNewTab, naviLinks } from '../../framework/utilities.js/utilities';
import UnorderedGrid from '../../components/UnorderedGrid';
import { Paths } from '../../routes/route';
import AddButton from '../../components/AddButton';
import { deleteBtn, handleOnDeleteSubmitted } from '../../helpers/API/Delete';
import { listData } from '../../helpers/API/List';

const List = () => {
	const API_URL = process.env.REACT_APP_API_URL;
	const [gridApi, setGridApi] = useState(null);
	const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row
	let [modalShow, setModalShow] = useState(false);
	let [delRecTitle, setDelRecTitle] = useState('');
	let [delRecId, setDelRecId] = useState(0);
	const [categoryDeleted, setCategoryDeleted] = useState(false);
	const [successMessage, setSuccessMessage] = useState('');

	// Code to fetch title based on URL
	const { pathname } = useLocation();
	const naviArr = naviLinks(pathname);
	const createPerm = naviArr['edit_perm'];
	const deletePerm = naviArr['delete_perm'];
	const readPerm = naviArr['add_perm'];

	const storedValue = localStorage?.getItem('viz_id');

	// Each Column Definition results in one Column.
	const [columnDefs] = useState([
		{ headerName: "Category", field: 'name', filter: true, sortable: true, rowDrag: true, resizable: true, flex: 1.75 },
		{ headerName: "Parent category", field: 'parent_name', filter: true, sortable: true, resizable: true, flex: 1.5 },
		{ headerName: "No. of parts", field: 'count', filter: true, sortable: true, resizable: true, minWidth: 130, maxWidth: 130 },
		{
			headerName: "", field: "id", minWidth: 50, maxWidth: 50, headerClass: "ag-theme-custom-text-center", cellClass: "pm-grid-number-cell ag-theme-custom-content-center",
			cellRenderer: (params) => <div className="ag-theme-content-text-center">
				{createPerm === 1 ? <Link onClick={handleOpenInNewTab} to={(`${Paths.partscategory.c2_path.split(":").slice(0, -1)[0]}` + params.value)} rel="noopener noreferrer" title="Edit" className="grid-action-btns text-success link"><img src={edit} alt="edit" className="me-2" style={{ width: '24px', height: '24px' }} /></Link> : null}

			</div>
		},
		{
			headerName: "", field: "id", minWidth: 50, maxWidth: 50, headerClass: "ag-theme-custom-text-center", cellClass: "pm-grid-number-cell ag-theme-custom-content-center",
			cellRenderer: (params) => <div className="ag-theme-content-text-center">

				{deletePerm === 1 ? <span onClick={() => deleteBtn(params, 'name', setDelRecId, setDelRecTitle, setModalShow)} title="Delete" className="grid-action-btns delete-icon-color link"><img src={delete_icon} alt="delete" className="me-2" style={{ width: '24px', height: '24px' }} /></span> : null}
			</div>
		}
	]);

	const onDelete = () => {
		handleOnDeleteSubmitted('/v1/category/deleteCategory', setSuccessMessage, setCategoryDeleted, setModalShow, setDelRecId, setDelRecTitle, categoryDeleted, delRecId, gridApi);
	}

	useEffect(() => {

		listData(API_URL + '/v1/category/categoryList?visualizer_id=' + storedValue, setRowData);

	}, [categoryDeleted]);

	return (
		<>
			<LayoutWrapper
				modal={<ConfirmDeleteModal modalShow={modalShow} handleModelClose={() => { setModalShow(false); }} delRecTitle={delRecTitle} handleOnDeleteSubmitted={onDelete} />}
				heading={Paths.partscategory.name}
				breadcrumb_text='Add, edit or remove the main menu categories, as well as their sub-categories. For example,
				the parent category ‘WHEEL LOCKS MAIN’ contains the sub-category ‘WHEEL LOCKS’. Within
				each subcategory, you have the ability amend the default chair part.'
			>
				<Card.Body>
					<div className='d-flex gap-3'>
						<AddButton
							className="custom-button"
							button_name={readPerm === 1 ? Paths.partscategory.c1_name : null}
							button_path={`${Paths.partscategory.c3_path}?type=1`}
							button_title={Paths.partscategory.c1_name}
							btn_class="list_button"
						/>
						<AddButton
							className="custom-button"
							button_name={readPerm === 1 ? Paths.partscategory.c3_name : null}
							button_path={`${Paths.partscategory.c3_path}?type=2`}
							button_title={Paths.partscategory.c3_name}
							btn_class="list_button"
						/>
					</div>
					{successMessage && <Alert variant="success">{successMessage}</Alert>}
					{(rowData !== undefined) && <UnorderedGrid gridData={rowData} columnDefs={columnDefs} />}
				</Card.Body>
			</LayoutWrapper>
		</>
	);
}

export default List;