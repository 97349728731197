import { useState, useEffect } from 'react';
import { handleOpenInNewTab } from '../../framework/utilities.js/utilities';
import { Card, Alert } from 'react-bootstrap';
import ConfirmDeleteModal from '../../components/ConfirmDeleteModal';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import edit from "../../assets/images/edit.svg";
import LayoutWrapper from '../../components/UI/LayoutWrapper';
import UnorderedGrid from '../../components/UnorderedGrid';
import { Paths } from '../../routes/route';
import { Link } from 'react-router-dom';
import delete_icon from "../../assets/images/delete.svg";
import AddButton from '../../components/AddButton';
import { deleteBtn, handleOnDeleteSubmitted } from '../../helpers/API/Delete';
import { listData } from '../../helpers/API/List';

const UserRoleList = () => {

	const API_URL = process.env.REACT_APP_API_URL;

	const [modalShow, setModalShow] = useState(false);
	const [delRecId, setDelRecId] = useState(0);
	const [delRecTitle, setDelRecTitle] = useState('');
	const [gridApi, setGridApi] = useState(null);
	const [rowData, setRowData] = useState();
	const [userRoleDeleted, setUserRoleDeleted] = useState(false);
	const [successMessage, setSuccessMessage] = useState('');

	useEffect(() => {

		listData(API_URL + '/v1/userroles/data/list', setRowData);

	}, [userRoleDeleted]);

	const [columnDefs] = useState([
		{ headerName: 'Role name', field: "name", sortable: true, filter: true, flex: 4 },
		{
			headerName: "", field: "id", flex: 1, minWidth: 100, maxWidth: 100, headerClass: "ag-theme-custom-text-center", cellClass: " ag-theme-custom-content-center d-flex align-items-center gap-2",
			cellRendererFramework: (params) => <>
				{<Link onClick={handleOpenInNewTab} to={(`${Paths.userrole.c2_path.split(":").slice(0, -1)[0]}` + params.value)} rel="noopener noreferrer" title="Edit" className="grid-action-btns text-success link"><img src={edit} alt="edit" className="me-2" style={{ width: '24px', height: '24px' }} /></Link>}

				<span onClick={() => { deleteBtn(params, 'name', setDelRecId, setDelRecTitle, setModalShow); }} title="Delete" className="grid-action-btns text-danger link"><img src={delete_icon} alt="delete" className="" style={{ width: '24px', height: '24px' }} /></span>
			</>
		}
	]);

	const onDelete = () => {
		handleOnDeleteSubmitted('/v1/userroles/data/delete/' + delRecId, setSuccessMessage, setUserRoleDeleted, setModalShow, setDelRecId, setDelRecTitle, userRoleDeleted, delRecId, gridApi);
	}

	const onGridReady = (params) => {
		setGridApi(params.api);
	};

	return (
		<>
			<LayoutWrapper
				modal={<ConfirmDeleteModal modalShow={modalShow} handleModelClose={() => { setModalShow(false); }} delRecTitle={delRecTitle} handleOnDeleteSubmitted={onDelete} />}
				heading={Paths.userrole.name}
				breadcrumb_text='Add edit and delete user access roles. You can assign or remove access for each individual
                user, based upon the sections in the CMS.'
			>
				<Card.Body>
					<AddButton
						className="custom-button"
						button_name={Paths.userrole.c1_name}
						button_path={Paths.userrole.c1_path}
						button_title={Paths.userrole.c1_name}
						btn_class="list_button"
					/>
					{successMessage && <Alert variant="success">{successMessage}</Alert>}
					{(rowData !== undefined) && <UnorderedGrid gridData={rowData} columnDefs={columnDefs} onGridReady={onGridReady} />}
				</Card.Body>
			</LayoutWrapper>
		</>
	);
}

export default UserRoleList;