import { Card, Alert } from 'react-bootstrap';
import ConfirmDeleteModal from '../../components/ConfirmDeleteModal';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import React, { useEffect, useState } from "react";
import edit from "../../assets/images/edit.svg";
import delete_icon from "../../assets/images/delete.svg";
import { useLocation } from "react-router-dom";
import { Link } from 'react-router-dom';
import LayoutWrapper from '../../components/UI/LayoutWrapper';
import { handleOpenInNewTab, naviLinks } from '../../framework/utilities.js/utilities';
import UnorderedGrid from '../../components/UnorderedGrid';
import { Paths } from '../../routes/route';
import AddButton from '../../components/AddButton';
import { deleteBtn, handleOnDeleteSubmitted } from '../../helpers/API/Delete';
import { listData } from '../../helpers/API/List';

const List = () => {
	const API_URL = process.env.REACT_APP_API_URL;
	const [gridApi, setGridApi] = useState(null);
	const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row
	let [modalShow, setModalShow] = useState(false);
	let [delRecTitle, setDelRecTitle] = useState('');
	let [delRecId, setDelRecId] = useState(0);
	const [successMessage, setSuccessMessage] = useState('');
	const [partsDeleted, setPartsDeleted] = useState(false);

	// Code to fetch title based on URL
	const { pathname } = useLocation();
	const naviArr = naviLinks(pathname);
	const createPerm = naviArr['edit_perm'];
	const deletePerm = naviArr['delete_perm'];
	const readPerm = naviArr['add_perm'];

	const storedValue = localStorage?.getItem('viz_id');

	// Each Column Definition results in one Column.
	const [columnDefs] = useState([
		{ headerName: "Part dependency name", field: 'part_dependency_name', filter: true, sortable: true, resizable: true, flex: 1.25 },
		{ headerName: "Status", field: "active", sortable: true, filter: true, minWidth: 300, maxWidth: 310 },
		{
			headerName: "", field: "id", minWidth: 50, maxWidth: 50, headerClass: "ag-theme-custom-text-center", cellClass: "pm-grid-number-cell ag-theme-custom-content-center",
			cellRenderer: (params) => <div className="ag-theme-content-text-center">
				{createPerm === 1 ? <Link onClick={handleOpenInNewTab} to={(`${Paths.partsconfiguration.c2_path.split(":").slice(0, -1)[0]}` + params.value)} title="Edit" className="grid-action-btns text-success link"><img src={edit} alt="edit" className="" style={{ width: '24px', height: '24px' }} /></Link> : null}

			</div>
		},
		{
			headerName: "", field: "id", minWidth: 50, maxWidth: 50, headerClass: "ag-theme-custom-text-center", cellClass: "pm-grid-number-cell ag-theme-custom-content-center",
			cellRenderer: (params) => <div className="ag-theme-content-text-center">

				{deletePerm === 1 ? <span onClick={() => deleteBtn(params, 'part_dependency_name', setDelRecId, setDelRecTitle, setModalShow)} title="Delete" className="grid-action-btns delete-icon-color link"><img src={delete_icon} alt="delete" className="" style={{ width: '24px', height: '24px' }} /></span> : null}
			</div>
		}

	]);

	const onDelete = () => {
		handleOnDeleteSubmitted('/v1/partsConfiguration/delete', setSuccessMessage, setPartsDeleted, setModalShow, setDelRecId, setDelRecTitle, partsDeleted, delRecId, gridApi);
	}

	useEffect(() => {

		listData(API_URL + '/v1/partsConfiguration/list?visualizer_id=' + storedValue, setRowData);

	}, [partsDeleted]);

	return (
		<>
			<LayoutWrapper
				modal={<ConfirmDeleteModal modalShow={modalShow} handleModelClose={() => { setModalShow(false); }} delRecTitle={delRecTitle} handleOnDeleteSubmitted={onDelete} />}
				heading={Paths.partsconfiguration.name}
				breadcrumb_text='Where multiple versions of the same part are required, for example, where you have three
				fixed frames and three swing-away frames, each frame angle requires a different glb model
				to be loaded. Option to add, amend or delete the dependencies for each individual part that
				is impacted by another part choice.'
			>
				<Card.Body>
					<AddButton
						className="custom-button"
						button_name={readPerm === 1 ? Paths.partsconfiguration.c1_name : null}
						button_path={Paths.partsconfiguration.c1_path}
						button_title={Paths.partsconfiguration.c1_name}
						btn_class="list_button"
					/>
					{successMessage && <Alert variant="success">{successMessage}</Alert>}
					{(rowData !== undefined) && <UnorderedGrid gridData={rowData} columnDefs={columnDefs} />}
				</Card.Body>
			</LayoutWrapper>
		</>
	);
}

export default List;