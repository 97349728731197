import React from "react";

const TranslationBlock = ({ specialModuleList, checkedIds, handleCheckboxChange, selectLanguage }) => {
    return (
        <div>
            {specialModuleList.map((item, i) => (
                <div key={i} className="user_block">
                    <div className=" mb-2 ">
                        <div className="fw-bold mb-2">Language list</div>
                        <div className="d-flex gap-2 mb-2 ">
                        </div>
                    </div>
                    <div className="translation_block">
                        {selectLanguage?.data[0]?.data?.map((ele, index) => (
                            <div key={index} className="mb-3 translation_innerblock">
                                <label className="dropdown-option d-flex gap-2 mb-2">
                                    <div>{ele.mode}</div>
                                </label>
                                <div className="mb-3 ps-4">
                                    {[ele.children].map((d, i) => (
                                        <div key={i} className="mb-4">
                                            <div className="d-flex gap-3 flex-wrap">
                                                {d[2]?.lang.map((langItem) => (
                                                    <div key={langItem.id} className="d-flex gap-5">
                                                        <div className="d-flex gap-5">
                                                            <label className="dropdown-option d-flex gap-2">
                                                                <input type="checkbox"
                                                                    name={langItem.visualiser}
                                                                    value={langItem.id}
                                                                    checked={checkedIds.includes(langItem.id.toString())}
                                                                    onChange={handleCheckboxChange}
                                                                />
                                                                {langItem.name}
                                                            </label>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default TranslationBlock;
