import { Alert, Card } from 'react-bootstrap';
import ConfirmDeleteModal from '../../components/ConfirmDeleteModal';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import React, { useEffect, useState } from "react";
import delete_icon from "../../assets/images/delete.svg";
import { useParams } from "react-router-dom";
import UnorderedGrid from '../../components/UnorderedGrid';
import { scrollToTop } from '../../framework/utilities.js/utilities';
import { deleteBtn, handleOnDeleteSubmitted } from '../../helpers/API/Delete';
import { getData } from '../../helpers/API/Misc';

const List = (props) => {
	const API_URL = process.env.REACT_APP_API_URL;
	const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row
	const [originalData, setOriginalData] = useState();
	const { cat_id } = useParams();

	let [modalShow, setModalShow] = useState(false);
	let [delRecTitle, setDelRecTitle] = useState('');

	const [columnDefs, setColumnDefs] = useState([]);
	const [dependencyOptionsId, setDependencyOptionsId] = useState(0);
	const [partNameId, setPartNameId] = useState([]);
	const [componentNameId, setComponentNameId] = useState([]);
	const [buttonChanged, setButtonChanged] = useState(false);
	const [rowIndex, setRowIndex] = useState(0);
	const [successMessage, setSuccessMessage] = useState('');

	const storedValue = localStorage?.getItem('viz_id');

	useEffect(() => {

		// Fetch part & component data
		if (props.subCatId) {
			getData(API_URL + '/v1/nodeConfig/getPartsAndComponents?subCatId=' + props.subCatId + '&visualizer_id=' + storedValue, 'GET', '', 5, setPartNameId, '', false, false, '', setComponentNameId, 'partData', 'componentData');
		}
	}, [props]);

	const onDelete = () => {
		const newData = [...rowData];
		newData.splice(rowIndex, 1);
		const updatednewData = newData.map(entry => ({
			...entry,
			assign_part: entry.assign_part ? partNameId.find(part => part.name === entry.assign_part)?.part_id : null,
			component: entry.component ? componentNameId.find(component => component.name === entry.component)?.component_id : null
		}));

		handleOnDeleteSubmitted('/v1/nodeConfig/deleteButton', setSuccessMessage, setButtonChanged, setModalShow, setRowIndex, setDelRecTitle, buttonChanged, dependencyOptionsId, '', 1, JSON.stringify(updatednewData));
	}

	useEffect(() => {

		fetch(API_URL + '/v1/nodeConfig/getOptions?id=' + cat_id, {
			method: 'GET',
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem("access_token"),
			},
		})
			.then(result => result.json())
			.then(jsondata => {
				setOriginalData(jsondata.options);
				setRowData(jsondata.options);
				setDependencyOptionsId(jsondata.dependency_options_id);
				// Each Column Definition results in one Column.
				const columnDefsApi = [
					{
						headerName: "Button Name",
						field: 'button_name',
						filter: true,
						sortable: true,
						resizable: true,
						rowDrag: true,
						editable: true,
						flex: 1
					},
					{
						headerName: "Button Type",
						field: 'button_type',
						filter: true,
						sortable: true,
						resizable: true,
						flex: 1.25,
						editable: true,
						cellEditor: 'agSelectCellEditor',
						cellEditorParams: {
							values: ['Button', 'Colour', 'Toggle'],
						},
					},
					{
						headerName: "Part",
						field: 'assign_part',
						filter: true,
						sortable: true,
						resizable: true,
						flex: 1.25,
						editable: true,
						cellEditor: 'agSelectCellEditor',
						cellEditorParams: {
							values: ['', ...partNameId.map(item => item.name)],
						},
					},
					{
						headerName: "Component",
						field: 'component',
						filter: true,
						sortable: true,
						resizable: true,
						flex: 1.25,
						editable: true,
						cellEditor: 'agSelectCellEditor',
						cellEditorParams: {
							values: ['', ...componentNameId.map(item => item.name)],
						},
					},
					{
						headerName: "",
						field: "",
						width: 60,
						cellRendererFramework: (params) => (
							<div className="text-column d-flex justify-content-between">
								<span
									className="delete-icon delete-icon-color link"
									onClick={() => deleteBtn(params, 'button_name', setRowIndex, setDelRecTitle, setModalShow, 'rowIndex')}
									title="Delete"
								>
									<img
										src={delete_icon}
										alt="delete"
										className=""
										style={{ width: '24px', height: '24px' }}
									/>
								</span>
								<span className="ellipsis" title={params.value}>
									{params.value}
								</span>
							</div>
						),
					},

				];
				setColumnDefs(columnDefsApi);
			})
	}, [buttonChanged, props, partNameId, componentNameId]);

	const updateDataInDatabase = (options) => {
		const url = API_URL + 'v1/nodeConfig/updateButton';
		const formData = new FormData();

		formData.append("id", dependencyOptionsId);
		formData.append("options", JSON.stringify(options));
		formData.append("visualizer", storedValue);

		return fetch(url, {
			method: 'POST',
			body: formData,
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem("access_token"),
			},
		})
			.then(response => response.json())
			.catch(err => {
				console.error('An error occurred:', err);
			});
	};

	const handleCellEditingStopped = (event) => {

		const data = event.data; // This is the data of the whole row whose cell is edited
		const rowIndex = event.rowIndex; // Row no. where cell is edited, 0 indexing

		const newData = originalData;

		// Replacing the newData element with data completely
		newData[rowIndex] = data;

		setOriginalData(newData);

		// The below is the format we need to store in the db
		const savingData = newData.map(({ button_name, button_type, assign_part, component }) => ({
			button_name,
			button_type,
			assign_part,
			component
		}));

		updateDataInDatabase(savingData)
			.then(jsondata => {
				setSuccessMessage(jsondata.message);
				setTimeout(function () {
					setSuccessMessage('');
				}, 2000);
			});
	};

	const onRowDragEnd = (params) => {

		const updated = params.api.getModel();
		const rowsToDisplay = updated.rowsToDisplay;
		const finalArrayOfObjects = rowsToDisplay.map(rowNode => rowNode.data);
		setOriginalData(finalArrayOfObjects);

		updateDataInDatabase(finalArrayOfObjects)
			.then(jsondata => {
				if (jsondata.message === 'Record updated successfully.') {
					setButtonChanged(!buttonChanged);
				} else {
					console.error('Something went wrong.');
				}
			});
	};

	const handleAddButton = (event) => {
		event.preventDefault();
		const add = { button_name: 'New Button', button_type: 'Button', assign_part: '', component: '' };

		const savingData = [...rowData, add];
		updateDataInDatabase(savingData)
			.then(jsondata => {
				if (jsondata.message === 'Record updated successfully.') {
					scrollToTop();
					setSuccessMessage('Record created successfully.');
					setTimeout(function () {
						setSuccessMessage('');
					}, 2000);
					setButtonChanged(!buttonChanged);
				} else {
					console.error('Something went wrong.');
				}
			});
	};

	return (
		<>
			<ConfirmDeleteModal modalShow={modalShow} handleModelClose={() => { setModalShow(false); }} delRecTitle={delRecTitle} handleOnDeleteSubmitted={onDelete} message='Please also make related part inactive' />
			<Card.Body style={{ margin: '30px 0 0' }}>
				{successMessage && <Alert variant="success">{successMessage}</Alert>}
				{(rowData !== undefined) && <UnorderedGrid gridData={rowData} columnDefs={columnDefs}
					handleCellEditingStopped={handleCellEditingStopped} onRowDragEnd={onRowDragEnd} rowDragManaged={true} rowDragEntireRow={true}
				/>}
                <div style={{ margin: '30px 0 0', display: 'flex', justifyContent: 'right' }}>
				<button onClick={handleAddButton} className='list_button'>Add Button</button>
				</div>
			</Card.Body>
		</>
	);
}

export default List;