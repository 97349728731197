import './LayoutWrapper.scss'
import { Link } from 'react-router-dom'
import ModuleBreadcrumb from './ModuleBreadcrumb'
import { Paths } from '../../routes/route';

const LayoutWrapper = ({ children, button_name, button_path, button_title, button_name2, btn_class, modal, modal1, custom_class = '', heading, form, label, title, linkto, breadcrumb_text, className }) => {

    return (
        <div className="grid-container">
            {modal}
            {modal1}
            {form ?
                <div className={`combined_breadcrumb ${className}`}>
                    <div className='document-breadcrumb'>
                        <ModuleBreadcrumb links={[{ label: label, linkto: linkto }, { label: title }]} />
                    </div>
                    <div className="document-name pe-3">{label}</div>
                </div>
                :
                <div className={`combined_breadcrumb ${className}`}>
                    <div className='document-breadcrumb'>
                        <ModuleBreadcrumb className="pb-0" links={[{ label: heading }]} />
                    </div>
                    <div className="document-name pe-3">{heading}</div>
                    <h5 className='breadcrumb_message'>{breadcrumb_text}</h5>
                </div>
            }
            <main className={`main ${custom_class}`}>
                <div className='d-flex gap-3'>
                    {button_name && <Link to={(`${button_path}?type=1`)} rel="noopener noreferrer" title={button_title} className={btn_class}>{button_name}</Link>}
                    {button_name2 && <Link to={(`${Paths.partscategory.c3_path}?type=2`)} rel="noopener noreferrer" title={Paths.partscategory.c3_name} className={btn_class}>{Paths.partscategory.c3_name}</Link>}
                </div>
                {children}
            </main>
        </div>
    )
}

export default LayoutWrapper