import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PartsMediaForm from "./PartsMediaForm";
import { Paths } from "../../routes/route";
import { fetchEditData } from "../../helpers/API/Edit";
const Edit = () => {
    const { part_id } = useParams();
    const API_URL = process.env.REACT_APP_API_URL;
    const [editData, setEditData] = useState();

    const title = Paths.partsmedia.c2_name;

    useEffect(() => {

        fetchEditData(API_URL + 'v1/parts/editPart?id=' + part_id, setEditData);

    }, [part_id]);

    return (
        <>
            <PartsMediaForm editform={1} editData={editData} title={title} />
        </>
    );
}

export default Edit;