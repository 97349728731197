import React, { useState, useEffect, useContext } from "react";
import { Col, Row, Form, Button, Alert } from 'react-bootstrap';
import LayoutWrapper from "../../components/UI/LayoutWrapper";
import DashboardCard from '../../components/UI/DashboardCard'
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Paths } from "../../routes/route";
import Select from 'react-select';
import ModuleEditScreenOwnerInfo from "../../components/ModuleEditScreenOwnerInfo";
import DataContext from "../../context/DataContext";
import { getOwnerInfo } from "../../framework/utilities.js/utilities";
import { handleSave } from "../../helpers/API/Save";

const UserForm = (props) => {
	const navigate = useNavigate();
	const { handleSubmit, register, setValue, formState: { errors } } = useForm();
	const API_URL = process.env.REACT_APP_API_URL;
	const { id } = useParams();
	const { auth } = useContext(DataContext);
	let userId = auth[0].user_profile.GID;
	const { editpage, editData, title } = props;

	const [userType, setUserType] = useState(2);
	const [type, setType] = useState(1);

	const [errMessage, setErrorMessage] = useState('');
	const [successMessage, setSuccessMessage] = useState('');
	const [visualStatus, setVisualStatus] = useState('1');
	const [startSave, setStartSave] = useState(false);

	const [userRoleId, setUserRoleId] = useState("");
	const [userRoleName, setUserRoleName] = useState("");
	const [userRoleOptions, setUserRoleOptions] = useState([]);

	const [createdAt, setCreatedAt] = useState("");
	const [updatedAt, setUpdatedAt] = useState("");
	const [createdBy, setCreatedBy] = useState("");
	const [updatedBy, setUpdatedBy] = useState("");


	useEffect(() => {
		setUserRoleId(editData?.user_role);
		setUserRoleName(editData?.user_role_label);
		const tmp = [];
		fetch(API_URL + '/v1/userroles/data/userRoleDrp', {
			method: 'POST',
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem("access_token"),
			}
		}).then(response => response.json())
			.then(jsondata => {
				var i = 0;
				Object.keys(jsondata).forEach(key => {
					if (i === 0 && !(id)) {
						setUserRoleId(key);
						setUserRoleName(jsondata[key]);
					}
					tmp.push({
						value: key,
						label: jsondata[key]
					});
					i++;
				});
				setUserRoleOptions(tmp);
			}).catch((err) => {
				// console.log("Login error: " + err);
			});
		// eslint-disable-next-line
	}, [editData?.user_role, editData?.user_role_label]);

	useEffect(() => {

		if (editData) {
			setValue("first_name", editData.first_name);
			setValue("last_name", editData.last_name);
			setValue("email", editData.email);
			setVisualStatus(editData.status);
			setUserType(editData.user_type);
			setCreatedAt(editData.created_at);
			setUpdatedAt(editData.updated_at);
			setCreatedBy(editData.created_by);
			setUpdatedBy(editData.updated_by);
		}

		if (!editpage) {
			setVisualStatus('1');
		}

	}, [editData?.first_name, editData?.last_name, editpage, editData?.email, editData?.status, editData?.user_type]);

	const ownerInfo = getOwnerInfo(createdAt, updatedAt, createdBy, updatedBy);

	// Saving
	const onSubmit = (data) => {

		setStartSave(true);

		let formData = new FormData();
		if (id !== undefined) {
			formData.append("GID", id);
		}
		formData.append("password", data.password);
		formData.append("first_name", data.first_name);
		formData.append("last_name", data.last_name);
		formData.append("email", data.email);
		formData.append("status", visualStatus || 0);
		formData.append("user_type", userType || 1);
		formData.append("user_role", userRoleId || 0);
		if (!editpage) {
			formData.append("created_by", userId);
		} else {
			formData.append("updated_by", userId);
		}

		handleSave(API_URL + '/v1/user/data/save', setSuccessMessage, Paths.users.path, setErrorMessage, setStartSave, formData, navigate);
	}

	return (
		<>
			<LayoutWrapper breadcrumb_title={Paths.users.name}
				form={true}
				label={Paths.users.name}
				linkto={Paths.users.path}
				title={title}>
				{errMessage && <Alert variant="danger">{errMessage}</Alert>}
				{successMessage && !errMessage && <Alert variant="success">{successMessage}</Alert>}
				<Row>
					<Col lg={12} className=''>
						<DashboardCard className='pt-4'>
							<h5 className="w-100 px-4 py-4 m-0">{title}</h5>
							<Form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" className="w-100 px-4 pb-4" >
								<Col xl={12} lg={12} md={12} className="mb-3">
									<Form.Group id="first_name">
										<Form.Label className="required ">First name<span style={{ color: 'red', marginLeft: '4px' }}>*</span></Form.Label>
										<Form.Control
											name="first_name"
											{...register("first_name", {
												required: 'First name is required',
												validate: {
													minLength: (v) => v.length >= 3 || 'First name should have at least 3 characters',
													matchPattern: (v) => /^[a-z, A-Z]+$/.test(v) || 'First name must contain only alphabetic characters',
												},
											})}
										/>
									</Form.Group>
									{errors.first_name?.message && (
										<small style={{ color: 'red' }}>{errors.first_name.message}</small>
									)}
								</Col>
								<Col xl={12} lg={12} md={12} className="mb-3">
									<Form.Group id="last_name">
										<Form.Label className="required ">Last name<span style={{ color: 'red', marginLeft: '4px' }}>*</span></Form.Label>
										<Form.Control
											name="last_name"
											{...register("last_name", {
												required: 'Last name is required',
												validate: {
													minLength: (v) => v.length >= 3 || 'Last name should have at least 3 characters',
													matchPattern: (v) => /^[a-z, A-Z]+$/.test(v) || 'First name must contain only alphabetic characters',
												},
											})}
										/>
									</Form.Group>
									{errors.last_name?.message && (
										<small style={{ color: 'red' }}>{errors.last_name.message}</small>
									)}
								</Col>
								<Col xl={12} lg={12} md={12} className="mb-3">
									<Form.Group id="email">
										<Form.Label className="required ">Email ID<span style={{ color: 'red', marginLeft: '4px' }}>*</span></Form.Label>
										<Form.Control
											type="text"
											name="email"
											{...register("email", {
												required: "Email is required",
												validate: {
													maxLength: (v) =>
														v.length <= 50 || "The email should have at most 50 characters",
													matchPattern: (v) =>
														/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
														"Email address must be a valid address",
												},
											})}
										/>
									</Form.Group>
									{errors.email?.message && (
										<small style={{ color: 'red' }}>{errors.email.message}</small>
									)}
								</Col>
								<Col md={12} className="mb-3">
									<Form.Group id="user_type">
										<Form.Label>User Type</Form.Label>
										<div className="d-block">
											<div className="d-inline-block me-4">
												<div className="d-inline-block pe-2">
													<input type="radio" id="user_type1" name="user_type" checked={userType == 2 ? true : false} value='2' onChange={(e) => {
														setUserType(e.target.value);
														setType(2);
													}} style={{ cursor: 'pointer' }}
													/>
												</div>
												<div className="d-inline-block">
													<label htmlFor="user_type1" style={{ cursor: 'pointer' }}>User</label>
												</div>
											</div>
											<div className="d-inline-block">
												<div className="d-inline-block pe-2">
													<input type="radio" id="user_type0" name="user_type" checked={userType == 1 ? true : false} value='1' onChange={(e) => {
														setUserType(e.target.value);
														setType(1);
													}} style={{ cursor: 'pointer' }}
													/>
												</div>
												<div className="d-inline-block">
													<label htmlFor="user_type0" style={{ cursor: 'pointer' }}>Admin</label>
												</div>
											</div>
										</div>
									</Form.Group>
								</Col>
								{(Number(type) === 2) || (Number(userType) === 2) ? <Col xl={3} lg={6} md={12} className="mb-3">
									<Form.Label>Role</Form.Label>
									<Form.Group id="user_role">
										<Select id="user_role" name="user_role" options={userRoleOptions} value={{ value: userRoleId, label: userRoleName }} onChange={event => {
											setUserRoleId(event.value);
											setUserRoleName(event.label);
											setValue("user_role", event.value);
										}}
										/>
									</Form.Group>
								</Col> : null}
								<Col md={6} className="mb-3">
									<Form.Group id="active">
										<Form.Label>Status</Form.Label>
										<div className="d-block">
											<div className="d-inline-block me-4">
												<div className="d-inline-block pe-2">
													<input type="radio" id="active1" name="active" label="Ja" checked={visualStatus === '1' ? true : false} value="1" onChange={(e) => {
														setVisualStatus(e.target.value);
													}} style={{ cursor: 'pointer' }}
													/>
												</div>
												<div className="d-inline-block">
													<label htmlFor="active1" style={{ cursor: 'pointer' }}>Active</label>
												</div>
											</div>
											<div className="d-inline-block">
												<div className="d-inline-block pe-2">
													<input type="radio" id="active0" name="active" label="Nein" checked={visualStatus === '0' ? true : false} value="0" onChange={(e) => {
														setVisualStatus(e.target.value);
													}} style={{ cursor: 'pointer' }}
													/>
												</div>
												<div className="d-inline-block">
													<label htmlFor="active0" style={{ cursor: 'pointer' }}>Inactive</label>
												</div>
											</div>
										</div>
									</Form.Group>
								</Col>
								{!startSave && <Col xl={12} lg={12} md={12} className="cancel_block">
									<div style={{ display: "flex" }} className="cancel_button">
										<Button className="list_button m-0" type="submit" >
											Save</Button>
										<Button
											className="list_button m-0"
											onClick={(e) => {
												e.preventDefault();
												navigate(`${Paths.users.path}`);
											}}
										>
											Cancel</Button>
									</div>
								</Col>}
								{editpage && <ModuleEditScreenOwnerInfo ownerInfo={ownerInfo} />}
							</Form>
						</DashboardCard>
					</Col>
				</Row>
			</LayoutWrapper>
		</>
	);
}

export default UserForm;