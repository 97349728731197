import React from "react";
import CategoryForm from "./Form";
import { useSearchParams } from "react-router-dom";
import { Paths } from '../../routes/route';

const Add = () => {

    const [queryParameters] = useSearchParams();

    return (
        <>
            <CategoryForm addform={1} type={1} title={(queryParameters.get("type") === '1') ? Paths.partscategory.c1_name : Paths.partscategory.c3_name} />
        </>
    );
}

export default Add;