import React, { useState, useEffect } from "react";
import Select from "react-select";

const Visualiser = () => {

    const [visualiser_list, setVisualiserlist] = useState(JSON.parse(localStorage.getItem("user_profile")).visualiser);
    const [options, setRowData] = useState([]);

    useEffect(() => {
        if (visualiser_list) {
            setRowData(visualiser_list);
        }
    }, [visualiser_list])

    if (!localStorage.getItem("viz_id")) {
        localStorage.setItem("viz_id", visualiser_list[0]['value']);
        localStorage.setItem("viz_lb", visualiser_list[0]['label']);
    }
    const [selectedOption, setSelectedOption] = useState({
        value: localStorage.getItem("viz_id"),
        label: localStorage.getItem("viz_lb"),
    });
    const handleOptionChange = (option) => {
        window.location.reload()
        localStorage.setItem("viz_id", '');
        localStorage.setItem("viz_lb", '');
        localStorage.setItem("viz_id", option.value);
        localStorage.setItem("viz_lb", option.label);
        setSelectedOption(option);

    };

    useEffect(() => {
        localStorage.setItem("myValueKey", JSON.stringify(selectedOption));
    }, [selectedOption]);

    return (
        <>
            <div style={{ width: '100%' }}>
                <Select
                    options={options}
                    value={selectedOption}
                    onChange={handleOptionChange}
                />
            </div>
        </>
    );
};

export default Visualiser;
