export const infoReducer = (state, action) => {
	switch (action.type) {
		case 'SET_VISUALIZER_ID':
			state[0].visualizer_id = !action.setvalue;
			return [...state]

		case 'SET_LOADER':
			state[0].loader = action.setvalue;
			return [...state]

		default:
			return state
	}
}