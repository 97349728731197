import React from "react";
import VisualizerForm from "./Form";
import { Paths } from "../../routes/route";
const Add = () => {

	const title = Paths.visualizer.c1_name;

	return (
		<>
			<VisualizerForm addform={1} title={title} />
		</>
	);
}

export default Add;