import React, { useState, useEffect, useContext } from "react";
import { Col, Row, Form, Button, Alert, Spinner } from 'react-bootstrap';
import LayoutWrapper from "../../components/UI/LayoutWrapper";
import DashboardCard from '../../components/UI/DashboardCard'
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useParams, useNavigate } from "react-router-dom";
import { getOwnerInfo } from "../../framework/utilities.js/utilities";
import ModuleEditScreenOwnerInfo from "../../components/ModuleEditScreenOwnerInfo";
import Uploader from "../../moduleBuilder/components/Uploader";
import { Paths } from "../../routes/route";
import DataContext from "../../context/DataContext";
import { useForm } from "react-hook-form";
import { handleSave } from "../../helpers/API/Save";

const LanguageForm = (props) => {
	const { handleSubmit, register, setValue, formState: { errors } } = useForm();
	const { auth } = useContext(DataContext);
	let userId = auth[0].user_profile.GID;
	const navigate = useNavigate();
	const API_URL = process.env.REACT_APP_API_URL;
	const { lang_id } = useParams();
	const lg = props?.lg || 12;
	const { editpage, editData, title } = props;

	const [langCode, setLangCode] = useState('');
	const [htmlTitle, setHtmlTitle] = useState("");
	const [metaDescription, setMetaDescription] = useState("");
	const [weightText, setWeightText] = useState("");
	const [termsAndCond, setTermsAndCond] = useState("");
	const [startSave, setStartSave] = useState(false);
	const [pdfUrl, setpdfUrl] = useState('');

	const [createdAt, setCreatedAt] = useState("");
	const [updatedAt, setUpdatedAt] = useState("");
	const [createdBy, setCreatedBy] = useState("");
	const [updatedBy, setUpdatedBy] = useState("");

	const ownerInfo = getOwnerInfo(createdAt, updatedAt, createdBy, updatedBy);

	const [visualStatus, setVisualStatus] = useState('');
	const [errMessage, setErrorMessage] = useState('');
	const [successMessage, setSuccessMessage] = useState('');

	// Uploader
	const [uploderState, setUploderState] = useState([]);
	const [fileUploadProgress, setFileUploadProgress] = useState(false);

	// Edited data
	useEffect(() => {
		if (editData) {
			setValue("lang_name", editData.lang_name);
			setLangCode(editData.lang_code);
			setHtmlTitle(editData.html_title);
			setMetaDescription(editData.meta_desc);
			setWeightText(editData.weight_text);
			setTermsAndCond(editData.terms_and_cond);
			setpdfUrl(editData.pdfurl);
			setVisualStatus(editData.active ? '1' : '0');
			setCreatedAt(editData.created_at);
			setUpdatedAt(editData.updated_at);
			setCreatedBy(editData.created_by);
			setUpdatedBy(editData.updated_by);
		}
		if (!editpage) {
			setVisualStatus('1');
		}
	}, [props]);

	// Selected configurator
	const storedValue = localStorage?.getItem('viz_id');

	// Saving
	const onSubmit = (data) => {
		setStartSave(true);
		setFileUploadProgress(true);
		setSuccessMessage('');
		setErrorMessage('');

		const fields = [
			{ name: 'lang_code', value: langCode },
			{ name: 'html_title', value: htmlTitle },
			{ name: 'meta_desc', value: metaDescription },
			{ name: 'weight_text', value: weightText },
			{ name: 'terms_and_cond', value: termsAndCond }
		];

		let formData = new FormData();
		formData.append("lang_name", data.lang_name);

		fields.forEach(field => {
			if (field.value !== undefined && field.value !== null) {
				formData.append(field.name, field.value);
			}
		});

		if (!editpage) {
			formData.append("created_by", userId);
		} else {
			formData.append("updated_by", userId);
		}

		formData.append("active", visualStatus);
		formData.append("visualizer_id", storedValue);

		for (let i = 0; i < uploderState.length; i++) {
			formData.append("FileData" + [i], uploderState[i]);
		}

		if (lang_id !== undefined) {
			formData.append("GID", lang_id);
		}

		handleSave(API_URL + '/v1/language/save', setSuccessMessage, Paths.languages.path, setErrorMessage, setStartSave, formData, navigate, setFileUploadProgress, setUploderState, 2);
	}

	return (
		<LayoutWrapper breadcrumb_title='Language module'
			form={true}
			label={Paths.languages.name}
			linkto={Paths.languages.path}
			title={title}>
			{errMessage && <Alert variant="danger">{errMessage}</Alert>}
			{successMessage && !errMessage && <Alert variant="success">{successMessage}</Alert>}
			<Row>
				<Col lg={lg} className=''>
					<DashboardCard className='pt-4'>
						<h5 className="w-100 px-4 py-4 m-0">{title}</h5>
						<Form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" className="w-100 px-4 pb-4" >
							<Row>
								<Col xl={12} lg={12} md={12} className="mb-3">
									<Form.Group id="lang_name">
										<Form.Label className="required ">Language name <span style={{ color: 'red', marginLeft: '4px' }}>*</span></Form.Label>
										<Form.Control
											{...register("lang_name", {
												required: 'Language name is required',
												validate: {
													minLength: (v) => v.length >= 3 || 'Language name should have at least 3 characters',
												},
											})}
										/>
									</Form.Group>
									{errors.lang_name?.message && (
										<small style={{ color: 'red' }}>{errors.lang_name.message}</small>
									)}
								</Col>
							</Row>
							<Row>
								<Col xl={12} lg={12} md={12} className="mb-3">
									<Form.Group id="lang_code">
										<Form.Label className="required ">Language code</Form.Label>
										<Form.Control name="lang_code" value={langCode} type="text" onChange={e => {
											setLangCode(e.target.value);
										}} />
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col xl={12} lg={12} md={12} className="mb-3">
									<Form.Group id="html_title">
										<Form.Label className="required ">HTML title</Form.Label>
										<Form.Control name="html_title" type="text" value={htmlTitle} onChange={e => {
											setHtmlTitle(e.target.value);
										}} />
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col xl={12} lg={12} md={12} className="mb-3">
									<Form.Group id="meta_desc">
										<Form.Label className="required">Meta description</Form.Label>
										<Form.Control name="meta_desc" type="text" value={metaDescription} onChange={e => {
											setMetaDescription(e.target.value);
										}} />
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col xl={12} lg={12} md={12} className="mb-3">
									<Form.Group id="weight_text">
										<Form.Label className="required">Weight Text</Form.Label>
										<Form.Control name="weight_text" type="text" value={weightText} onChange={e => {
											setWeightText(e.target.value);
										}} />
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col xl={12} lg={12} md={12} className="mb-3">
									<Form.Group id="terms_and_cond">
										<Form.Label className="required">Terms and Conditions</Form.Label>
										<Form.Control name="terms_and_cond" type="text" value={termsAndCond} onChange={e => {
											setTermsAndCond(e.target.value);
										}} />
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col>Order PDF: {pdfUrl && <a style={{ wordBreak: 'break-all' }} href={pdfUrl} target="_blank" rel="noreferrer">{pdfUrl}</a>}</Col>
								<Col xl={12} lg={4} md={12} className="mb-3">
									<Uploader maxFiles={10} uploderState={uploderState} setUploderState={setUploderState} accept={{ 'pdf/*': ['.pdf'] }} maxSize={20000000} maxSizeLabel='with size less than 20 MB' filesAcceptedTxt="You can only upload PDF file" />
									{((fileUploadProgress === true) && !errMessage) &&
										<>
											<Button variant="primary" disabled>
												<Spinner
													as="span"
													animation="grow"
													size="sm"
													role="status"
													aria-hidden="true"
												/>
												Uploading. Please wait...
											</Button>
										</>
									}
								</Col>
							</Row>
							<Row>
								<Col md={12} className="mb-3">
									<Form.Group id="active">
										<Form.Label>Status</Form.Label>
										<div className="d-block">
											<div className="d-inline-block me-4">
												<div className="d-inline-block pe-2">
													<input type="radio" id="active1" name="active" label="Ja" checked={visualStatus === '1' ? true : false} value="1" onChange={(e) => {
														setVisualStatus(e.target.value);
													}} style={{ cursor: 'pointer' }}
													/>
												</div>
												<div className="d-inline-block">
													<label htmlFor="active1" style={{ cursor: 'pointer' }}>Active</label>
												</div>
											</div>
											<div className="d-inline-block">
												<div className="d-inline-block pe-2">
													<input type="radio" id="active0" name="active" label="Nein" checked={visualStatus === '0' ? true : false} value="0" onChange={(e) => {
														setVisualStatus(e.target.value);
													}} style={{ cursor: 'pointer' }}
													/>
												</div>
												<div className="d-inline-block">
													<label htmlFor="active0" style={{ cursor: 'pointer' }}>Inactive</label>
												</div>
											</div>
										</div>
									</Form.Group>
								</Col>
							</Row>
							{!startSave && <Row>
								<Col xl={5} lg={12} md={12} className="cancel_block">
									<div style={{ display: "flex" }} className="cancel_button">
										<Button className="list_button m-0" type="submit" >
											Save</Button>
										<Button
											className="list_button m-0"
											onClick={(e) => {
												e.preventDefault();
												navigate(`${Paths.languages.path}`);
											}}
										>
											Cancel</Button>
									</div>
								</Col>
							</Row>}
							{editpage && <ModuleEditScreenOwnerInfo ownerInfo={ownerInfo} />}
						</Form>
					</DashboardCard>
				</Col>
			</Row>
		</LayoutWrapper>
	);
}

export default LanguageForm;