import { Navigate } from 'react-router-dom';

import Layout from '../Layout';
import Dashboard from '../pages/Dashboard';
import ResetPassword from '../pages/userAdmission/ResetPassword';

import AddUser from '../pages/user/Add';
import EditUser from '../pages/user/Edit';
import ListUser from '../pages/user/List';

import ListNodeConfig from '../pages/NodeConfiguration/List'
import AddNodeConfig from '../pages/NodeConfiguration/AddMain';
import EditNodeConfig from '../pages/NodeConfiguration/EditMain';
import AddSubNodeConfig from '../pages/NodeConfiguration/AddSub';
import EditSubNodeConfig from '../pages/NodeConfiguration/EditSub';

import AddVisualizer from '../pages/visualizer/Add';
import EditVisualizer from '../pages/visualizer/Edit';
import CloneVisualizer from '../pages/visualizer/Clone';
import ListVisualizer from '../pages/visualizer/List';
import ListCategory from '../pages/category/List';
import AddCategory from '../pages/category/Add';
import EditCategory from '../pages/category/Edit';

import ListPartsConfiguration from '../pages/PartsConfiguration/List';
import AddPartsConfiguration from '../pages/PartsConfiguration/Add';
import EditPartsConfiguration from '../pages/PartsConfiguration/Edit';

import ListCountry from '../pages/country/List';
import AddCountry from '../pages/country/Add';
import EditCountry from '../pages/country/Edit';

import AddLanguage from '../pages/Language/Add';
import EditLanguage from '../pages/Language/Edit';
import ListLanguage from '../pages/Language/List';

import AddHotspot from '../pages/hotspot/Add';
import EditHotspot from '../pages/hotspot/Edit';
import ListHotspot from '../pages/hotspot/List';

import AddColor from '../pages/color/Add';
import EditColor from '../pages/color/Edit';
import ListColor from '../pages/color/List';

import AddColorComp from '../pages/ColorComponent/Add';
import EditColorComp from '../pages/ColorComponent/Edit';
import ListColorComp from '../pages/ColorComponent/List';

import ListPartsMedia from '../pages/partsMedia/List'
import AddPartsMedia from '../pages/partsMedia/Add'
import EditPartsMedia from '../pages/partsMedia/Edit'

import ListTranslation from '../pages/translation/List';

import UserRoleList from '../pages/userRole/List';
import UserRoleAdd from '../pages/userRole/Add';
import UserRoleEdit from '../pages/userRole/Edit';

import Profile from '../pages/userAdmission/Profile';

import ChangePassword from '../pages/userAdmission/ChangePassword';

import PageNotFound from '../components/UI/PageNotFound';
import LoginLayout from '../pages/userAdmission/Login/LoginLayout';

function Protected({ children }) {

	if (!localStorage.getItem("isLoggedin")) {
		return <Navigate to="/login" replace />
	}

	const access = JSON.parse(localStorage.getItem("allowedURLs"));
	const url = window.location.pathname.split('/');
	const path = '/' + url[1] + (url[2] ? '/' + url[2] : '');

	if (!access.includes(path) && url[1]) {
		window.location.href = '/';
	}

	return children;
}

export const Paths = {
	Dashboard: "/dashboard",
	Profile: "/user-profile",
	changepassword: "/change-password",

	users: {
		name: "USERS",
		path: '/users',

		c1_name: 'ADD USER',
		c1_path: '/users/add',

		c2_name: 'EDIT USER',
		c2_path: '/users/edit/:id'
	},

	visualizer: {
		name: "VISUALISER CONFIGURATION",
		path: '/visualizer-configuration',

		c1_name: 'ADD VISUALISER',
		c1_path: '/visualizer-configuration/add',

		c2_name: 'EDIT VISUALISER',
		c2_path: '/visualizer-configuration/edit/:id',

		c3_name: 'CLONE VISUALISER',
		c3_path: '/visualizer-configuration/clone/:id'
	},

	partscategory: {
		name: "PARTS CATEGORY SET-UP",
		path: '/parts-category',

		c1_name: 'ADD PARENT CATEGORY',
		c1_path: '/parts-category/add',

		c2_name: 'EDIT CATEGORY',
		c2_path: '/parts-category/edit/:cat_id',

		c3_name: 'ADD SUB CATEGORY',
		c3_path: '/parts-category/add',

	},

	partsmedia: {
		name: "PARTS LIBRARY",
		path: '/parts-media',

		c1_name: 'ADD PARTS',
		c1_path: '/parts-media/add',

		c2_name: 'EDIT PARTS',
		c2_path: '/parts-media/edit/:part_id',

	},

	nodesconfiguration: {
		name: "MAIN UI SET-UP",
		path: '/nodes-configuration',

		c1_name: 'ADD MAIN UI SET-UP',
		c1_path: '/nodes-configuration/add',

		c2_name: 'EDIT MAIN UI SET-UP',
		c2_path: '/nodes-configuration/edit/:cat_id',

		c3_name: 'ADD SUB NODE',
		c3_path: '/nodes-configuration/add-sub',

		c4_name: 'EDIT SUB NODE',
		c4_path: '/nodes-configuration/edit-sub/:cat_id',

	},

	partsconfiguration: {
		name: "PART DEPENDENCIES",
		path: '/parts-configuration',

		c1_name: 'ADD PART DEPENDENCY',
		c1_path: '/parts-configuration/add',

		c2_name: 'EDIT PART DEPENDENCY',
		c2_path: '/parts-configuration/edit/:id',

	},

	colours: {
		name: "COLOUR LIBRARY",
		path: '/colours',

		c1_name: 'ADD COLOUR',
		c1_path: '/colours/add',

		c2_name: 'EDIT COLOUR',
		c2_path: '/colours/edit/:col_id',

	},

	colourconfiguration: {
		name: "COLOUR CONFIGURATION",
		path: '/colour-configuration',

		c1_name: 'ADD COLOUR CONFIGURATION',
		c1_path: '/colour-configuration/add',

		c2_name: 'EDIT COLOUR CONFIGURATION',
		c2_path: '/colour-configuration/edit/:id',

	},

	country: {
		name: "COUNTRY",
		path: '/country',

		c1_name: 'ADD COUNTRY',
		c1_path: '/country/add',

		c2_name: 'EDIT COUNTRY',
		c2_path: '/country/edit/:id',

	},

	languages: {
		name: "LANGUAGES",
		path: '/language',

		c1_name: 'ADD LANGUAGES',
		c1_path: '/language/add',

		c2_name: 'EDIT LANGUAGES',
		c2_path: '/language/edit/:lang_id',

	},

	translations: {
		name: "TRANSLATIONS",
		path: '/translation',

		c1_name: 'ADD TRANSLATION',

	},

	hotspot: {
		name: "HOTSPOTS",
		path: '/hotspot',

		c1_name: 'ADD HOTSPOT',
		c1_path: '/hotspot/add',

		c2_name: 'EDIT HOTSPOT',
		c2_path: '/hotspot/edit/:id',

	},

	userrole: {
		name: "USER ACCESS ROLES",
		path: '/user-role',

		c1_name: 'ADD USER ACCESS ROLE',
		c1_path: '/user-role/add',

		c2_name: 'EDIT USER ACCESS ROLE',
		c2_path: '/user-role/edit/:role_id',

	},

	reset: {
		name: "RESET PASSWORD",
		path: '/reset-password/:type/:token'
	},

	PageNotFound: "*",
	LoginLayout: "/login",

}

export default [
	{
		path: "/",
		element: <Layout />,
		children: [
			{
				index: true,
				element: <Protected><Dashboard /></Protected>
			},

			{
				path: Paths.Dashboard,
				element: <Protected><Dashboard /></Protected>,
				RouteName: "Dashboard",
				protected: true
			},

			{
				path: Paths.users.path,
				element: <Protected><ListUser /></Protected>,
				RouteName: "ListUser",
				protected: true
			},

			{
				path: Paths.users.c1_path,
				element: <Protected><AddUser /></Protected>,
				RouteName: "AddUser",
				protected: true
			},

			{
				path: Paths.users.c2_path,
				element: <Protected><EditUser /></Protected>,
				RouteName: "EditUser",
				protected: true
			},

			{
				path: Paths.visualizer.path,
				element: <Protected><ListVisualizer /></Protected>,
				RouteName: "ListVisualizer",
				protected: true
			},
			{
				path: Paths.visualizer.c1_path,
				element: <Protected><AddVisualizer /></Protected>,
				RouteName: "AddVisualizer",
				protected: true,
			},
			{
				path: Paths.visualizer.c2_path,
				element: <Protected><EditVisualizer /></Protected>,
				RouteName: "EditVisualizer",
				protected: true,
			},
			{
				path: Paths.visualizer.c3_path,
				element: <Protected><CloneVisualizer /></Protected>,
				RouteName: "CloneVisualizer",
				protected: true,
			},

			{
				path: Paths.partscategory.path,
				element: <Protected><ListCategory /></Protected>,
				RouteName: "ListCategory",
				protected: true,
			},
			{
				path: Paths.partscategory.c1_path,
				element: <Protected><AddCategory /></Protected>,
				RouteName: "AddCategory",
				protected: true,
			},
			{
				path: Paths.partscategory.c2_path,
				element: <Protected><EditCategory /></Protected>,
				RouteName: "EditCategory",
				protected: true,
			},

			{
				path: Paths.partsmedia.path,
				element: <Protected><ListPartsMedia /></Protected>,
				RouteName: "ListPartsMedia",
				protected: true,
			},
			{
				path: Paths.partsmedia.c1_path,
				element: <Protected><AddPartsMedia /></Protected>,
				RouteName: "AddPartsMedia",
				protected: true,
			},
			{
				path: Paths.partsmedia.c2_path,
				element: <Protected><EditPartsMedia /></Protected>,
				RouteName: "EditPartsMedia",
				protected: true,
			},

			{
				path: Paths.nodesconfiguration.path,
				element: <Protected><ListNodeConfig /></Protected>,
				RouteName: "ListNodeConfig",
				protected: true,
			},
			{
				path: Paths.nodesconfiguration.c1_path,
				element: <Protected><AddNodeConfig /></Protected>,
				RouteName: "AddNodeConfig",
				protected: true,
			},
			{
				path: Paths.nodesconfiguration.c2_path,
				element: <Protected><EditNodeConfig /></Protected>,
				RouteName: "EditNodeConfig",
				protected: true,
			},
			{
				path: Paths.nodesconfiguration.c3_path,
				element: <Protected><AddSubNodeConfig /></Protected>,
				RouteName: "AddSubNodeConfig",
				protected: true,
			},
			{
				path: Paths.nodesconfiguration.c4_path,
				element: <Protected><EditSubNodeConfig /></Protected>,
				RouteName: "EditSubNodeConfig",
				protected: true,
			},
			{
				path: Paths.partsconfiguration.path,
				element: <Protected><ListPartsConfiguration /></Protected>,
				RouteName: "ListConfigParts",
				protected: true,
			},
			{
				path: Paths.partsconfiguration.c1_path,
				element: <Protected><AddPartsConfiguration /></Protected>,
				RouteName: "AddConfigParts",
				protected: true,
			},
			{
				path: Paths.partsconfiguration.c2_path,
				element: <Protected><EditPartsConfiguration /></Protected>,
				RouteName: "EditConfigParts",
				protected: true,
			},

			{
				path: Paths.colours.path,
				element: <Protected><ListColor /></Protected>,
				RouteName: "ListColor",
				protected: true
			},
			{
				path: Paths.colours.c1_path,
				element: <Protected><AddColor /></Protected>,
				RouteName: "AddColor",
				protected: true
			},
			{
				path: Paths.colours.c2_path,
				element: <Protected><EditColor /></Protected>,
				RouteName: "EditColor",
				protected: true
			},

			{
				path: Paths.colourconfiguration.path,
				element: <Protected><ListColorComp /></Protected>,
				RouteName: "ListColorComp",
				protected: true,
			},
			{
				path: Paths.colourconfiguration.c1_path,
				element: <Protected>< AddColorComp /></Protected>,
				RouteName: "AddColorComp",
				protected: true,
			},
			{
				path: Paths.colourconfiguration.c2_path,
				element: <Protected><EditColorComp /></Protected>,
				RouteName: "EditColorComp",
				protected: true,
			},

			{
				path: Paths.country.path,
				element: <Protected><ListCountry /></Protected>,
				RouteName: "ListCountry",
				protected: true
			},
			{
				path: Paths.country.c1_path,
				element: <Protected><AddCountry /></Protected>,
				RouteName: "AddCountry",
				protected: true
			},
			{
				path: Paths.country.c2_path,
				element: <Protected><EditCountry /></Protected>,
				RouteName: "EditCountry",
				protected: true
			},

			{
				path: Paths.languages.path,
				element: <Protected><ListLanguage /></Protected>,
				RouteName: "ListLanguage",
				protected: true
			},
			{
				path: Paths.languages.c1_path,
				element: <Protected><AddLanguage /></Protected>,
				RouteName: "AddLanguage",
				protected: true
			},
			{
				path: Paths.languages.c2_path,
				element: <Protected><EditLanguage /></Protected>,
				RouteName: "EditLanguage",
				protected: true
			},

			{
				path: Paths.translations.path,
				element: <Protected><ListTranslation /></Protected>,
				RouteName: "ListTranslation",
				protected: true,
			},

			{
				path: Paths.hotspot.path,
				element: <Protected><ListHotspot /></Protected>,
				RouteName: "ListHotspot",
				protected: true
			},
			{
				path: Paths.hotspot.c1_path,
				element: <Protected><AddHotspot /></Protected>,
				RouteName: "AddHotspot",
				protected: true
			},
			{
				path: Paths.hotspot.c2_path,
				element: <Protected><EditHotspot /></Protected>,
				RouteName: "EditHotspot",
				protected: true
			},
			{
				path: Paths.Profile,
				element: <Protected><Profile /></Protected>,
				RouteName: "Profile",
				protected: true
			},
			{
				path: Paths.changepassword,
				element: <Protected><ChangePassword /></Protected>,
				RouteName: "ChangePassword",
				protected: true
			},

			{
				path: Paths.userrole.path,
				element: <Protected><UserRoleList /></Protected>,
				RouteName: "UserRoleList",
				protected: true,
			},
			{
				path: Paths.userrole.c1_path,
				element: <Protected><UserRoleAdd /></Protected>,
				RouteName: "UserRoleAdd",
				protected: true,
			},
			{
				path: Paths.userrole.c2_path,
				element: <Protected><UserRoleEdit /></Protected>,
				RouteName: "UserRoleEdit",
				protected: true,
			},

			{
				path: Paths.PageNotFound,
				element: <Protected><PageNotFound /></Protected>,
				RouteName: "PageNotFound",
				protected: true
			}
		]
	},
	{
		path: Paths.LoginLayout,
		element: <LoginLayout />,
		RouteName: "LoginLayout",
		protected: false
	},
	{
		path: Paths.reset.path,
		element: <ResetPassword />,
		RouteName: "Reset Password",
		protected: true,
	},
]