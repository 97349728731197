import React from "react";
import { Modal, Button } from 'react-bootstrap';
import { faTrash, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ConfirmDeleteModal = (props) => {
    let popType = props.popType || '0';
    return (
        <Modal
            show={props.modalShow}
            onHide={props.handleModelClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className={props.className}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.modalTitle ? props.modalTitle : 'Confirm deletion'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    {props.labelBtn ? props.labelBtn : <p>Do you really want to delete this entry "{props.delRecTitle}"? <span style={{ display: "block", fontWeight: "600", color: "red", padding: "5px 0 5px" }}><br />WARNING!</span> <ul><li>You will not be able to recover this item once deleted. Are you sure you want to delete?</li>{props.message && <li>{props.message}</li>}</ul></p>}
                </p>
                <p>
                    {props.labelBtnText}
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.handleModelClose} className="list_button modal_button" >
                    <FontAwesomeIcon icon={faTimes} className="animate-left-3 me-3 ms-2" />
                    {props.cancelBtn ? props.cancelBtn : 'Cancel'}
                </Button>
                {popType === "1" ? <Button onClick={props.handleOnDeleteSubmitted} className="list_button modal_button" >
                    <FontAwesomeIcon icon={faCheck} className="animate-left-3 me-3 ms-2" />
                    {props.saveBtn ? props.saveBtn : 'Delete'}
                </Button> : <Button onClick={props.handleOnDeleteSubmitted} className="list_button modal_button" >
                    <FontAwesomeIcon icon={faTrash} className="animate-left-3 me-3 ms-2" />
                    {props.saveBtn ? props.saveBtn : 'Delete'}
                </Button>}
            </Modal.Footer>
        </Modal>
    );
}

export default ConfirmDeleteModal;
