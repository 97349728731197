import React, { useState, useEffect, useContext } from "react";
import { Col, Row, Form, Button, Alert, Spinner, InputGroup } from 'react-bootstrap';
import LayoutWrapper from "../../components/UI/LayoutWrapper";
import DashboardCard from '../../components/UI/DashboardCard'
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Uploader from "../../moduleBuilder/components/Uploader";
import { useParams, useNavigate } from "react-router-dom";
import { getOwnerInfo } from "../../framework/utilities.js/utilities";
import Select from 'react-select';
import ModuleEditScreenOwnerInfo from "../../components/ModuleEditScreenOwnerInfo";
import { Paths } from "../../routes/route";
import DataContext from "../../context/DataContext";
import { useForm } from "react-hook-form";
import { handleSave } from "../../helpers/API/Save";
import { getData } from "../../helpers/API/Misc";

const HotspotForm = (props) => {
	const { handleSubmit, register, setValue, formState: { errors } } = useForm();
	const navigate = useNavigate();
	const API_URL = process.env.REACT_APP_API_URL;
	const { id } = useParams();
	const { auth } = useContext(DataContext);
	let userId = auth[0].user_profile.GID;
	const storedValue = localStorage?.getItem('viz_id');
	const { editpage, editData, title } = props;

	const [createdAt, setCreatedAt] = useState("");
	const [updatedAt, setUpdatedAt] = useState("");
	const [createdBy, setCreatedBy] = useState("");
	const [updatedBy, setUpdatedBy] = useState("");

	// Edited data
	useEffect(() => {
		if (editData) {
			setValue("hotspot_name", editData.hotspot_name);
			setPositionX(editData.position_x);
			setPositionY(editData.position_y);
			setPositionZ(editData.position_z);
			setHotspotTypeLabel(editData.hotspot_type);
			setHotspotContent(editData.hotspot_content);
			setHotspotUrl(editData.hotspot_url);
			setInitialHotspot(editData.initial_hotspot);
			setPartValue(editData.assign_parts);
			setCatImgUrl(editData.url);
			setStatus(editData.active ? '1' : '0');
			setCreatedAt(editData.created_at);
			setUpdatedAt(editData.updated_at);
			setCreatedBy(editData.created_by);
			setUpdatedBy(editData.updated_by);
			if (editData.hotspot_type === 'Text') {
				setInputActive(1);
			}
			if (editData.hotspot_type === 'Video') {
				setInputActive(2);
			}
			if (editData.hotspot_type === 'Images') {
				setInputActive(3);
			}
		}

		if (!editpage) {
			setStatus('1');
		}
	}, [props]);

	const [status, setStatus] = useState('');
	const [errMessage, setErrorMessage] = useState('');
	const [successMessage, setSuccessMessage] = useState('');

	const [positionX, setPositionX] = useState(0);
	const [positionY, setPositionY] = useState(0);
	const [positionZ, setPositionZ] = useState(0);
	const [hotspotTypeValue, setHotspotTypeValue] = useState('');
	const [hotspotTypeLabel, setHotspotTypeLabel] = useState('');
	const [hotspotContent, setHotspotContent] = useState('');
	const [hotspotUrl, setHotspotUrl] = useState('');
	const [initialHotspot, setInitialHotspot] = useState('1');
	const [startSave, setStartSave] = useState(false);
	const [partValue, setPartValue] = useState([]);
	const [partData, setPartData] = useState([]);
	const [inputActive, setInputActive] = useState('');
	const [catImgUrl, setCatImgUrl] = useState('');
	const hotspotTypeData = [
		{ value: '0', label: '' },
		{ value: '1', label: 'Text' },
		{ value: '2', label: 'Video' },
		{ value: '3', label: 'Images' }
	];

	const ownerInfo = getOwnerInfo(createdAt, updatedAt, createdBy, updatedBy);

	// Parts dropdown
	useEffect(() => {

		// Parts dropdown
		getData(API_URL + '/v1/parts/partsDropDownList1?visualizer_id=' + storedValue, 'GET', '', 2, setPartData);

	}, []);


	// Uploader
	const [uploderState, setUploderState] = useState([]);
	const [fileUploadProgress, setFileUploadProgress] = useState(false);

	// Saving
	const onSubmit = (data) => {
		setStartSave(true);
		const fields = [
			{ name: 'hotspot_type', value: hotspotTypeLabel },
			{ name: 'hotspot_content', value: hotspotContent },
			{ name: 'hotspot_url', value: hotspotUrl },
			{ name: 'assign_parts', value: JSON.stringify(partValue) }
		];
		setErrorMessage("");
		setSuccessMessage("");
		setFileUploadProgress(true);
		let formData = new FormData();

		for (let i = 0; i < uploderState.length; i++) {
			formData.append("FileData" + [i], uploderState[i]);
			formData.append("counter", uploderState[i].counter);
		}

		formData.append("hotspot_name", data.hotspot_name);
		formData.append("position_x", positionX || 0);
		formData.append("position_y", positionY || 0);
		formData.append("position_z", positionZ || 0);
		formData.append("initial_hotspot", initialHotspot || 0);
		formData.append("active", status);
		formData.append("visualizer_id", storedValue);

		if (!editpage) {
			formData.append("created_by", userId);
		} else {
			formData.append("updated_by", userId);
		}

		fields.forEach(field => {
			if (field.value !== undefined && field.value !== null) {
				formData.append(field.name, field.value);
			}
		});

		if (id !== undefined) {
			formData.append("GID", id);
		}

		handleSave(API_URL + '/v1/hotspot/saveHotspot', setSuccessMessage, Paths.hotspot.path, setErrorMessage, setStartSave, formData, navigate, setFileUploadProgress, setUploderState, 2);
	}

	return (<>
		<LayoutWrapper breadcrumb_title='Hotspot module'
			form={true}
			label={Paths.hotspot.name}
			linkto={Paths.hotspot.path}
			title={title}>
			{errMessage && <Alert variant="danger">{errMessage}</Alert>}
			{successMessage && !errMessage && <Alert variant="success">{successMessage}</Alert>}
			<Row>
				<Col lg='12' className=''>
					<DashboardCard className='pt-4'>
						<h5 className="w-100 px-4 py-4 m-0">{title}</h5>
						<Form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" className="w-100 px-4 pb-4" >
							<Col xl={12} lg={12} md={12} className="mb-3">
								<Form.Group id="hotspot_name">
									<Form.Label className="required ">Hotspot name <br />
										{<span class="text-danger" style={{ 'font-size': '12px' }}>! Please add this Hotspot name in the tranlations section, otherwise the Hotspot name will not work </span>}</Form.Label>
									<Form.Control
										{...register("hotspot_name", {
											required: 'Hotspot name is required'
										})}
									/>
								</Form.Group>
								{errors.hotspot_name?.message && (
									<small style={{ color: 'red' }}>{errors.hotspot_name.message}</small>
								)}
							</Col>
							<Col xl={12} lg={12} md={12} className="mb-3">
								<InputGroup className="mb-3">
									<InputGroup.Text>Position</InputGroup.Text>
									<InputGroup.Text>left/right</InputGroup.Text><Form.Control aria-label="x"
										name="position_x"
										type="number"
										value={positionX}
										onChange={(e) => {
											setPositionX(e.target.value);
										}}
									/>
									<InputGroup.Text>up/down</InputGroup.Text> <Form.Control aria-label="y"
										name="position_y"
										type="number"
										value={positionY}
										onChange={(e) => {
											setPositionY(e.target.value);
										}}
									/>
									<InputGroup.Text>forward/backward</InputGroup.Text> <Form.Control aria-label="z"
										name="position_z"
										type="number"
										value={positionZ}
										onChange={(e) => {
											setPositionZ(e.target.value);
										}}
									/>
								</InputGroup>
							</Col>
							<Col xl={12} lg={12} md={12} className="mb-3">
								<Form.Group id="type">
									<Form.Label className="required "> Text <br />
										{<span class="text-danger" style={{ 'font-size': '12px' }}>! Please add this Hotspot text in the tranlations section, otherwise the Hotspot text will not work </span>}
									</Form.Label>
									<Form.Control as="textarea" rows={3}
										name="hotspot_type"
										value={hotspotContent}
										onChange={(e) => {
											setHotspotContent(e.target.value);
										}}
									/>
								</Form.Group>
							</Col>
							<Col xl={12} lg={4} md={12} className="mb-3">
								<Form.Group id="component">
									<Form.Label>Type</Form.Label>
									<Select
										isSearchable
										name="hotspot_type"
										options={hotspotTypeData}
										value={{ value: hotspotTypeValue, label: hotspotTypeLabel }}
										onChange={(data) => {
											setHotspotTypeValue(data.value);
											setHotspotTypeLabel(data.label);
											if (data.value === '1') {
												setInputActive(1);
											} else if (data.value === '2') {
												setInputActive(2);
											} else if (data.value === '3') {
												setInputActive(3);
											} else {
												setInputActive('');
											}
										}}
									/>
								</Form.Group>
							</Col>
							{inputActive === 3 &&
								<Row>
									<Col>Image: {catImgUrl && <a href={catImgUrl} target="_blank" rel="noreferrer">{catImgUrl}</a>}</Col>
									<Col xl={12} lg={4} md={12} className="mb-3">
										<Uploader maxFiles={3} uploderState={uploderState} setUploderState={setUploderState} counter={1} maxSize={1000000} maxSizeLabel='with size less than 10 MB' />
										{((fileUploadProgress === true) && !errMessage) &&
											<>
												<Button variant="primary" disabled>
													<Spinner
														as="span"
														animation="grow"
														size="sm"
														role="status"
														aria-hidden="true"
													/>
													Uploading. Please wait...
												</Button>
											</>
										}
									</Col>
								</Row>
							}
							{inputActive === 2 && <Col xl={12} lg={12} md={12} className="mb-3">
								<Form.Group id="hotspot_name">
									<Form.Label className="required ">Url</Form.Label>
									<Form.Control
										name="hotspot_name"
										type="url"
										value={hotspotUrl}
										onChange={(e) => {
											setHotspotUrl(e.target.value);
										}}
									/>
								</Form.Group>
							</Col>}
							<Col xl={12} lg={12} md={12} className="mb-3">
								<Form.Group id="type">
									<div className="d-inline-block me-4">
										<div className="d-inline-block pe-4" >
											<input type="checkbox" id="initial_hotspot" name="initial_hotspot" checked={Number(initialHotspot) === 1 ? true : false} onChange={(e) => {
												const val = e.target.checked === true ? 1 : 0;
												setInitialHotspot(val);
											}} style={{ cursor: 'pointer' }}
											/>
										</div>
										<div className="d-inline-block">
											<label htmlFor="initial_hotspot" style={{ cursor: 'pointer' }}> Initial hotspot </label>
										</div>
									</div>
								</Form.Group>
							</Col>
							{!initialHotspot && <Col xl={12} lg={12} md={12} className="mb-3">
								<Form.Label className="required">Parts</Form.Label>
								<Form.Group id="Part">
									<Select
										isMulti
										isSearchable
										name="Part"
										options={partData}
										value={partValue}
										onChange={(data) => setPartValue(data)}
									/>
								</Form.Group>
							</Col>}
							<Col md={12} className="mb-3">
								<Form.Group id="active">
									<Form.Label>Status</Form.Label>
									<div className="d-block">
										<div className="d-inline-block me-4">
											<div className="d-inline-block pe-2">
												<input type="radio" id="active1" name="active" label="" checked={status === '1' ? true : false} value="1" onChange={(e) => {
													setStatus(e.target.value);
												}} style={{ cursor: 'pointer' }}
												/>
											</div>
											<div className="d-inline-block">
												<label htmlFor="active1" style={{ cursor: 'pointer' }}>Active</label>
											</div>
										</div>
										<div className="d-inline-block">
											<div className="d-inline-block pe-2">
												<input type="radio" id="active0" name="active" label="" checked={status === '0' ? true : false} value="0" onChange={(e) => {
													setStatus(e.target.value);
												}} style={{ cursor: 'pointer' }}
												/>
											</div>
											<div className="d-inline-block">
												<label htmlFor="active0" style={{ cursor: 'pointer' }}>Inactive</label>
											</div>
										</div>
									</div>
								</Form.Group>
							</Col>
							{!startSave && <Row>
								<Col xl={5} lg={12} md={12} className="cancel_block">
									<div style={{ display: "flex" }} className="cancel_button">
										<Button className="list_button m-0" type="submit" >
											Save</Button>
										<Button
											className="list_button m-0"
											onClick={(e) => {
												e.preventDefault();
												navigate(`${Paths.hotspot.path}`);
											}}
										>
											Cancel</Button>
									</div>
								</Col>
							</Row>}
							{editpage && <ModuleEditScreenOwnerInfo ownerInfo={ownerInfo} />}
						</Form>
					</DashboardCard>
				</Col>
			</Row>
		</LayoutWrapper>
	</>
	);
}

export default HotspotForm;