import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import LanguageForm from "./Form";
import { Paths } from "../../routes/route";
import { fetchEditData } from "../../helpers/API/Edit";

const Edit = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const { lang_id } = useParams();

    const [editData, setEditData] = useState();

    const title = Paths.languages.c2_name;

    useEffect(() => {

        fetchEditData(API_URL + 'v1/language/editLanguage?id=' + lang_id, setEditData);

    }, [lang_id]);

    return (
        <>
            <LanguageForm editpage={1} editData={editData} lg={11} title={title} />
        </>
    );
}

export default Edit;