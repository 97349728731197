import { Card, Alert } from 'react-bootstrap';
import ConfirmDeleteModal from '../../components/ConfirmDeleteModal';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import React, { useEffect, useState } from "react";
import edit from "../../assets/images/edit.svg";
import delete_icon from "../../assets/images/delete.svg";
import { Link, useLocation } from "react-router-dom";
import Grid from '../../components/Grid';
import LayoutWrapper from '../../components/UI/LayoutWrapper';
import { handleOpenInNewTab, naviLinks } from '../../framework/utilities.js/utilities';
import { Paths } from '../../routes/route';
import AddButton from '../../components/AddButton';
import { deleteBtn, handleOnDeleteSubmitted } from '../../helpers/API/Delete';
import { listData } from '../../helpers/API/List';

const List = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const [gridApi, setGridApi] = useState(null);
    const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row

    let [modalShow, setModalShow] = useState(false);
    let [delRecTitle, setDelRecTitle] = useState('');
    let [delRecId, setDelRecId] = useState(0);

    //code to fetch title based on URL
    const { pathname } = useLocation();
    const naviArr = naviLinks(pathname);
    const createPerm = naviArr['edit_perm'];
    const deletePerm = naviArr['delete_perm'];
    const readPerm = naviArr['add_perm'];
    const [successMessage, setSuccessMessage] = useState('');

    // Each Column Definition results in one Column.
    const [columnDefs] = useState([
        { headerName: "Colour name", field: 'name', filter: true, rowDrag: true, flex: 2, resizable: true },
        { headerName: "Surface finish", field: 'surface_finish', filter: true, resizable: true, flex: 1 },
        {
            headerName: "Status", field: "active", sortable: true, filter: true, flex: 1, minWidth: 100, maxWidth: 110
        },
        {
            headerName: "", field: "id", flex: 1, minWidth: 50, maxWidth: 50, headerClass: "ag-theme-custom-text-center", cellClass: "pm-grid-number-cell ag-theme-custom-content-center",
            cellRenderer: (params) => <div className="ag-theme-content-text-center">
                {createPerm === 1 ?
                    <Link onClick={handleOpenInNewTab} to={(`${Paths.colours.c2_path.split(":").slice(0, -1)[0]}` + params.value)} rel="noopener noreferrer" title="Edit" className="grid-action-btns text-success link"><img src={edit} alt="edit" className="me-2" style={{ width: '24px', height: '24px' }} /></Link>
                    : null}
            </div>
        },
        {
            headerName: "", field: "id", flex: 1, minWidth: 50, maxWidth: 50, headerClass: "ag-theme-custom-text-center", cellClass: "pm-grid-number-cell ag-theme-custom-content-center",
            cellRenderer: (params) => <div className="ag-theme-content-text-center">

                {deletePerm === 1 ? <span onClick={() => deleteBtn(params, 'name', setDelRecId, setDelRecTitle, setModalShow)} title="Delete" className="grid-action-btns delete-icon-color link"><img src={delete_icon} alt="delete" className="me-2" style={{ width: '24px', height: '24px' }} /></span> : null}
            </div>
        }

    ]);

    const onDelete = () => {
        handleOnDeleteSubmitted('/v1/color/deleteColor', setSuccessMessage, '', setModalShow, setDelRecId, setDelRecTitle, '', delRecId, gridApi, 2);
    }

    // Example load data from sever
    useEffect(() => {

        listData(API_URL + '/v1/color/colorList', setRowData);

    }, []);

    return (
        <>
            <LayoutWrapper
                modal={<ConfirmDeleteModal modalShow={modalShow} handleModelClose={() => { setModalShow(false); }} delRecTitle={delRecTitle} handleOnDeleteSubmitted={onDelete} />}
                heading={Paths.colours.name}
                breadcrumb_text='All existing colours for all visualisers can be found here. Option to add, edit or remove
                colours. When you click through to a specific colour, you have the option to amend the
                colour, material properties, surface finish, tooltip text and the icon for the colours in the
                main UI and summary. For textures, you can amend the repetition of the uploaded texture
                file.'
            >
                <Card.Body>
                    <AddButton
                        className="custom-button"
                        button_name={readPerm === 1 ? Paths.colours.c1_name : null}
                        button_path={Paths.colours.c1_path}
                        button_title={Paths.colours.c1_name}
                        btn_class="list_button"
                    />
                    {successMessage && <Alert variant="success">{successMessage}</Alert>}
                    {(rowData !== undefined) && <Grid gridData={rowData} columnDefs={columnDefs} url={'v1/color/updateOrder'} />}
                </Card.Body>
            </LayoutWrapper>
        </>
    );
}

export default List;