import React, { useContext, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import sunrise_video from "../../assets/videos/about-sunrise.mp4";
import logo from "../../assets/images/Logo.svg";
import { faMoon, faSun, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../userAdmission/Login/LoginLayout.scss";
import { Paths } from "../../routes/route";
import DataContext from "../../context/DataContext";
import { handleLogout } from "../../framework/utilities.js/utilities";

const ResetPassword = () => {
  const { token, type } = useParams();
  const navigate = useNavigate();
  const { dispatchAuth } = useContext(DataContext);
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [error, setError] = useState("");

  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const goBackToLogin = () => {
    setShowForgotPassword(false);
  };


  const switchHandler = () => {
    document.querySelector("body").classList.toggle("dark");

    if (document.querySelector("body").classList.contains("dark")) {
      document.querySelector("body").querySelector(".mode-text1").innerText =
        "Dark mode";
    } else {
      document.querySelector("body").querySelector(".mode-text1").innerText =
        "Light mode";
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const API_URL = process.env.REACT_APP_API_URL;

    let formData = new FormData();
    formData.append("password", password);
    formData.append("password_confirmation", password2);
    formData.append("token", token);

    fetch(API_URL + "v1/user/password/reset", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((jsondata) => {
        if (type == 1) {
          setError(jsondata.message + ' You will be redirected to the login page.');
          setTimeout(function () {
            setError('');
            navigate(`${Paths.LoginLayout}`);
          }, 6000);
        } else {
          setError('Your password has been set successfully. You will be redirected to the login page.');
          setTimeout(function () {
            setError('');
            handleLogout(dispatchAuth);
            navigate(`${Paths.LoginLayout}`);
          }, 6000);
        }
      })
      .catch((err) => {
        console.log("Forgot password error: " + err);
      });
  };

  return (
    <>
      <div className="mode login-dark-mode">
        <div className="sun-moon">
          {showForgotPassword && (
            <div
              className="icon"
              onClick={goBackToLogin}
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faArrowLeft} className="left-arrow-icon" />
            </div>
          )}
          <div
            className="icon"
            onClick={switchHandler}
            style={{ cursor: "pointer" }}
          >
            <FontAwesomeIcon icon={faMoon} className="dark-moon" />
            <FontAwesomeIcon icon={faSun} className="light-sun" />
          </div>
        </div>
      </div>
      <div className="login_main">
        <div className="bg_video">
          <div className="video-container">
            <video id="background_video" width="100%" autoPlay loop muted>
              <source src={sunrise_video} type="video/mp4" />
            </video>
            <div className="content caption">
              <img src={logo} alt="sunrise logo" />
              <h1>
                Visualiser <span>Management</span> System
              </h1>
            </div>
          </div>
        </div>

        <div className="login_form_block">
          <div className="intro_text">
            <h3>Reset Password</h3>
          </div>
          <div className="w-50">
            <form onSubmit={handleSubmit}>
              <div className="form-outline mb-3">
                <label className="form-label" htmlFor="form3Example4">
                  Password
                </label>
                <input
                  type="password"
                  id="form3Example4"
                  className="form-control form-control-lg"
                  placeholder="Enter password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  value={password}
                />
              </div>
              <div className="form-outline mb-3">
                <label className="form-label" htmlFor="form3Example4">
                  Confirm Password
                </label>
                <input
                  type="password"
                  id="form3Example4"
                  className="form-control form-control-lg"
                  placeholder="Enter password"
                  onChange={(e) => {
                    setPassword2(e.target.value);
                  }}
                />
              </div>

              {error}
              <div className="text-center text-lg-start mt-4 pt-2">
                <button type="submit" className="list_button">
                  Reset Password
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="login_form_block">
        <div className="intro_text">
          <h3>{type == 1 ? 'Reset Password' : 'Set Password'}</h3>
        </div>
        <div className="w-50">
          <form onSubmit={handleSubmit}>
            <div className="form-outline mb-3">
              <label className="form-label" htmlFor="form3Example4">
                Password
              </label>
              <input
                type="password"
                id="form3Example4"
                className="form-control form-control-lg"
                placeholder="Enter password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                value={password}
              />
            </div>
            <div className="form-outline mb-3">
              <label className="form-label" htmlFor="form3Example4">
                Confirm Password
              </label>
              <input
                type="password"
                id="form3Example4"
                className="form-control form-control-lg"
                placeholder="Enter password"
                onChange={(e) => { setPassword2(e.target.value) }}
              />
            </div>

            {error}
            <div className="text-center text-lg-start mt-4 pt-2">
              <button type="submit" className="list_button">
                {type == 1 ? 'Reset Password' : 'Set Password'}
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="mobile-screen">
        <h2 className="fw-bold mb-0 text-center text-white">
          Please use ipad or desktop
        </h2>
      </div>
    </>
  );
};

export default ResetPassword;
