import React, { useState } from "react";

const ForgotPassword = () => {
  const [user, setUser] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    const API_URL = process.env.REACT_APP_API_URL;

    let formData = new FormData();
    formData.append("email", user);

    fetch(API_URL + "v1/user/password/request", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((jsondata) => {
        setError(jsondata.message);
      })
      .catch((err) => {
        console.log("Forgot password error: " + err);
      });
  };

  return (
    <>
      <div className="intro_text">
        <h3>Forgot Password</h3>
        <p>
          Enter the email address associated with your account. We'll send you a link to reset your password.
        </p>
      </div>
      <div className="w-75">
        <form onSubmit={handleSubmit}>
          <div className="form-outline mb-4">
            <label className="form-label" htmlFor="form3Example3">
              Email address
            </label>
            <input
              type="email"
              id="form3Example3"
              className="form-control form-control-lg"
              placeholder="Enter a valid email address"
              onChange={(e) => {
                setUser(e.target.value);
              }}
              value={user}
            />
          </div>

          {error}
          <div className="text-center text-lg-start mt-4 pt-2">
            <button type="submit" className="list_button">
              Forgot Password
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ForgotPassword;
