import React from 'react'

import './DashboardCard.scss';

const DashboardCard = (props) => {
    return (
        <div className={`dashboard-card ${props.className}`}>{props.children}</div>
    )
}

export default DashboardCard