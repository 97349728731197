import { scrollToTop } from "../../framework/utilities.js/utilities";

export const handleSave = (url = '', setSuccessMessage, path = '/', setErrorMessage, setStartSave, formData, navigate, setFileUploadProgress, setUploderState, type = 1, setFileUploadProgress1, setUploderState2, setHexCode, setStatus, dispatchInfo, setState, state) => {

    // type 2 is for some of the modules that have an uploader
    // type 3 is for module with 2 uploaders (colour)
    // type 4 is only for surface finish
    // type 5 is only for translation

    fetch(url, {
        method: 'POST',
        body: formData,
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem("access_token"),
        },
    })
        .then(response => response.json())
        .then(jsondata => {
            if (type === 2 || type === 3) setUploderState([]);
            if (type === 3) setUploderState2([]);
            if ((type === 3 && jsondata.message === 'This colour name already exists.') || jsondata.message.includes('visualiser')) {
                setStartSave(false);
                scrollToTop();
                setErrorMessage(jsondata.message);
                setTimeout(function () {
                    setErrorMessage('');
                }, 2000);
            } else if (type === 4) {
                if (url.includes('editSurfaceFinish')) return;
                setState(!state);
            } else if (type === 5) {
                scrollToTop();
                if (url.includes('addTranslation')) setState(!state);
                setSuccessMessage(jsondata.message);
                setTimeout(function () {
                    setSuccessMessage('');
                }, 2000);
                setErrorMessage('');
            } else if (jsondata.GID) {
                scrollToTop();
                setSuccessMessage(jsondata.message);
                setTimeout(() => {
                    setSuccessMessage('');
                    if (type === 3) {
                        setHexCode('');
                        setStatus(1);
                    }
                    navigate(`${path}`);
                }, 2000);
                setErrorMessage('');
            } else {
                setStartSave(false);
                scrollToTop();
                setErrorMessage('Something went wrong');
                setTimeout(() => {
                    setErrorMessage('');
                }, 2000);
            }
            if (dispatchInfo) dispatchInfo({ type: 'SET_LOADER', setvalue: false });
            if (type === 2 || type === 3) setFileUploadProgress(false);
            if (type === 3) setFileUploadProgress1(false);
        })
        .catch((err) => {
            setStartSave(false);
            if (type === 2 || type === 3) {
                setUploderState([]);
                setFileUploadProgress(false);
                if (type === 3) {
                    setUploderState2([]);
                    setFileUploadProgress1(false);
                }
                setErrorMessage('File could not be saved. Please try after some time.');
            }
        });

};