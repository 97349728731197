import React from "react";
import PartsConfigForm from "./Form";
import { Paths } from "../../routes/route";
const Add = () => {

	const title = Paths.partsconfiguration.c1_name;

	return (
		<>
			<PartsConfigForm addform={1} title={title} />
		</>
	);
}

export default Add;