import React from "react";
import LanguageForm from "./Form";
import { Paths } from "../../routes/route";
const Add = () => {

    const title = Paths.languages.c1_name;

    return (
        <>
            <LanguageForm addform={1} title={title} />
        </>
    );
}

export default Add;