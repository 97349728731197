import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import UserForm from "./Form";
import { Paths } from "../../routes/route";
import { fetchEditData } from "../../helpers/API/Edit";
const Edit = () => {
	const API_URL = process.env.REACT_APP_API_URL;
	const { id } = useParams();
	const [editData, setEditData] = useState([]);
	const title = Paths.users.c2_name;

	useEffect(() => {

		fetchEditData(API_URL + 'v1/user/edit?id=' + id, setEditData);

	}, [id]);

	return (
		<>
			<UserForm editpage={1} editData={editData} title={title} />
		</>
	);
}

export default Edit;