export const fetchEditData = (url = '', setEditData, method = 'GET') => {

    fetch(url, {
        method: method,
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem("access_token"),
        },
    })
        .then(result => result.json())
        .then((editData) => {
            setEditData(editData.configurator[0]);
        })
}
