import { Card, Alert } from 'react-bootstrap';
import ConfirmDeleteModal from '../../components/ConfirmDeleteModal';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import React, { useEffect, useState } from "react";
import edit from "../../assets/images/edit.svg";
import delete_icon from "../../assets/images/delete.svg";
import { Link, useLocation } from 'react-router-dom';
import LayoutWrapper from '../../components/UI/LayoutWrapper';
import UnorderedGrid from '../../components/UnorderedGrid';
import { Paths } from '../../routes/route';
import { naviLinks } from '../../framework/utilities.js/utilities';
import AddButton from '../../components/AddButton';
import { deleteBtn, handleOnDeleteSubmitted } from '../../helpers/API/Delete';
import { listData } from '../../helpers/API/List';

const List = () => {
	const API_URL = process.env.REACT_APP_API_URL;
	const [gridApi, setGridApi] = useState(null);
	const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row

	// Code to fetch title based on URL
	const { pathname } = useLocation();
	const naviArr = naviLinks(pathname);
	const createPerm = naviArr['edit_perm'];
	const deletePerm = naviArr['delete_perm'];
	const readPerm = naviArr['add_perm'];
	const [userDeleted, setUserDeleted] = useState(false);
	const [successMessage, setSuccessMessage] = useState('');

	let [modalShow, setModalShow] = useState(false);
	let [delRecTitle, setDelRecTitle] = useState('');
	let [delRecId, setDelRecId] = useState(0);

	// Each Column Definition results in one Column.
	const [columnDefs] = useState([
		{ headerName: "First name", field: 'first_name', filter: true, sortable: true, resizable: true, flex: 1.25 },
		{ headerName: "Last name", field: 'last_name', filter: true, sortable: true, resizable: true, flex: 1.25 },
		{ headerName: "Email", field: 'email', filter: true, sortable: true, resizable: true, flex: 1.25 },
		{ headerName: "Status", field: "status", sortable: true, filter: true, minWidth: 100, maxWidth: 110 },
		{
			headerName: "", field: "id", minWidth: 50, maxWidth: 50, headerClass: "ag-theme-custom-text-center", cellClass: "pm-grid-number-cell ag-theme-custom-content-center",
			cellRenderer: (params) => <div className="ag-theme-content-text-center">
				{createPerm === 1 ? <Link onClick={handleOpenInNewTab} to={(`${Paths.users.c2_path.split(":").slice(0, -1)[0]}` + params.value)} title="Edit" className="grid-action-btns text-success link">
					<img src={edit} alt="edit" className="" style={{ width: '24px', height: '24px' }} /></Link> : null}

			</div>
		},
		{
			headerName: "", field: "id", minWidth: 50, maxWidth: 50, headerClass: "ag-theme-custom-text-center", cellClass: "pm-grid-number-cell ag-theme-custom-content-center",
			cellRenderer: (params) => <div className="ag-theme-content-text-center">

				{deletePerm === 1 ? <span onClick={() => deleteBtn(params, 'first_name', setDelRecId, setDelRecTitle, setModalShow)} title="Delete" className="grid-action-btns delete-icon-color link">
					<img src={delete_icon} alt="delete" className="" style={{ width: '24px', height: '24px' }} /></span> : null}
			</div>
		}

	]);

	const handleOpenInNewTab = (event) => {
		if (event.button === 0) {
			// Left mouse button clicked
			// Open link in the same tab
			return;
		}
		const link = document.createElement('a');
		link.href = event.currentTarget.href;
		link.target = '_blank';
		link.click();
	}

	const onGridReady = (params) => {
		setGridApi(params.api);

	};

	const onDelete = () => {
		handleOnDeleteSubmitted('/v1/user/data/delete/' + delRecId, setSuccessMessage, setUserDeleted, setModalShow, setDelRecId, setDelRecTitle, userDeleted, delRecId, gridApi);
	}

	// Example load data from sever
	useEffect(() => {

		listData(API_URL + 'v1/user/data/list', setRowData);

	}, [userDeleted]);

	return (
		<>
			<LayoutWrapper
				modal={<ConfirmDeleteModal modalShow={modalShow} handleModelClose={() => { setModalShow(false); }} delRecTitle={delRecTitle} handleOnDeleteSubmitted={onDelete} />}
				heading={Paths.users.name}
				breadcrumb_text='Create, edit or delete users. Here you can control the email address, name and password, as
				well as amend the user access role.'
			>
				<Card.Body>
					<AddButton
						className="custom-button"
						button_name={readPerm === 1 ? Paths.users.c1_name : null}
						button_path={Paths.users.c1_path}
						button_title={Paths.users.c1_name}
						btn_class="list_button"
					/>
					{successMessage && <Alert variant="success">{successMessage}</Alert>}
					{(rowData !== undefined) && <UnorderedGrid gridData={rowData} columnDefs={columnDefs} onGridReady={onGridReady} />}
				</Card.Body>
			</LayoutWrapper>
		</>
	);
}

export default List;