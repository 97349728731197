import { useContext, useEffect, useState } from "react";
import "../../../components/UI/Sidebar.scss";
import "./LoginLayout.scss";
import DataContext from "../../../context/DataContext";
import Button from "./Button";
import { useNavigate } from "react-router-dom";
import "./LoginLayout.scss";

const LoginFormm = ({ onForgotPasswordClick }) => {
	const navigate = useNavigate();
	const { dispatchAuth } = useContext(DataContext);

	const API_KEY = process.env.REACT_APP_API_KEY;
	const API_URL = process.env.REACT_APP_API_URL;

	const [user, setUser] = useState("");
	const [pwd, setPwd] = useState("");
	const [showLoader, setShowLoader] = useState(false);
	const [error, setError] = useState(false);
	const [active, setActive] = useState('inactive');
	const [rememberMe, setRememberMe] = useState(false);

	useEffect(() => {
		const storedRememberMe = localStorage.getItem("rememberMe");
		if (storedRememberMe !== null) {
			setRememberMe(JSON.parse(storedRememberMe));
			// If "Remember Me" was checked, pre-fill the username remember me checkbox
			if (JSON.parse(storedRememberMe)) {
				const storedUsername = localStorage.getItem("rememberedUsername");
				if (storedUsername !== null) setUser(storedUsername);
			}
		}
	}, []);

	const handleRememberMeChange = (e) => {
		setRememberMe(e.target.checked);
		localStorage.setItem("rememberMe", JSON.stringify(e.target.checked));
	};

	useEffect(() => {
		setActive(user && pwd ? "active" : "inactive");
	}, [user, pwd]);

	const handleSubmit = (e) => {
		e.preventDefault();
		setShowLoader(true);

		let formData = new FormData();
		formData.append("action", "login");
		formData.append("api_key", API_KEY);
		formData.append("username", user);
		formData.append("password", pwd);

		if (rememberMe) {
			localStorage.setItem("rememberedUsername", user);
		} else {
			localStorage.removeItem("rememberedUsername");
		}

		fetch(API_URL + 'v1/user/login', { method: 'POST', body: formData })
			.then(response => response.json())
			.then(jsondata => {
				if (jsondata.error) {
					// Err messages
					setError(true)
					setShowLoader(false)

					localStorage.setItem("error", jsondata.error.message);

				} else {
					// Successs
					setShowLoader(false)
					if (jsondata.access_token) {
						localStorage.setItem("isLoggedin", true);
						localStorage.setItem("expires_in", jsondata.expires_in);
						localStorage.setItem("access_token", jsondata.access_token);
						localStorage.setItem("refresh_token", jsondata.refresh_token);
						localStorage.setItem("user_profile", JSON.stringify(jsondata.profile));
						localStorage.setItem("access_modules", JSON.stringify(jsondata.profile.Modules));
						localStorage.setItem("allowedURLs", JSON.stringify(jsondata.profile.allowedURLs));
						localStorage.setItem("visualiser_list", JSON.stringify(jsondata.profile.visualiser));

						const tokenReceivedTime = Date.now();
						localStorage.setItem("tokenReceivedTime", tokenReceivedTime);

						// Api call to check session to get and store userdetails (u decide)
						dispatchAuth({
							type: 'SET_AUTH', setvalue: {
								'isLoggedin': true,
								'expires_in': jsondata.expires_in,
								'access_token': jsondata.access_token,
								'refresh_token': jsondata.refresh_token,
								'user_profile': jsondata.profile,
								'access_modules': jsondata.profile.Modules,
								'allowedURLs': jsondata.profile.allowedURLs,
								'visualiser_list': jsondata.profile.visualiser,
							}
						});
						return navigate("/");

					} else {
						// Message login failed
					}
				}
			})
			.catch((err) => {
				console.log("Login error: " + err);
			});
	};

	return (
		<>
			<div className="intro_text">
				<h3>Let's get moving</h3>
				<p>
					This CMS is only for use on a desktop computer.
				</p>
			</div>
			<div className="w-75">
				<form onSubmit={handleSubmit}>
					<div className="form-outline mb-4 mt-2">
						<label className="form-label" htmlFor="form3Example3">
							Username
						</label>
						<input
							className="form-control form-control-lg form-in"
							type="text"
							id="username"
							autoComplete="off"
							name="username"
							onChange={(e) => {
								setUser(e.target.value);
								setError(false);
							}}
							value={user}
							required
						/>
					</div>
					<div className="form-outline mb-3">
						<label className="form-label" htmlFor="form3Example4">
							Password
						</label>
						<input
							className="form-control form-control-lg form-in"
							type="password"
							id="password"
							onChange={(e) => {
								setPwd(e.target.value);
								setError(false);
							}}
							value={pwd}
							required
						/>
					</div>
					<div className="d-flex justify-content-between align-items-center">
						<div className="form-check mb-0">
							<input
								className="form-check-input me-2 remember-input"
								type="checkbox"
								value=""
								id="form2Example3"
								style={{ cursor: 'pointer' }}
								checked={rememberMe}
								onChange={handleRememberMeChange}
							/>
							<label className="form-check-label" htmlFor="form2Example3">
								Remember me
							</label>
						</div>
						<a href="#" className="text-body" onClick={onForgotPasswordClick}>
							Forgotten Password
						</a>
					</div>

					<div className="mt-4 button_block">
						<Button
							text="SIGN IN"
							loading={showLoader}
							disabled={showLoader}
							error={error}
							active={active}
						/>
					</div>
				</form>
			</div>
		</>
	);
};

export default LoginFormm;
