import LayoutWrapper from '../components/UI/LayoutWrapper'
import './Dashboard.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useContext } from 'react';
import DataContext from '../context/DataContext';
import { NavLink } from 'react-router-dom';
import { Paths } from '../routes/route';


const Dashboard = () => {
    const { auth } = useContext(DataContext);
    let username = auth[0]?.user_profile?.FName + ' ' + auth[0]?.user_profile?.SName;
    return (
        <LayoutWrapper className='d-none' custom_class='padding_css'>
            <div className='dashboard_main'>
                <div className='dashboard_title'>Hello, {username}.</div>
                <div className='dashboard_subtitle'>What would you like to do today?</div>
            </div>
            <div className='dashboard_block1'>
                <NavLink to={Paths.users.path}>
                    <div className='inner_block'>
                        <div className='shapes'>
                            <img className='img1' src="/images/navIcons/users-solid.svg" alt="repair" />
                        </div>
                        <div className='block_title'>
                            I’d like to create or amend a user<FontAwesomeIcon icon={faAngleRight} style={{ color: '#EE6100' }} />
                        </div>
                    </div>
                </NavLink>
                <NavLink to={Paths.visualizer.path}>
                    <div className="inner_block">
                        <div className='shapes'>
                            <img className='img2' src="/images/navIcons/visualiser.svg" alt="globe" />
                        </div>
                        <div className='block_title'>
                            I’d like to create a new visualiser<FontAwesomeIcon icon={faAngleRight} style={{ color: '#EE6100' }} />
                        </div>
                    </div>
                </NavLink>
            </div>
            <div className='dashboard_block2'>
                <NavLink to={Paths.translations.path}>
                    <div className='inner_block'>
                        <div className='shapes'>
                            <img className='img3' src="/images/navIcons/tent-arrow-left-right-solid.svg" alt="paint" />
                        </div>
                        <div className='block_title'>
                            I’d like to make a change to translations<FontAwesomeIcon icon={faAngleRight} style={{ color: '#EE6100' }} />
                        </div>
                    </div>
                </NavLink>
                <NavLink to={Paths.colours.path}>
                    <div className="inner_block">
                        <div className='shapes'>
                            <img className='img4' src="/images/navIcons/fill-drip-solid.svg" alt="marker" />
                        </div>
                        <div className='block_title'>
                            I’d like to make a change to a colour<FontAwesomeIcon icon={faAngleRight} style={{ color: '#EE6100' }} />
                        </div>
                    </div>
                </NavLink>
            </div>
        </LayoutWrapper>
    );
}

export default Dashboard;