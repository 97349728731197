import { Card, Alert } from 'react-bootstrap';
import ConfirmDeleteModal from '../../components/ConfirmDeleteModal';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import React, { useEffect, useState } from "react";
import edit from "../../assets/images/edit.svg";
import delete_icon from "../../assets/images/delete.svg";
import { useLocation } from "react-router-dom";
import LayoutWrapper from '../../components/UI/LayoutWrapper';
import { Link } from 'react-router-dom';
import UnorderedGrid from '../../components/UnorderedGrid';
import { Paths } from '../../routes/route';
import { naviLinks } from '../../framework/utilities.js/utilities';
import AddButton from '../../components/AddButton';
import { deleteBtn, handleOnDeleteSubmitted } from '../../helpers/API/Delete';
import { listData } from '../../helpers/API/List';

const List = () => {
	const API_URL = process.env.REACT_APP_API_URL;
	const [gridApi, setGridApi] = useState(null);
	const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row
	const [languageDeleted, setlanguageDeleted] = useState(false);

	let [modalShow, setModalShow] = useState(false);
	let [delRecTitle, setDelRecTitle] = useState('');
	let [delRecId, setDelRecId] = useState(0);

	// Code to fetch title based on URL
	const { pathname } = useLocation();
	const naviArr = naviLinks(pathname);
	const createPerm = naviArr['edit_perm'];
	const deletePerm = naviArr['delete_perm'];
	const readPerm = naviArr['add_perm'];
	const [successMessage, setSuccessMessage] = useState('');

	const storedValue = localStorage?.getItem('viz_id');

	// Each Column Definition results in one Column.
	const [columnDefs] = useState([
		{ headerName: "Language", field: 'name', filter: true, resizable: true, flex: 1 },
		{ headerName: "Language code", field: 'code', filter: true, resizable: true, flex: 1 },
		{ headerName: "Status", field: "active", sortable: true, filter: true, flex: 1 },
		{
			headerName: "", field: "id", minWidth: 50, maxWidth: 50, headerClass: "ag-theme-custom-text-center", cellClass: "pm-grid-number-cell ag-theme-custom-content-center",
			cellRenderer: (params) => <div className="ag-theme-content-text-center">
				{createPerm === 1 ? <Link onClick={handleOpenInNewTab} to={(`${Paths.languages.c2_path.split(":").slice(0, -1)[0]}` + params.value)} title="Edit" className="grid-action-btns text-success link"><img src={edit} alt="edit" className="me-2" style={{ width: '24px', height: '24px' }} /></Link> : null}

			</div>
		},
		{
			headerName: "", field: "id", flex: 1, minWidth: 50, maxWidth: 50, headerClass: "ag-theme-custom-text-center", cellClass: "pm-grid-number-cell ag-theme-custom-content-center",
			cellRenderer: (params) => <div className="ag-theme-content-text-center">

				{deletePerm === 1 ? <span onClick={() => deleteBtn(params, 'name', setDelRecId, setDelRecTitle, setModalShow)} title="Delete" className="grid-action-btns delete-icon-color link"><img src={delete_icon} alt="delete" className="me-2" style={{ width: '24px', height: '24px' }} /></span> : null}
			</div>
		}
	]);

	const handleOpenInNewTab = (event) => {
		if (event.button === 0) {
			// Left mouse button clicked
			// Open link in the same tab
			return;
		}
		const link = document.createElement('a');
		link.href = event.currentTarget.href;
		link.target = '_blank';
		link.click();
	}

	const onDelete = () => {
		handleOnDeleteSubmitted('/v1/language/deleteLanguage', setSuccessMessage, setlanguageDeleted, setModalShow, setDelRecId, setDelRecTitle, languageDeleted, delRecId, gridApi);
	}

	useEffect(() => {

		listData(API_URL + '/v1/language/languageList?visualizer_id=' + storedValue, setRowData);

	}, [languageDeleted]);

	return (
		<>
			<LayoutWrapper
				modal={<ConfirmDeleteModal modalShow={modalShow} handleModelClose={() => { setModalShow(false); }} delRecTitle={delRecTitle} handleOnDeleteSubmitted={onDelete} />}
				heading={Paths.languages.name}
				breadcrumb_text='Add, remove or change the status of a language. When you click into the language, you can
				amend the weight and the terms and condition disclaimer.'
			>
				<Card.Body>
					<AddButton
						className="custom-button"
						button_name={readPerm === 1 ? Paths.languages.c1_name : null}
						button_path={Paths.languages.c1_path}
						button_title={Paths.languages.c1_name}
						btn_class="list_button"
					/>
					{successMessage && <Alert variant="success">{successMessage}</Alert>}
					{(rowData !== undefined) && <UnorderedGrid gridData={rowData} columnDefs={columnDefs} url='' />}
				</Card.Body>
			</LayoutWrapper>
		</>
	);
}

export default List;