import { Col, Row, Form, Button, Alert, Card } from '@themesberg/react-bootstrap';
import { useForm } from "react-hook-form";
import React, { useContext, useState } from "react";
import { PasswordCheck, handleLogout, passRules, scrollToTop } from '../../framework/utilities.js/utilities';
import './Password.scss'
import DataContext from '../../context/DataContext';
import { Modal } from 'react-bootstrap';

const ChangePassword = (props) => {
    const { dispatchAuth } = useContext(DataContext);
    const heading = "Change Password";
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [successMessage, setSuccessMessage] = useState('');
    const [errMessage, setErrorMessage] = useState('');
    const API_URL = process.env.REACT_APP_API_URL;
    let dat = '';
    // Get saved data from sessionStorage
    if (sessionStorage) {
        dat = sessionStorage.getItem("key");
    }

    const [passwordOne, setPasswordOne] = useState("")

    const [containsLowercase, setContainsLowercase] = useState(false) // Lowercase letter
    const [containsUppercase, setContainsUppercase] = useState(false) // Uppercase letter
    const [containsNumber, setContainsNumber] = useState(false) // Number
    const [containsEightCharacters, setContainsEightCharacters] = useState(false) // Min 8 characters
    const [containsLoginEmail, setContainsLoginEmail] = useState(false) // Same email
    const [containsCharactersTwice, setContainsCharactersTwice] = useState(false) // Twice characters

    const onSubmit = postedData => {
        setErrorMessage('');
        let formData = new FormData();
        let sessionUser = JSON.parse(localStorage.getItem("user_profile"));
        let semail = sessionStorage.getItem("email");

        if (!PasswordCheck(postedData.new_password)) {
            setErrorMessage(passRules());
            return false;
        }
        else if (postedData.new_password !== postedData.confirm_password) {
            setErrorMessage('Die Passw&ouml;rter stimmen nicht &uuml;berein.');
            return false;

        }
        if (sessionUser) {
            formData.append("email", sessionUser.Email);
        }
        else {
            formData.append("email", semail);
        }
        formData.append("old_password", postedData.old_password);
        formData.append("new_password", postedData.new_password);
        formData.append("confirm_password", postedData.confirm_password);

        fetch(API_URL + '/v1/user/loggedin-password-reset', {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("access_token"),
            },
            body: formData
        })
            .then(response => response.json())
            .then(jsondata => {
                if (jsondata.message === "CREATED") {
                    scrollToTop();
                    setErrorMessage('');
                    setSuccessMessage("Your Password is Updated, You will be Redirected to login page. Kindly use your new Password to Login");
                    setTimeout(function () {
                        setSuccessMessage("");
                        handleLogout(dispatchAuth);
                    }, 7000);
                }
                if (jsondata.message === "") {
                    scrollToTop();
                    setErrorMessage(jsondata.vmessage);
                    setSuccessMessage('');
                    setTimeout(function () {
                        setErrorMessage('');
                    }, 2000);
                }
            }).catch((err) => {
                console.log("Login error: " + err);
                setErrorMessage("Passwort konnte nicht aktualisiert werden.");
            });
    }

    const validatePassword = () => {
        let sessionUser = JSON.parse(localStorage.getItem("profile"));
        let semail = sessionStorage.getItem("email");

        // Has lowercase letter
        if (passwordOne.toUpperCase() !== passwordOne) setContainsLowercase(true)
        else setContainsLowercase(false)

        // Has uppercase letter
        if (passwordOne.toLowerCase() !== passwordOne) setContainsUppercase(true)
        else setContainsUppercase(false)

        // Has number
        if (/\d/.test(passwordOne)) setContainsNumber(true)
        else setContainsNumber(false)

        // Has 8 characters
        if (passwordOne.length >= 8) setContainsEightCharacters(true)
        else setContainsEightCharacters(false)

        // Has same login user email
        if (sessionUser) {
            if (passwordOne === sessionUser.Email) setContainsLoginEmail(false)
            else setContainsLoginEmail(true)
        } else {
            if (passwordOne === semail) setContainsLoginEmail(false)
            else setContainsLoginEmail(true)
        }

        // No characters should be added twice
        if (/^.*(.).*\1{2}.*$/.test(passwordOne)) setContainsCharactersTwice(false)
        else setContainsCharactersTwice(true)

        if (passwordOne === '') {
            setContainsLoginEmail(false);
            setContainsCharactersTwice(false);
        }
    }

    return (
        <>
            <Modal
                show={props.modalDisplay}
                onHide={props.handleModelClose}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="changepassword_popup"
            >
                <Modal.Header style={{ padding: '0', border: 'none' }} closeButton />
                {successMessage && !errMessage && <Alert variant="success">{successMessage}</Alert>}
                {errMessage && <Alert variant="danger">{errMessage}</Alert>}
                <Modal.Body>
                    <div border="light" className="bg-white mb-4 profilecard px-4">
                        <div>
                            <h5 style={{ color: '#000000', fontSize: '22px', fontWeight: '800' }} className="pb-3">
                                {heading}
                            </h5>
                        </div>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Card.Body>
                                {dat === '1' && <Row className="border">
                                    <Col>
                                        <div>Dein Passwort muss alle 180 Tage ge&auml;ndert werden.Es w&auml;re mal wieder so weit...</div>
                                    </Col>
                                </Row>}

                                <Row>
                                    <Col md={12} className="mb-3">
                                        <Form.Group id="old_password">
                                            <Form.Label className="required">Old Password</Form.Label>
                                            <Form.Control name="old_password" type="password" {...register("old_password", { required: true })} {...register("old_password", {
                                                required: true, minLength: { value: 8, message: "Your password must have at least 8 characters." }, validate: (value) => {
                                                    return [
                                                        /[a-z]/,
                                                        /[A-Z]/,
                                                        /[0-9]/,
                                                        /[^a-zA-Z0-9]/,
                                                    ].every((pattern) => pattern.test(value)) || "Must contain numbers, characters and special characters."
                                                }
                                            })} className={errors?.old_password?.type === "required" && "is-invalid"}
                                            />
                                            {errors?.old_password?.message && <p className="is-invalid" >{errors?.old_password?.message}</p>}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className="mb-3">
                                        <Form.Group id="new_password">
                                            <Form.Label className="required">New Password</Form.Label>
                                            <Form.Control name="new_password" type="password" {...register("new_password", { required: true })} {...register("new_password", {
                                                required: true, minLength: { value: 8, message: "Your password must have at least 8 characters." }, validate: (value) => {
                                                    return [
                                                        /[a-z]/,
                                                        /[A-Z]/,
                                                        /[0-9]/,
                                                        /[^a-zA-Z0-9]/,
                                                    ].every((pattern) => pattern.test((value))) || "Must contain numbers, characters and special characters."
                                                }
                                            })} className={errors?.new_password?.type === "required" && "is-invalid"} value={passwordOne} onChange={e => setPasswordOne(e.target.value)} onKeyUp={validatePassword} />
                                            {errors?.new_password?.message && <p className="is-invalid" >{errors?.new_password?.message}</p>}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className="mb-3">
                                        <Form.Group id="confirm_password ">
                                            <Form.Label className="required">Confirm Password</Form.Label>
                                            <Form.Control name="confirm_password" type="password" {...register("confirm_password", {
                                                required: true, validate: (value) =>
                                                    value === watch('new_password') || "Password does not match."
                                            })} className={errors?.confirm_password?.type === "required" && "is-invalid"} />
                                            {errors?.confirm_password?.message && <p className="is-invalid" >{errors?.confirm_password?.message}</p>}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="border mb-4 mt-2 mx-1" style={{ background: '#f2f2f2' }}>
                                    <Col >
                                        <div id="passwordRequirements">
                                            <div>The password must meet the following requirements:</div>
                                            <ul>
                                                <li id="lowerCaseLetter" className={containsLowercase ? `green-color` : `red-color`}><span><i className={containsLowercase ? `fa fa-check` : `fa fa-times`} aria-hidden="true"></i></span> It must contain at least one lowercase letter.</li>
                                                <li id="upperCaseLetter" className={containsUppercase ? `green-color` : `red-color`}><span><i className={containsUppercase ? `fa fa-check` : `fa fa-times`} aria-hidden="true"></i></span>It must contain at least one capital letter.</li>
                                                <li id="number" className={containsNumber ? `green-color` : `red-color`}><span><i className={containsNumber ? `fa fa-check` : `fa fa-times`} aria-hidden="true"></i></span>It must contain at least one number.</li>
                                                <li id="length" className={containsEightCharacters ? `green-color` : `red-color`}><span><i className={containsEightCharacters ? `fa fa-check` : `fa fa-times`} aria-hidden="true"></i></span>It must be at least 8 characters long.</li>
                                                <li id="repeatingCharacters" className={containsCharactersTwice ? `green-color` : `red-color`}><span><i className={containsCharactersTwice ? `fa fa-check` : `fa fa-times`} aria-hidden="true"></i></span>No character may be used more than twice</li>
                                                <li id="sameAsEmail" className={containsLoginEmail ? `green-color` : `red-color`}><span><i className={containsLoginEmail ? `fa fa-check` : `fa fa-times`} aria-hidden="true"></i></span>It cannot be your email address.</li>
                                            </ul>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={12} lg={12} md={12} className="d-flex flex-column align-items-center">
                                        <div >
                                            <Button type="submit" className="list_button m-0" >
                                                Save user</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ChangePassword;
