import React from "react";
import UserRoleForm from "./UserRoleForm";
import { Paths } from "../../routes/route";
const Add = () => {

	const data = [];
	return (
		<>
			<UserRoleForm addform={1} title={Paths.userrole.c1_name} data={data} />
		</>
	);
}

export default Add;